import React, { useState } from 'react';
import classNames from 'classnames';
import { Dropdown } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Elements } from 'react-stripe-elements';
import { convertToMoney } from 'src/utils/convertToMoney';

import Stripe from './components/Stripe';
import Fps from './components/Fps';
import { PaymentFormcSS } from './styles';

export const CREDIT_CARD_SURCHARGE = 1.034;

const PaymentForm = (props: any) => {
  const { t } = useTranslation('payment');
  const [paymentType, setPaymentType] = useState('');
  const { remainingAmount } = props;
  const options = [
    {
      key: 'stripe',
      value: 'stripe',
      text: (
        <div className="option-item">
          <div className="type">{t('creditCard')}</div>
          <div className="amount">{convertToMoney(remainingAmount * CREDIT_CARD_SURCHARGE)}</div>
        </div>
      ),
    },
    {
      key: 'fps',
      value: 'fps',
      text: (
        <div className="option-item">
          <div className="type">{t('fps')}</div>
          <div className="amount">{convertToMoney(remainingAmount)}</div>
        </div>
      ),
    },
  ];

  const renderPaymentForm = () => {
    switch (paymentType) {
      case 'stripe':
        return (
          <Elements>
            <Stripe {...props} />
          </Elements>
        );

      case 'fps':
        return <Fps {...props} />;

      default:
        return null;
    }
  };

  return (
    <PaymentFormcSS>
      <label htmlFor="paymentType">{t('paymentType')}</label>
      <Dropdown
        placeholder={t('selectPaymentType')}
        selection
        options={options}
        onChange={(e: any, { value }: any) => {
          setPaymentType(value);
        }}
        value={paymentType}
      />
      <div className={classNames('payment-form-container', paymentType)}>{renderPaymentForm()}</div>
    </PaymentFormcSS>
  );
};

export default PaymentForm;
