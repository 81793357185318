import React, { useEffect, useState } from 'react';
import { FirebaseContext, UserContext, MerchantContext } from 'src/context';

export const useAuthHook = (auth: any) => {
  const [authState, setState] = useState({
    isLoading: true,
    user: null
  });

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((state: any) =>
      setState({ isLoading: false, user: state })
    );
    return unsubscribe;
  }, [auth]);
  return authState;
}

export const useDocumentHook = (ref: any) => {
  const [docState, setDocState] = useState({
    isLoading: true,
    data: null
  });

  useEffect(() => {
    return ref.onSnapshot((doc: any) => {
      setDocState({
        isLoading: false,
        data: {
          ...doc.data(),
          id: doc.id,
        }
      });
    });
  }, []);

  return docState;
}

export const useCollectionHook = (ref: any) => {
  const [docState, setDocState] = useState({
    isLoading: true,
    data: []
  });

  useEffect(() => {
    return ref.onSnapshot((snapshot: any) => {

      const data = snapshot.docs.map((doc: any) => {
        return {
          ...doc.data(),
          id: doc.id,
        }
      })

      // const mappedData: any = [];

      // docs.forEach((singleData: any) => {
      //   mappedData.push(singleData.data());
      // })

      setDocState({
        isLoading: false,
        data
      });
    });
  }, []);

  return docState;
}

export const withFirebase: any = (Component: any) => (props: any) => (
  <FirebaseContext.Consumer>
    {firebase => <Component {...props} firebase={firebase} />}
  </FirebaseContext.Consumer>
);

export const withUser: any = (Component: any) => (props: any) => (
  <UserContext.Consumer>
    {user => <Component {...props} user={user} />}
  </UserContext.Consumer>
)

export const withMerchant: any = (Component: any) => (props: any) => (
  <MerchantContext.Consumer>
    {merchant => <Component {...props} merchant={merchant} />}
  </MerchantContext.Consumer>
)