import styled from 'styled-components';
import { largeDesktopWidth } from 'src/config';

export const DetailsCSS = styled.div`
  padding: 1.5rem 2rem;
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-gap: 20px;
  background: white;

  @media only screen and (min-width: ${largeDesktopWidth}px) {
    grid-template-columns: repeat(2, minmax(100px, 1fr));
  }
`;
