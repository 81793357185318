
import React from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { Button, Input, Form } from 'semantic-ui-react'
import { withFirebase, withUser } from 'src/components/Firebase';
import EditDetailContainer from '../../../EditDetailContainer';
import { initialState } from './config';

class Edit extends React.Component<any, any>{
  constructor(props: any) {
    super(props);

    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const { name, phone } = user;
    this.setState({
      name,
      phone
    })
  }

  handleChange = (e: null, { name, value }: any) => {
    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    const { toggleEdit, toggleLoading, firebase, user } = this.props;
    const { name, phone } = this.state;

    try {
      toggleLoading(true);
      await firebase
        .userDoc(user.id)
        .set({
          name,
          phone
        }, { merge: true });
      toast.dismiss();
      toggleEdit(false);
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { toggleEdit } = this.props;
    const { name, phone } = this.state;
    return (
      <EditDetailContainer>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field
            required
            control={Input}
            value={name}
            name='name'
            label={t('name')}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            control={Input}
            value={phone}
            name='phone'
            label={t('phone')}
            onChange={this.handleChange}
          />
          <Button
            type='submit'
          >
            {t('save')}
          </Button>
          <Button
            type='button'
            className='invert-primary-light-button'
            onClick={() => toggleEdit(false)}
          >
            {t('cancel')}
          </Button>
        </Form>
      </EditDetailContainer>
    )
  }
}

export default R.compose<any, any, any, any>(
  withTranslation('settings'),
  withUser,
  withFirebase
)(Edit);