import React from 'react';
import { SingleDocumentCSS } from './styles';

const SingleDocument = (props:any) => {
  const { children } = props;
  return (
    <SingleDocumentCSS className="drop-shadow">
      {children}
    </SingleDocumentCSS>
  )
}

export default SingleDocument;
