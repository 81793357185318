import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const PrivacyCSS = styled.div`
  .container { 
    background: white;
    padding: 1rem;
    padding-left: 2rem;
    border-radius: 6px;
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    display: flex;
    justify-content: center;

    .container {
      width: 100%;
      max-width: 750px;
      padding: 1.5rem;
      padding-left: 2.5rem;
    }
  }
`;