import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { withFirebase, withUser } from 'src/components/Firebase';
import { LanguageCSS } from './styles';

const Language = (props: any) => {
  const { id, user, firebase } = props;
  const { i18n } = useTranslation();


  const handleChangeLanguage = () => {
    const lng = i18n.language === 'cn' ? 'en' : 'cn';
    if(user) {
      firebase.userDoc(user.id).update({
        language: lng
      })
    }
    i18n.changeLanguage(lng);
  };

  return (
    <LanguageCSS
      id={id}
      role="button"
      onClick={handleChangeLanguage}
    >
      {
        i18n.language === 'cn' ? 'EN' : '中'
      }
    </LanguageCSS>
  )
}

export default R.compose<any, any, any>(
  withFirebase,
  withUser
)(Language);
