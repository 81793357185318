import styled from 'styled-components';

export const SuccessCSS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1.5rem;
  

  p {
    margin: 0.5rem 0;
    text-align: center;
    font-size: 1rem;
    color: var(--fontColor);
    font-weight: 300;
  }

  h1 {
    color: var(--primaryDark);
    margin-top: 0.5rem;
  }

  i.icon {
    color: var(--primaryDark);
  }
`;