import React from 'react';
import Callback from 'src/screens/ConnectsCallback/Callback';
import { useConnectHook } from '../hooks';

const Quickbooks = () => {
  const {
    connectState
  } = useConnectHook('quickbooks');
  return (
    <Callback result={connectState} />
  )
}

export default Quickbooks;

