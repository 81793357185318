import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import Media from 'react-media';
import { Switch, Route, Redirect } from 'react-router-dom';
import { MerchantContext } from 'src/context';
import { withFirebase, withUser } from 'src/components/Firebase';
import { Merchant } from 'src/models/Merchant';
import { useDocumentHook } from 'src/components/Firebase/utils'
import { desktopWidth } from 'src/config';
import DesktopSettingsAndLogout from 'src/components/DesktopSettingsAndLogout';
import Nav from 'src/components/Nav';
import Loading from 'src/components/Loading';
import Dashboard from 'src/screens/Dashboard';
import Pay from 'src/screens/Pay';
import GetPaid from 'src/screens/GetPaid';
import RequestForm from 'src/screens/RequestForm';
import Invoices from 'src/screens/Invoices';
import SingleInvoice from 'src/screens/SingleInvoice';
import Contact from 'src/screens/Contact';
import ResetPassword from 'src/screens/ResetPassword';
import Logout from 'src/screens/Logout';
import SettingRoutes from '../SettingRoutes';
import * as ROUTES from '../../../routes';

const Routes = (props: any) => {
  const { i18n } = useTranslation();
  const { firebase, user } = props;
  const merchantRef = firebase.merchantDoc(user.merchantId);
  const merchant = useDocumentHook(merchantRef);


  if (user.language && i18n.language !== user.language) {
    i18n.changeLanguage(user.language);
  }

  if (merchant.isLoading) {
    return (
      <Loading />
    )
  }

  const data = Merchant.formatData(merchant.data);

  return (
    <MerchantContext.Provider value={data}>
      <Nav />
      <div className="switch-container">
        <Media
          query={`(min-width: ${desktopWidth}px)`}
          render={() => (
            <DesktopSettingsAndLogout />
          )}
        />
        <Switch>
          <Route exact path={ROUTES.HOME} component={Dashboard} />
          <Route exact path={ROUTES.INVOICES} component={Invoices} />
          <Route path={ROUTES.SINGLE_INVOICE} component={SingleInvoice} />
          <Route path={ROUTES.CONTACT} component={Contact} />
          <Route path={ROUTES.SETTINGS} component={SettingRoutes} />
          <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
          <Route exact path={ROUTES.PAY} component={Pay} />
          <Route
            path={ROUTES.PAY_REQUEST}
            component={() => <RequestForm path='pay' />}
          />
          <Route path={ROUTES.SINGLE_PAY} component={SingleInvoice} />
          <Route exact path={ROUTES.GET_PAID} component={GetPaid} />
          <Route
            path={ROUTES.GET_PAID_REQUEST}
            component={() => <RequestForm path='get-paid' />}
          />
          <Route path={ROUTES.SINGLE_GET_PAID} component={SingleInvoice} />
          <Route path={ROUTES.LOGOUT} component={Logout} />
          <Redirect to={ROUTES.HOME} />
        </Switch>
      </div>
    </MerchantContext.Provider>
  )
}

export default R.compose<any, any, any>(
  withFirebase,
  withUser
)(Routes);