export const filterRepeat = (documents:any[]) => {
  const hash:any = {};

  return documents.filter((x:any) => {
    if(hash[x.type]){
      return false;
    }
    
    hash[x.type] = true;
    return true;
  })
}

export const connects = [
  {
    connect: 'xero',
    src: '/static/images/xero.png',
    alt: 'xero connect',
  },
  {
    connect: 'quickbooks',
    src: '/static/images/quickbooks.png',
    alt: 'quickbooks connect',
  },
]

export const mapDocumentTypes:any = (type:any) => {
  switch(type) {
    case '3_months':
      return '3 Months'
    case 'tu':
      return 'TU'
    case 'audit_report':
      return 'Audit Report'
    default:
      return type
  }
}

