import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SETTINGS, LOGOUT } from 'src/screens/routes';
import Language from 'src/components/Language';
import { DesktopSettingsAndLogoutCSS } from './styles';


const DesktopSettingsAndLogout = () => {

  const { t } = useTranslation('nav');
  return (
    <DesktopSettingsAndLogoutCSS>
      <p className="text-hover text-hover-black">
        <Language id="closed-routes" />
      </p>
      <Link to={SETTINGS}>
        <p className="text-hover text-hover-black">{t('settings')}</p>
      </Link>
      <Link to={LOGOUT}>
        <p
          className="text-hover text-hover-black"
        >
          {t('logout')}
        </p>
      </Link>
    </DesktopSettingsAndLogoutCSS>
  )
}

export default DesktopSettingsAndLogout;