import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const NoDataCSS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 250px;

  &.full-height {
    min-height: calc(100vh - 50px);
  }

  i.icon, i.icons {
    color: var(--gray200);
    margin-bottom: 1rem;
  }

  p {
    text-align: center;
    font-size: 1rem;
    color: var(--gray200);
    font-weight: 600;
  }

  &.white {
    i.icon, i.icons {
      color: #deeac7;
    }

    p {
      color: #deeac7;
    }
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    justify-content: flex-start;
    padding: 1rem;
    padding-top: 5rem;
    min-height: auto;

    i.icon, i.icons {
      color: var(--gray100);
    }
  }
`;