import styled from 'styled-components';

export const TriggerCSS = styled.span`
  transition: 0.3s;
  
  &:hover {
    cursor: pointer;
  }
`;

export const ContentCSS = styled.span`
  .privacy-wrapper {
    margin-left: 1.2rem;
  }
`;