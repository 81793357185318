import styled from 'styled-components';

export const ConnectCSS = styled.div`
  h3 {
    text-align: center;
    color: var(--gray300);
  }

  .connects-container {
    background: white;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 1rem;

    #stripe-image {
      width: 90%;
    }
  
    #xero-image {
      width: 60%;
      max-width: 200px;
    }
  }
`;
