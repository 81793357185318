import React from 'react';

export const FirebaseContext = React.createContext(null);
export const UserContext = React.createContext(null);
export const MerchantContext = React.createContext(null);


export const ListInvoiceContext = React.createContext([]);
export const ListActiveAndCompletedInvoiceContext = React.createContext([]);
export const SingleInvoiceContext = React.createContext(null);
export const SingleInvoiceTransactionsContext = React.createContext([]);
export const SettingsContext = React.createContext(null);
export const SingleInvoiceOfferContext = React.createContext({});