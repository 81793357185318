import styled from 'styled-components';

export const ConnectCSS = styled.span`
  .or {
    text-align: center;
    margin: 0;
    color: var(--gray300);
  }

  hr {
    margin-top: 1.5rem;
  }
`;
