import styled from 'styled-components'; import { tabletWidth, desktopWidth } from 'src/config';

export const ConfirmFormCSS = styled.div`
  .ui.button {
    width: 100%;
    background: var(--primaryLight);
    color: white;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  hr {
    border-bottom: var(--gray200) solid 1px;
  }

  .ui.list .list>.item>i.icon, .ui.list>.item>i.icon {
    color: var(--gray300);
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    max-width: 500px;
    margin: auto;
    padding: 3rem 2rem;
    background: white;
    -webkit-box-shadow: 13px 14px 14px -10px rgba(74,74,74,0.4);
    -moz-box-shadow: 13px 14px 14px -10px rgba(74,74,74,0.4);
    box-shadow: 13px 14px 14px -10px rgba(74,74,74,0.4);
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    margin-top: 2rem;
  }
`;
