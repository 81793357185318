import styled from 'styled-components';

export const TableCSS = styled.div`
  overflow-x: auto;
  width: 100%;

  .ui.table {
    border: none;
    width: 100%;
  }

  .ui.table thead th {
    color: white;
    background: var(--secondary);
    padding-left: 1rem;
    padding-right: 1rem;
    border-bottom: none;
  }

  .ui.table td {
    padding: 0;
  }

  .cell {
    padding: 1rem;
  }

  .cell-companyName {
    display: flex;
    align-items: center;
    white-space: nowrap;
  }

  i.icon, i.icons {
    margin-left: 0.7rem;
    color: var(--rejectRed);
  }

  .ui.button {
    background: var(--primaryDark);
    color: white;
    padding: 0.6rem 1.3rem;

    i.icon, i.icons {
      color: white;
    }

    &:hover {
      i.icon, i.icons {
        color: var(--primaryDark);
      }
    }
  }

  .table-details-container {
    display: flex;
    justify-content: space-between;
    align-items: center;

    h3 {
      margin-bottom: 0;
      color: var(--primaryDark);
    }
  }

`;