import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom'
import * as ROUTES from './routes';
import Login from './Login';
import Register from './Register';
import Language from '../components/Language';


const GuestRoutes = () => {
  return (
    <>
      <Language id="open-routes" />
      <Switch>
        <Route
          exact
          path={ROUTES.HOME}
          render={() => <Redirect to={ROUTES.LOGIN} />}
        />
        <Route path={ROUTES.LOGIN} component={Login} />
        <Route path={ROUTES.REGISTER} component={Register} />
        <Route component={Login} />
      </Switch>
    </>
  )
}

export default GuestRoutes;