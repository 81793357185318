import React from 'react';
import { useTranslation } from 'react-i18next';
import { Tab } from 'semantic-ui-react'
import Profile from '../Profile';
import { SettingsCSS } from './styles';
import Documents from './components/Documents';
import Connect from './components/Connect';

const DesktopSettings = () => {
  const { t } = useTranslation('settings');

  const panes = [
    {
      menuItem: t('profile'),
      render: () => <Profile />,
    },
    {
      menuItem: t('documents'),
      render: () => <Documents />,
    },
    {
      menuItem: t('connect'),
      render: () => <Connect />,
    },
  ]
  return (
    <SettingsCSS className="wrapper-padding">
      <div className="title-container">
        <h1>{t('title')}</h1>
      </div>
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />
    </SettingsCSS>
  );
};



export default DesktopSettings;
