import styled from 'styled-components';
import { largeDesktopWidth } from 'src/config';

export const DesktopOngoingAndCompletedInvoiceCSS = styled.div`
  h1 {
    margin-bottom: 1rem;
    color: var(--primaryDark);
  }

  .invoice-grid-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 35px;
  }

  @media only screen and (min-width: ${largeDesktopWidth}px) {
    .invoice-grid-container {
      grid-template-columns: repeat(2, minmax(100px, auto)) minmax(100px, 400px);
      grid-template-rows: auto;
    }

    .merchant-details-container {
      grid-column: 3/ span 1;
    }

    .transactions-table-container  {
      grid-column: 1/ span 2;
      grid-row: 1/ span 2;
    }
  }
`;