import styled from 'styled-components';

export const DocumentsContainerCSS = styled.div`
  background: white;
  padding: 2rem;
  border-radius: 4px;
  margin-bottom: 3rem;
  
  h3 {
    color: var(--gray300);
  }
`;
