import { useState } from 'react';
import { toast } from 'react-toastify';
import {
  fetchConnectTokenState,
  connects
} from 'src/screens/Settings/utils';
import { useCollectionHook } from 'src/components/Firebase/utils';

export const useDocumentsHook = (firebase:any, user:any) => {
  const [showRequiredScreen, toggleShowRequiredScreen] = useState(true);

  const merchantDocumentsRef = firebase.merchantDocumentsCollection(user.merchantId);
  const merchantDocuments:any = useCollectionHook(merchantDocumentsRef);
  const allDocuments = merchantDocuments.data;

  const incompleteDocuments:any[] = [];
  const completeDocuments:any[] = [];


  for (let i = 0; i < allDocuments.length; i += 1) {
    const current = allDocuments[i];
    if(current.status !== 'complete' && current.status !== 'completed') {
      incompleteDocuments.push(current);
    } else {
      completeDocuments.push(current);
    }
  }

  return {
    showRequiredScreen,
    toggleShowRequiredScreen,
    completeDocuments,
    incompleteDocuments
  }
}

export const useConnectHook = (merchantId:any) => {
  const [isLoading, setLoading] = useState(false);

  const handleConnect = async (provider: string) => {
    try {
      setLoading(true);
      const state = await fetchConnectTokenState(merchantId.toString());
      setLoading(false);
      const handler = connects[provider];
      const link = handler(state);

      window.open(link, 'blank');
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  };

  return {
    handleConnect,
    isLoading,
  }
}

