import styled from 'styled-components';

export const DetailsCSS = styled.div`
  padding: 1.5rem 1rem;
  background: var(--primaryLight);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  
  p {
    color: white;
    text-align: center;
  }
`;