import React from 'react';
import { withTranslation } from 'react-i18next';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { Icon, Progress } from 'semantic-ui-react';
import { withFirebase, withMerchant, withUser } from 'src/components/Firebase';
import Upload from 'src/components/Upload';
import { UploadCSS } from './styles';

class MerchantDocumentUpload extends React.Component<any,any>{
  constructor(props:any){
    super(props);
    this.state = {
      files: [],
      progress: {}
    }
    this.handleUploadFiles = this.handleUploadFiles.bind(this);
    this.verifyFiles = this.verifyFiles.bind(this);
  }

  verifyFiles(newFiles:any){
    const {progress} = this.state;
    const {t} = this.props;
    const filteredFiles = newFiles.filter((x:any)=>{
      if(!progress[x.name]){
        return true;
      }
      const message = `${x.name} ${t('fileAlreadyExist')}`
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
      return false;
    })
    return filteredFiles;
  }


  async handleUploadFiles(name:any, newFiles:any){
    // eslint-disable-next-line
    newFiles = await this.verifyFiles(newFiles);
    
    await this.setState((prevState: any) => ({
      [name]: R.concat(prevState[name], newFiles),
    }));

    try {
      const {
        merchant,
        firebase,
        user,
        t
      } = this.props;

      const uploadFiles = newFiles.map((newFile:any) => {
        const { name:filename } = newFile;
        const upload = firebase.uploadMerchantFiles(merchant.id, filename).put(newFile);

        upload.on('state_changed', (snapshot: firebase.storage.UploadTaskSnapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          const { state } = this;
          const newState = R.mergeDeepLeft({
            progress: {
              [filename]: progress
            }
          }, state);

          this.setState(newState)
        })

        return upload;
      });

      const reflect = (promise: any) => promise.then(
        async (data: any) => {
          const fileName = R.pathOr('', ['metadata', 'name'], data);
          const url = await firebase.uploadMerchantFiles(merchant.id, fileName).getDownloadURL()
          const formattedData = {
            fileName,
            url
          }
          firebase.merchantFilesDoc(user.merchantId).add(formattedData);

          return (
            { data, status: "fulfilled" }
          )
        },
        (data: any) => {
          const fileName = R.pathOr('', ['data', 'metadata', 'name'], data);
          const message = `${fileName} ${t('requestForm:uploadFileError')}`;

          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: false,
          });

          const { state } = this;
          const newState = R.mergeDeepLeft({
            progress: {
              [fileName]: false
            }
          }, state);

          this.setState(newState)

          return (
            { data, status: "rejected" }
          )
        }
      )

      await Promise.all(uploadFiles.map(reflect));

    } catch(error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }

    return true;
  }

  render () {
    const { t } = this.props;
    const { files, progress } = this.state;
    return (
      <UploadCSS className="wrapper-padding gray-background page-padding-bottom">
        <h3>{t('upload')}</h3>
        <p className="small-font">{t('pleaseAllow24Hours')}</p>
        <Upload
          accept=".pdf"
          name='files'
          filesCallback={this.handleUploadFiles}
        >
          <div className="upload-button">
            <Icon name='cloud upload' size='huge' />
          </div>
        </Upload>
        <div>
          {files.map((file:any)=>{
            const { name } = file;
            return(
              <div key={name}>
                <p>{name}</p>
                <Progress 
                  percent={progress[name]} 
                  progress 
                  success={progress[name] === 100} 
                  error={progress[name] === false}
                />
              </div>
            )
          })}
        </div>
      </UploadCSS>
    )
  }
} 



export default R.compose<any, any, any, any, any>(
  withTranslation(['settings', 'requestForm']),
  withMerchant,
  withFirebase,
  withUser
)(MerchantDocumentUpload);
