import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailCSS } from './styles';

const Detail = (props: any) => {
  const { detail, label } = props;
  const { t } = useTranslation('invoice');
  return (
    <DetailCSS>
      <p className='label'>{t(label)}</p>
      <p className='detail'>{detail}</p>
    </DetailCSS>

  )
}

export default Detail;