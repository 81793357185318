import styled from 'styled-components';

export const ItemCSS = styled.div`
  width: 100%;
  background: white;
  margin: 1.5rem 0;
  border-radius: 6px;
  padding: 1rem;

  &:first-child {
    margin-top: 0;
  }

  &.pending {
    border-left: 7px solid var(--gray200);

    .status {
      color:var(--gray200);
    }
  }

  &.rejected {
    border-left: 7px solid var(--rejectRed);

    .status {
      color:var(--rejectRed);
    }
  }

  &.accepted {
    border-left: 7px solid var(--primaryDark);

    .status {
      color:var(--primaryDark);
    }
  }

  hr {
    border-top: 1px solid #f1f1f1;
    margin: 0 -1rem;
    margin-bottom: 0.7rem;
  }

  h3 {
    margin-bottom: 0.5rem;
    color: var(--primaryDark);
    font-weight: 300;
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
  }

  .detail {
    margin-left: 10px;
  }
`;