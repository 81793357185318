import styled from 'styled-components';

export const NavItemsCSS = styled.div`
  li {
    color: white;
    padding: 1em 1em 1em 1.5em;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.2rem;

    &.active {
      background: var(--primaryDark);
    }

    &:hover {
      background: var(--primaryDark);
    }

    i.icon {
      margin: 0 .75rem 0 0;
    }
  }

`;
