import React from 'react';
import * as R from 'ramda';
import { Switch, Route } from 'react-router-dom';
import { UserContext } from 'src/context';
import IsAdmin from 'src/screens/IsAdmin';
import { withFirebase } from 'src/components/Firebase';
import * as ROUTES from 'src/screens/routes';
import Logout from 'src/screens/Logout';
import { useDocumentHook } from 'src/components/Firebase/utils'

import Loading from 'src/components/Loading';
import Routes from './components/Routes';
import { AppCSS } from './styles';

const LoggedInRoutes = (props: any) => {
  const { firebase, user } = props;
  const roleRef = firebase.roleDoc(user.uid);
  const roleHook = useDocumentHook(roleRef);
  const userRef = firebase.userDoc(user.uid);
  const userHook = useDocumentHook(userRef);

  if (userHook.isLoading || roleHook.isLoading) {
    return <Loading />
  }

  if (R.pathOr(false, ['data', 'admin'], roleHook)) {
    return (
      <>
        <IsAdmin />
        <Switch>
          <Route path={ROUTES.LOGOUT} component={Logout} />
        </Switch>
      </>
    )
  }

  return (
    <>
      <AppCSS>
        <UserContext.Provider value={userHook.data}>
          <Routes />
        </UserContext.Provider>
      </AppCSS>
    </>
  )
}

export default R.compose<any, any>(
  withFirebase
)(LoggedInRoutes);
