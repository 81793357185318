import React from 'react';
import * as R from 'ramda';
import axios from 'axios';
import { toast } from 'react-toastify';
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements';
import { Form, Button } from 'semantic-ui-react';
import NumberInput from 'src/components/NumberInput';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { withFirebase, withMerchant } from 'src/components/Firebase';
import { StripeCSS } from './styles';

import { CREDIT_CARD_SURCHARGE } from '../../index';

class Stripe extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      amount: null,
      isLoading: false,
    };
    this.handleChange = this.handleChange.bind(this);
    this.submit = this.submit.bind(this);
    this.validatePayment = this.validatePayment.bind(this);
  }

  componentDidMount() {
    const { remainingAmount } = this.props;
    this.setState({
      amount: remainingAmount * CREDIT_CARD_SURCHARGE,
    });
  }

  handleChange(e: null, { name, value }: any) {
    this.setState({ [name]: value });
  }

  validatePayment() {
    const { t, remainingAmount } = this.props;
    const { amount } = this.state;

    if (!amount) {
      throw Error(t('amountEmptyError'));
    }

    if (amount < 4) {
      throw Error(t('amountTooSmallError'));
    }

    if (amount > remainingAmount * CREDIT_CARD_SURCHARGE) {
      throw Error(t('amountTooLargeError'));
    }

    if (remainingAmount - amount < 4 && remainingAmount - amount > 0) {
      throw Error(t('remainingAmountLessThanFour'));
    }

    if (amount % 1) {
      throw Error(t('noCentsError'));
    }
    return true;
  }

  async submit(e: any) {
    e.preventDefault();
    const { t, merchant } = this.props;

    try {
      this.validatePayment();
      this.setState({ isLoading: true });
      const { amount } = this.state;
      const { stripe, match, setSuccess } = this.props;
      const { token } = await stripe.createToken({
        name: merchant.companyName,
      });

      const invoiceId = R.pathOr('', ['params', 'id'], match);
      const { status } = await axios.post(
        `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/api/stripe/charge`,
        {
          amount,
          token: token.id,
          paymentType: 'stripe',
          invoiceId,
        }
      );

      this.setState({ isLoading: false });
      if (status === 200) {
        setSuccess(true);
      } else {
        throw Error(t('generalError'));
      }
    } catch (error) {
      this.setState({ isLoading: false });
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  render() {
    const { amount, isLoading } = this.state;
    const { t, handleClose } = this.props;

    const style = {
      base: {
        '::placeholder': {
          color: '#CCCCCC',
        },
      },
    };

    return (
      <StripeCSS>
        <Form onSubmit={this.submit}>
          <Form.Field>
            <label htmlFor="amount">{t('amount')}</label>
            <NumberInput
              name="amount"
              value={amount}
              disabled
            />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="cardNumber">{t('cardNumber')}</label>
            <CardNumberElement style={style} className="card-info" />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="expirationDate">{t('expiration')}</label>
            <CardExpiryElement style={style} className="card-info" />
          </Form.Field>
          <Form.Field required>
            <label htmlFor="cv">{t('cv')}</label>
            <CardCVCElement style={style} className="card-info" />
          </Form.Field>
          <Form.Field>
            <div className="buttons">
              <Button loading={isLoading} type="submit" className="primary-dark">
                {t('submit')}
              </Button>
              <Button onClick={handleClose} type="button" className="invert-primary-dark-button">
                {t('cancel')}
              </Button>
            </div>
          </Form.Field>
        </Form>
      </StripeCSS>
    );
  }
}

export default R.compose<any, any, any, any, any, any>(
  withTranslation('payment'),
  injectStripe,
  withFirebase,
  withRouter,
  withMerchant,
)(Stripe);

