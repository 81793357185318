import React from 'react';
import { StripeProvider } from 'react-stripe-elements';
import { ToastContainer } from 'react-toastify';
import { withFirebase } from 'src/components/Firebase';
import { useAuthHook } from 'src/components/Firebase/utils';
import Loading from 'src/components/Loading';
import GuestRoutes from './GuestRoutes';
import LoggedInRoutes from './LoggedInRoutes';
import Routes from './Routes/index';
import 'react-toastify/dist/ReactToastify.css';


const App = (props: any) => {
  const { firebase } = props;
  const { user, isLoading } = useAuthHook(firebase.auth);


  if (isLoading) {
    return (
      <Loading />
    )
  }

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PUBLIC}>
      <div className="App">
        <ToastContainer />
        <Routes>
          {user
            ? <LoggedInRoutes user={user} />
            : <GuestRoutes />
          }
        </Routes>
      </div>
    </StripeProvider>
  );
}

export default withFirebase(App);
