
import styled from 'styled-components';
import { tabletWidth, desktopWidth } from 'src/config';

export const FormCSS = styled.span`
  h3 {
    text-align: center;
    color: var(--primaryDark);
    margin-top: 0.5rem;
  }

  .ui.form .field > label {
    color: var(--gray200);
    line-height: 2;
    font-size: 1rem;
  }

  .ui.form .field .ui.input input {
    color: var(--fontColor);
  }
  
  .ui.button {
    width: 100%;
    font-weight: 700;
    margin-top: 0.5rem;
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    .ui.form{
      max-width: 500px;
      margin: auto;
      padding: 3rem 2rem;
      background: white;
      -webkit-box-shadow: 13px 14px 14px -10px rgba(74,74,74,0.4);
      -moz-box-shadow: 13px 14px 14px -10px rgba(74,74,74,0.4);
      box-shadow: 13px 14px 14px -10px rgba(74,74,74,0.4);
    }
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    .ui.form {
      margin-top: 2rem;
    }
  }
`;