import React, { useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
// eslint-disable-next-line
import { Form, TextArea, Button } from 'semantic-ui-react';
import { withUser } from 'src/components/Firebase';
import { FormCSS } from './styles';

interface IProps {
  user: object;
  className?: string;
  onComplete: Function;
}

function ContactForm({ user, className, onComplete }: IProps) {
  const { name, email }: any = user;
  const { t } = useTranslation('contact');
  const [message, setMessage] = useState('');
  const [isLoading, setLoading] = useState(false);

  const handleChange = (e: null, { value }: any) => {
    setMessage(value);
  }

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }

    setLoading(true);

    const subject = `Enquiry from ${name}(${email})`;
    const data = {
      subject,
      to: 'enquiry@uprisecredit.com',
      from: 'no-reply@uprisepay.com',
      body: message,
    };

    try {
      const apiUrl = `${process.env.REACT_APP_EMAIL_API_HOST}/notifications/email`;
      const { status } = await axios.post(apiUrl, data);

      if (status === 200) {
        onComplete();
      }
    } catch (error) {
      toast.error('Unexpected error', {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    } finally {
      setLoading(false);
    }
  }

  return (
    <FormCSS className={className}>
      <Form onSubmit={handleSubmit}>
        <Form.Input value={name} readOnly />
        <Form.Input value={email} readOnly />
        <Form.Field
          required
          control={TextArea}
          name="message"
          placeholder={t('message')}
          onChange={handleChange}
        />
        <Form.Field>
          <Button type="submit" className="secondary" loading={isLoading}>
            {t('submit')}
          </Button>
        </Form.Field>
      </Form>
    </FormCSS>
  );
}

export default withUser(ContactForm);
