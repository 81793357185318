import React from 'react';
import { Icon, Loader } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { CallbackCSS } from './styles';

// eslint-disable-next-line
const Callback = (props: any) => {
  const { t } = useTranslation('settings');
  const { result } = props;

  const renderResult = () => {
    if(result === false) {
      return (
        <p>{t('connectFail')}</p>
      )
    }
    if(result) {
      return (
        <>
          <h3>{t('connectSuccess')}</h3>
          <p>{t('closeTab')}</p>
        </>
      )
    }
    return (
      <Loader active inline='centered' />
    )
  }

  return (
    <CallbackCSS>
      <div className="container drop-shadow">
        { result !== null && (
          <Icon
            name={result ? 'checkmark' : 'close'}
            size='big'
          />
        )}
        {renderResult()}
      </div>
    </CallbackCSS>
  )
}

export default Callback;
