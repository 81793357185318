import React from 'react';
import * as R from 'ramda';
import { withFirebase, withUser } from 'src/components/Firebase';
import { useDocumentsHook } from '../../../../hooks';
import RequiredDocuments from './screens/RequiredDocuments';
import SubmittedDocuments from './screens/SubmittedDocuments';

const Documents = (props:any) => {
  const { firebase, user } = props;

  const {
    showRequiredScreen,
    toggleShowRequiredScreen,
    completeDocuments,
    incompleteDocuments
  } = useDocumentsHook(firebase, user);

  const toggleScreen = () => {
    toggleShowRequiredScreen(!showRequiredScreen)
  }
  return (
    <div className="wrapper-padding gray-background page-padding-bottom">
      {
        showRequiredScreen
        ? (
          <RequiredDocuments 
            toggleScreen={toggleScreen}
            documents={incompleteDocuments} 
          />
        )
        : (
          <SubmittedDocuments 
            toggleScreen={toggleScreen} 
            documents={completeDocuments}
          />
        )
      }
    </div>
  )
}

export default R.compose<any, any, any>(
  withUser,
  withFirebase,
)(Documents);

