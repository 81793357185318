import styled from 'styled-components';
import { desktopWidth } from 'src/config';

export const ContactSuccessWrapper = styled.div`
  width: 100%;

  @media only screen and (max-width: ${desktopWidth}px) {
    margin-top: 2rem;
    text-align: center;
  }
`
