import styled from 'styled-components';

export const DesktopNavCSS = styled.div`
  width: 275px;
  height: 100vh;
  background: var(--primary);
  position: fixed;
  display: flex;
  flex-direction: column;

  .nav-flex {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    height: 100%;
    justify-content: space-between;
  }

  .terms-container {
    color: var(--gray300);
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    > *:hover {
      color: white;
    }

    & > * {
      margin: 0.2rem 0;
    }
  }
`;

export const PlaceholderCSS = styled.div`
  min-width: 275px;
  max-width: 275px;
  // height: calc(100vh - 5px);
`;
