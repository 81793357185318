import styled from 'styled-components';

export const PayModalHeaderCSS = styled.div`
  text-align: center;
`;

export const PayModalBodyCSS = styled.span`
  .buttons {
    display: flex;
    flex-direction: column;

    .ui.button {
      margin: 0.5rem 0;
    }
  }
`
