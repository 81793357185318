import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as ROUTE from 'src/screens/routes';
import { Button, Icon } from 'semantic-ui-react';
import { ListDocumentsCSS } from './styles';

const ListDocuments = (props:any) => {
  const { t } = useTranslation(['settings', 'general']);
  const {
    title='',
    returnTo='',
    children,
    toggleScreen
  } = props;

  return (
    <ListDocumentsCSS>
      <h3>{title}</h3>
      <Link to={ROUTE.MERCHANT_DOCUMENTS_UPLOAD}>
        <Button className="primary-light">
          <Icon name='cloud upload' />
          {t('general:upload')}
        </Button>
      </Link>
      <p className="small-font">{t('pleaseAllow24Hours')}</p>
      <div>
        {children}
      </div>
      <Button className="primary-dark" onClick={toggleScreen}>
        {returnTo}
      </Button>
    </ListDocumentsCSS>
  )
}

export default ListDocuments;
