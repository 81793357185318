export default {
  title: 'Dashboard',
  dashboard: 'Dashboard',
  creditLimit: 'Credit Limit',
  usedCreditLimit: 'Used Credit Limit',
  whatDoYouWantToDoToday: 'What would you like to do today?',
  pay: 'Pay',
  getPaid: 'Get Paid',
  upcomingDue: 'Upcoming Due',
  upcomingPay: 'Upcoming Pay',
  amount: 'Amount',
  to: 'To',
  welcome: 'Welcome',
  continueOne: 'To proceed, please head over to',
  continueTwo: '& register your bank account',
  settings: 'settings',
  gotIt: 'Got it',
  dueDate: 'Due Date',
};
