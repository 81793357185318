import React from 'react';
import { Table } from 'semantic-ui-react'
import { useTranslation } from 'react-i18next';
import { mapDocumentTypes } from 'src/screens/Settings/config';
import { DocumentsContainerCSS } from '../../styles';

const SubmittedDocuments = (props:any) => {
  const { t } = useTranslation(['settings', 'general']);
  const { documents } = props;
  const { Header, Row, HeaderCell, Body, Cell } = Table;
  const headers = [
    'type',
    'fileName',
  ]
  
  return (
    <DocumentsContainerCSS className="drop-shadow">
      <h3>{t('submittedDocuments')}</h3>
      <Table singleLine>
        <Header>
          <Row>
            {headers.map((x:any)=> (
              <HeaderCell key={x}>{t(x)}</HeaderCell>
            ))}
          </Row>
        </Header>
        <Body>
          {documents.map((document:any)=> (
            <Row key={document.id}>
              <Cell>{mapDocumentTypes(document.type)}</Cell>
              <Cell>
                <a 
                  href={document.url} 
                  target="_blank" 
                  rel="noopener noreferrer"
                >
                  {document.fileName}
                </a>
              </Cell>
            </Row>
          ))}
        </Body>
      </Table>
    </DocumentsContainerCSS>
  )
}

export default SubmittedDocuments;

