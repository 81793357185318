import styled from 'styled-components';

export const StripeCSS = styled.span`

  .card-info {
    border-bottom: solid 2px var(--gray100);
    padding: 0.5rem 1em;
    color: var(--gray200);
  }

  .ui.form .field>label {
    color: var(--gray200);
    margin-bottom: 0.5rem;
  }

  .ui.form .field {
    margin-bottom: 1.5rem;
  }

  .ui.form .field .ui.input input, .ui.form .fields .field .ui.input input, .ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
    border: none;
    border-bottom: solid 2px var(--gray100);
  }
`;