
import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import Media from 'react-media';
import { ListInvoiceContext } from 'src/context'
import { withFirebase, withUser } from 'src/components/Firebase';
import { useCollectionHook } from 'src/components/Firebase/utils';
import { tabletWidth } from 'src/config';
import sortByCreatedAt from 'src/utils/sortByCreatedAt';
import MobileInvoices from './screens/MobileInvoices';
import DesktopInvoices from './screens/DesktopInvoices';
import {
  sortInvoicesbyStatus,
} from './config';

const Invoices = (props: any) => {
  const { firebase, user } = props;
  const sellerRef = firebase
    .invoiceCollection()
    .where('sellerId', '==', user.merchantId)
    .orderBy('createdAt', 'desc');

  const buyerRef = firebase
    .invoiceCollection()
    .where('buyerId', '==', user.merchantId)
    .orderBy('createdAt', 'desc');


  const sellerData = useCollectionHook(sellerRef);
  const buyerData = useCollectionHook(buyerRef);

  const invoices = sortInvoicesbyStatus(sortByCreatedAt(R.concat(sellerData.data, buyerData.data)));

  const { t } = useTranslation('invoice');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <ListInvoiceContext.Provider value={invoices as any}>
        <Media query={{ minWidth: tabletWidth }}>
          {matches =>
            matches ? (
              <DesktopInvoices />
            )
              : (
                <MobileInvoices />
              )}
        </Media>
      </ListInvoiceContext.Provider>
    </>
  )
}

export default R.compose<any, any, any>(
  withUser,
  withFirebase,
)(Invoices);
