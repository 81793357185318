export default {
  title: 'Invoices',
  payTitle: 'Pay',
  getPaidTitle: 'Get Paid',
  referenceNumber: 'Reference Number',
  date: 'Date',
  requestedAmount: 'Requested Amount',
  remainingAmount: 'Remaining Amount',
  status: 'Status',
  role: 'Role',
  terms: 'I agree to the Terms and Conditions',
  errorMessage: 'You must agree to the terms and conditions',
  confirm: 'Confirm',
  transactions: 'Transactions',
  pay: 'Pay',
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
  nextPayment: 'Next Payment',
  getPaid: 'Get Paid',
  createdAt: 'Created At',
  invoices: 'Invoices',
  email: 'Email',
  phone: 'Phone',
  amount: 'Amount',
  type: 'Type',
  company: 'Company',
  newInvoice: 'Create Invoice',
  agreeToTermsAndConditions: 'I agree to the',
  congratulations: 'Congratulations',
  review: 'Your request has been reviewed and approved. Please review our offer below.',
  offeredAmount: 'Offer Amount',
  accept: 'Accept',
  decline: 'Decline',
  goBack: 'Go back',
  completed: 'Completed',
  ongoing: 'On-going',
  payForBuyer: 'Repay by seller on term complete',
  additionalPermissions: 'Other',
  dueDate: 'Due Date',
  purpose: 'Purpose'
};



