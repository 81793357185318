import React from 'react';
import PrivacyDetails from 'src/components/PrivacyDetails';
import { PrivacyCSS } from './styles';

const Privacy = () => {
  return (
    <PrivacyCSS className="wrapper-padding page-padding-bottom">
      <div className="container drop-shadow">
        <PrivacyDetails />
      </div>
    </PrivacyCSS>
  )
}

export default Privacy
