import React from 'react';
import { useTranslation } from 'react-i18next';
import { DesktopDashboardCSS } from './styles';
import Upcoming from '../../components/Upcoming';
import DoToday from '../../components/DoToday';
import CreditLimit from '../../components/CreditLimit';

const DesktopDashboard = ({ upcomingDueRecords, usedCredit }: any) => {
  const { t } = useTranslation('dashboard');
  return (
    <DesktopDashboardCSS className="wrapper-padding page-padding-bottom">
      <h1>{t('dashboard')}</h1>
      <div className="dashboard-container">
        <div className="table-container">
          <Upcoming className="upcoming-due" data={upcomingDueRecords} />
        </div>
        <div className="credit-limit-and-do-container">
          <CreditLimit className="credit-limit" usedCredit={usedCredit} />
          <DoToday className="do-today" />
        </div>
      </div>
    </DesktopDashboardCSS>
  );
};

export default DesktopDashboard;
