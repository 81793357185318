import styled from 'styled-components';
import { largeDesktopWidth } from 'src/config';

export const ItemCSS = styled.div`
  .label {
    margin-bottom: 0.5rem;
    color: var(--gray300);
    font-weight: 600;
  }

  .detail {
    font-size: 1rem;
    word-break: break-all;
  }

  &.full-width {
    grid-column-start: 1;
    grid-column-end: 4;
  }

  @media only screen and (min-width: ${largeDesktopWidth}px) {
    &.full-width {
      grid-column-end: 3;
    }
  }
`
