export default {
  amount: '金額',
  email: '電郵地址',
  cancel: '取消',
  cardNumber: '信用上號碼',
  expiration: '有效期',
  cv: 'CV',
  invalidEmail: '無效之電郵地址',
  amountEmptyError: '金額最少為 HKD$4.00',
  amountTooSmallError: '金額最少為 HKD$4.00',
  amountTooLargeError: '金額不能太於賬單應付額',
  remainingAmountLessThanFour: '賬單應付額不能少於 HKD$4.00',
  noCentsError: '請輸入整數',
  paymentType: '付款方式',
  selectPaymentType: '請選擇',
  success: '付款成功',
  close: '關閉',
  generalError: '發生錯誤，請重試',
  creditCard: 'Credit Card',
  fps: 'FPS',
  submit: '確定',
  bankAccountName: '銀行名稱',
  bankAccountNumber: '戶口號碼',
  fpsInstructions: 'Some instructions here for fps',
  upload: '上傳',
  uploadInvoiceProof: '入數記錄',
  fpsId: 'FPS ID',
}

