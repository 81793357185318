
import styled from 'styled-components';
import { tabletWidth, desktopWidth } from 'src/config';

export const AppCSS = styled.div`
  @media only screen and (min-width: ${tabletWidth}px) {
    .switch-container {
      width: 100%;
      min-height: calc(100vh - 50px);
      background: var(--gray100);
    }
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    display: flex;

    .switch-container {
      min-width: calc(100vh - 275px);
      // min-height: calc(100vh - 5px);
      min-height: 100vh;
    }
  }
`;