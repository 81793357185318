
import React from 'react';
import * as R from 'ramda';
import { Icon } from 'semantic-ui-react';
import { withMerchant, withUser } from 'src/components/Firebase';
import { UserInfoCSS } from './styles';

const UserInfo = (props: any) => {
  const { merchant, user } = props;
  return (
    <UserInfoCSS>
      <Icon
        name='user'
        size='large'
      />
      <h3>{user.name}</h3>
      <p className="company-name">{merchant.companyName}</p>
    </UserInfoCSS>
  )
}

export default R.compose<any, any, any>(
  withUser,
  withMerchant
)(UserInfo);
