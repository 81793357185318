import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react';
import {ConnectCSS} from './styles';

const Connect = (props:any) => {
  const { t } = useTranslation('requestForm');
  const {
    connectInvoiceNumber,
    connectInvoiceUrl,
    type,
    handleChange,
    connects
  } = props;

  const isConnected = connects[type];

  return (
    <ConnectCSS>
      {
        isConnected && (
          <>
            <Form.Field
              value={connectInvoiceNumber}
              control={Input}
              name='connectInvoiceNumber'
              label={t('invoiceNumber')}
              onChange={handleChange}
            />
            <p className='or'>{t('or')}</p>
          </>
        )
      }
      <Form.Field
        value={connectInvoiceUrl}
        control={Input}
        name='connectInvoiceUrl'
        label={t('invoiceUrl')}
        onChange={handleChange}
      />
      <hr className="hr-gray" />
    </ConnectCSS>
)
}

export default Connect;
