import styled from 'styled-components';

export const ListInvoicesCSS = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  .items-container {
    width: 100%;
    margin-top: -99px;
  }
`;