import styled from 'styled-components';
import { tabletWidth } from 'src/config';


export const EditDetailContainerCSS = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  padding: 1rem;
  max-width: 350px;
  margin: auto;

  &:first-child {
    margin-bottom: 2rem;
  }

  .ui.form .field > label {
    color: var(--gray200);
    line-height: 2;
    font-size: 1rem;
  }

  .ui.form .field .ui.input input {
    color: var(--fontColor);
  }

  .ui.button {
    width: 100%;
    background: var(--primaryLight);
    color: white;
    font-weight: 700;
    margin-top: 0.7rem;
    border: 1px solid var(--primarylight);
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    max-width: none;
    margin: 0;
    padding: 2rem;
  }

`;
