import * as R from 'ramda';

export const fetchOptions = (data: any, t:any) => {
  const defaultOption = {
    key: 0,
    text: t('addNew'),
    value: 'new',
  };

  const merchants = data.map((x: any, i: any) => ({
    key: i + 1,
    text: x.companyName,
    value: i,
  }));

  return [defaultOption, ...merchants];
};

export const invoiceOptions = (t:any) => {
  return ([
    {
      key: 'photo',
      text: t('photo'),
      value: 'photo'
    },
    {
      key: 'xero',
      text: t('xero'),
      value: 'xero'
    },
    {
      key: 'quickbooks',
      text: t('quickbooks'),
      value: 'quickbooks'
    },
  ])
}

export const formatConnects = (connects:any[]) => {
  if(!connects) {
    return {}
  };
  
  const connectHash:any = {};
  connects.forEach((x:any)=>{
    const {type} = x;
    connectHash[type] = true;
  });

  return connectHash;
}


export const initialState: any = {
  amount: '',
  purpose: '',
  deliveryTime: '',
  merchantIndex: null,
  merchantName: '',
  merchantEmail: '',
  merchantPhone: '',
  showConfirmation: false,
  invoiceId: '',
  uploadInvoiceIsLoading: false,
  uploadAdditionalIsLoading: false,
  requiredFiles: [],
  additionalFiles: [],
  extraPermissions: {},
  listPermissions: [],
  invoiceOption: null,
  connectInvoiceNumber: '',
  connectInvoiceUrl: '',
  connects: {}
};

export const formatSubmission = (
  state: any,
  pastMerchants: any,
  user: any,
  merchant: any,
  path: string
) => {
  const {
    amount,
    purpose,
    deliveryTime,
    merchantIndex,
    merchantName,
    merchantEmail,
    merchantPhone,
    invoiceId,
    extraPermissions,
    invoiceOption,
    connectInvoiceNumber,
    connectInvoiceUrl
  } = state;

  const isTrue = (x: any) => x === true;
  const permissions = R.filter(isTrue, extraPermissions);

  const referenceNumber = invoiceId.slice(0, 6).toUpperCase();

  const requestTo: any = {};

  if (merchantIndex === 'new') {
    requestTo.companyName = merchantName;
    requestTo.email = merchantEmail;
    requestTo.phone = merchantPhone;
  } else {
    requestTo.companyName = pastMerchants[merchantIndex].companyName;
    requestTo.email = pastMerchants[merchantIndex].email;
    requestTo.phone = pastMerchants[merchantIndex].phone;
  }

  let invoiceProofData = '';
  if(invoiceOption !== 'photo') {
    invoiceProofData = connectInvoiceNumber.length ? connectInvoiceNumber : connectInvoiceUrl
  }

  return {
    initiatedBy: {
      userId: user.id,
      merchantId: user.merchantId,
      type: path,
      companyName: merchant.companyName,
      email: user.email,
      phone: user.phone,
      permissions,
    },
    buyerConfirmed: path === 'pay',
    buyerId: path === 'pay' ? user.merchantId : null,
    requestedAmount: amount,
    sellerConfirmed: path === 'get-paid',
    sellerId: path === 'get-paid' ? user.merchantId : null,
    purpose,
    deliveryTime,
    referenceNumber,
    requestTo,
    invoiceProofType: invoiceOption,
    invoiceProofData,
  };
};
