import styled from 'styled-components';

export const ContainerCSS = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(100px, 1fr));
  grid-template-rows: auto;
  grid-gap: 20px;

  .single-document {
    padding: 2rem 0;
    text-align: center;
    border-radius: 5px;
    border: 2px dashed var(--gray200);
    color: var(--gray300);

    &:hover {
      cursor: pointer;
    }
  }
`;
