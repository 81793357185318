import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table as SemanticTable } from 'semantic-ui-react'
import { Link, withRouter } from 'react-router-dom';
import TableComponent from 'src/components/Table';
import { PAY } from 'src/screens/routes';

const Table = (props: any) => {
  const {
    Header,
    Row,
    HeaderCell,
    Body,
    Cell
  } = SemanticTable
  const { t } = useTranslation('dashboard');
  const { data } = props;

  return (
    <TableComponent>
      <SemanticTable unstackable selectable>
        <Header>
          <Row>
            <HeaderCell>{t('dueDate')}</HeaderCell>
            <HeaderCell>{t('to')}</HeaderCell>
            <HeaderCell>{t('amount')}</HeaderCell>
          </Row>
        </Header>
        <Body>
          {data.map((x: any) => {
            const { requestTo } = x;
            return (
              <Row key={x.id}>
                <Cell>
                  <Link to={`${PAY}/${x.id}`}>
                    <div className="cell">
                      {x.nextDueDate}
                    </div>
                  </Link>
                </Cell>
                <Cell>
                  <Link to={`${PAY}/${x.id}`}>
                    <div className="cell">
                      {requestTo.companyName}
                    </div>
                  </Link>
                </Cell>
                <Cell>
                  <Link to={`${PAY}/${x.id}`}>
                    <div className="cell">
                      {x.remainingAmount}
                    </div>
                  </Link>
                </Cell>
              </Row>
            )
          })}
        </Body>
      </SemanticTable>
    </TableComponent>
  )
}

export default withRouter(Table);