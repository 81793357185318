import styled from 'styled-components';

export const ItemCSS = styled.div`
  margin-bottom: 0.75rem;

  &.full-width {
    flex-direction: column;
    align-items: flex-start;
  }

  p {
    color: white;
    font-size: 1.1rem;
    line-height: 1.5;

    &.label {
      font-weight: 400;
      font-size: 95%;
    }

    &.detail {
      color: white;
      font-weight: 600;
      word-break: break-all;
    }

    &.label {
      white-space: nowrap;
    }
  }
`;
