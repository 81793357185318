import React from 'react';
import Media from 'react-media';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as ROUTES from 'src/screens/routes';
import DesktopSettings from 'src/screens/Settings/screens/DesktopSettings';
import Profile from 'src/screens/Settings/screens/Profile';
import MobileConnect from 'src/screens/Settings/screens/MobileSettings/screens/Connect';
import MobileDocuments from 'src/screens/Settings/screens/MobileSettings/screens/Documents';
import MobileDocumentsUpload from 'src/screens/Settings/screens/MobileSettings/screens/Documents/screens/Upload';
import { desktopWidth } from 'src/config';


const SettingRoutes = () => {
  const RenderMobileSettingRoutes = () => {
    return (
      <Switch>
        <Route exact path={ROUTES.SETTINGS} component={Profile} />
        <Route exact path={ROUTES.MERCHANT_DOCUMENTS} component={MobileDocuments} />
        <Route path={ROUTES.MERCHANT_DOCUMENTS_UPLOAD} component={MobileDocumentsUpload} />
        <Route path={ROUTES.CONNECT} component={MobileConnect} />
        <Route component={Profile} />
      </Switch>
    );
  };

  const RenderDesktopSettingRoutes = () => {
    return (
      <Switch>
        <Route
          exact
          path={ROUTES.SETTINGS}
          component={DesktopSettings}
        />
        <Redirect to={ROUTES.SETTINGS} />
      </Switch>
    );
  };

  const { t } = useTranslation('settings');

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <Media query={{ maxWidth: desktopWidth - 1}}>
        {matches =>
          matches 
            ? <RenderMobileSettingRoutes />
            : <RenderDesktopSettingRoutes />}
      </Media>
    </>
  );
};

export default SettingRoutes;
