/* eslint-disable */
import React from 'react';

const PrivacyDetails = () => {
  return (
    <div className="privacy-wrapper container-padding">
      <h2>PRIVACY POLICY</h2>
      <p>This policy sets out: </p>
      <ol>
        <li>the information we collect about you when you visit our website, use our products or services, or otherwise interact with us;</li>
        <li>how we use, share, store, and secure the information; and</li>
        <li>how you may access and control the information. </li>
      </ol>
      <p>In this policy, <b>“Uprise”</b> or <b>“we”</b> refers to Uprise Credit Limited of Unit 1816 Bonham Circus, 40 Bonham Strand, Sheung Wan, Hong Kong and <b>“Platform”</b> means our website at uprise.hk and uprisecredit.com.</p>
      <p>In this policy, <b>“personal information”</b> refers to any data, information, or combination of data and information that is provided by you to us, or through your use of our products or services, that relates to an identifiable individual.</p>
      <ol>
        <li>
          <p><b>What information we collect about you</b></p>
          <ol>
            <li>
              <p className="privacy-inline">We collect the following types of information about you:</p>
              <ul>
                <li>account and profile information that you provide when you register for an account or sign up for our products or services, for example name and address, occupation, contact details, date of birth and nationality of customers and spouses of customers and their identity card and/or passport numbers and place and date of issue thereof; name and contact details of referees of customers; current employer, nature of position, salary and other benefits of customers and spouses of customers; details of properties, assets or investments held by customers and their spouses; details of all other assets or liabilities (actual or contingent) of customers and their spouses; information obtained by the Company in the ordinary course of the continuation of the business relationship (for example, when customers generally communicate verbally or in writing with the Company, by means of documentation or telephone recording system or on-line facility, as the case may be); information as to credit standing provided by a referee, credit reference agency or debt collection agency in connection with a request to collect a debt due from any customer to the Company; and information which is in the public domain. The Company may hold other kinds of personal data which it needs in the light of experience and the specific nature of its business. (collectively, <b>“Account Data”</b>);</li>
                <li>information you provide through support channels, for example when you report a problem to us or interact with our support team, including any contact information, documentation, or screenshots (collectively, <b>“Support Data”</b>);</li>
                <li>communication, marketing, and other preferences that you set when you set up your account or profile, or when you participate in a survey or a questionnaire that we send you (collectively, <b>“Preference Data”</b>);</li>
                <li>information about your device or connection, for example your internet protocol (IP) address, log-in data, browser type and version, time-zone setting, browser plug-in types and versions, operating system and platform, and other technology on the devices you use to access our products or services and information we collect through cookies and other data collection technologies (please read our Cookies Policy for details) (collectively, <b>“Technical Data”</b>); and</li>
                <li>information about your use of or visit to our Platform, for example your clickstream to, through, and from our Platform, products you viewed, used, or searched for, page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs), or methods to browse away from the page (collectively, <b>“Usage Data”</b>).</li>
              </ul>
            </li>
            <li>
              <p className="privacy-inline">We collect the above information when you provide it to us or when you use or visit our Platform. We may also receive information about you from other sources, including:</p>
              <ul>
                <li>other services linked to your account, for example if you register or log in your account using your business services accounts credentials, we receive your name and email address to authenticate you, as permitted by your business services providers profile settings;</li>
                <li>our business partners and service providers based in Hong Kong, the United Kingdom, Canada, the USA, Ireland and Singapore who provide payment and analytics.</li>
              </ul>
            </li>
            <li>We do not collect sensitive data or special category data about you. This includes details about your race, ethnic origin, politics, religion, trade union membership, genetics, biometrics, health, or sexual orientation.</li>
          </ol>
        </li>
        <li>
          <p><b>How we use information we collect</b></p>
          <ol>
            <li>
              <p className="privacy-inline">We only use your personal information where the law allows us to. We use your personal information only where:</p>
              <ul>
                <li>we need to perform the contract we have entered into (or are about to enter into) with you, including to operate our products or services, to provide customer support and personalised features, and to protect the safety and security of our Platform;</li>
                <li>it satisfies a legitimate interest which is not overridden by your fundamental rights or data protection interests, for example for research and development, and in order to protect our legal rights and interests</li>
                <li>you've given us consent to do so for a specific purpose, for example we may send you direct marketing materials or publish your information as part of our testimonials or customer stories to promote our products or services with your permission; or</li>
                <li>we need to comply with a legal or regulatory obligation.</li>
              </ul>
            </li>
            <li>If you have given us consent to use your personal information for a specific purpose, you have the right to withdraw your consent any time by contacting us (please refer to paragraph 8 for contact information), but please note this will not affect any use of your information that has already taken place.</li>
            <li>We do not share your personal information with any company outside our group for marketing purpose, unless with your express specific consent to do so.</li>
            <li>For visitors to or users of our Platform who are located in the European Union, we have set out our legal bases for processing your information in the Legal Bases Table at the end of this policy.</li>
          </ol>
        </li>
        <li>
          <p><b>How we share information we collect</b></p>
          <ol>
            <li>We may share personal information on aggregated or de-identified basis with third parties for research and analysis, profiling, and similar purposes to help us improve our products and services.</li>
            <li>If you use any third-party software in connection with our products or services, for example any third-party software that our Platform integrates with, you might give the third-party software provider access to your account and information. Policies and procedures of third-party software providers are not controlled by us, and this policy does not cover how your information is collected or used by third-party software providers. We encourage you to review the privacy policies of third-party software providers before you use the third-party software.</li>
            <li>Our Platform may contain links to third-party websites over which we have no control. If you follow a link to any of these websites or submit information to them, your information will be governed by their policies. We encourage you to review the privacy policies of third-party websites before you submit information to them.</li>
            <li>We may share your information with government and law enforcement officials to comply with applicable laws or regulations, for example when we respond to claims, legal processes, law enforcement, or national security requests.</li>
            <li>If we are acquired by a third party as a result of a merger, acquisition, or business transfer, your personal information may be disclosed and/or transferred to a third party in connection with such transaction. We will notify you if such transaction takes place and inform you of any choices you may have regarding your information.</li>
          </ol>
        </li>
        <li>
          <p><b>How we store and secure information we collect</b></p>
          <ol>
            <li>We use data hosting service providers based in the US to host the information we collect.</li>
            <li>
              <p className="privacy-inline">We have adopted the following measures to protect the security and integrity of your personal information:</p>
              <ul>
                <li>information is encrypted using TLS/SSL technology;</li>
                <li>your account is password-protected, with the requirement(s) that passwords must be at least 8 characters long, with a combination of letters and numbers;</li>
                <li>access to your personal information is restricted to personnel or service providers on a strictly need-to-know basis, who will only process your information on our instructions and who are subject to a duty of confidentiality; and</li>
                <li>our information collection, storage, and processing practices are reviewed regularly. </li>
              </ul>
            </li>
            <li>We have put in place procedures to deal with any suspected privacy breach and will notify you and any applicable regulator of a breach where we are legally required to do so.</li>
            <li>While we implement safeguards designed to protect your information, please note that no transmission of information on the Internet is completely secure. We cannot guarantee that your information, during transmission through the Internet or while stored on our systems or processed by us, is absolutely safe and secure.</li>
            <li>We only retain personal information for so long as it is reasonably necessary to fulfil the purposes we collected it for, including for the purposes of satisfying any legal, accounting, or reporting requirements. After such time, we will delete or anonymise your information, or if this is not possible, we will securely store your information and isolate it from further use. We periodically review the basis and appropriateness of our data retention policy.</li>
          </ol>
        </li>
        <li>
          <p><b>Your rights</b></p>
          <ol className="privacy-margin-top">
            <li>
              <p className="privacy-inline">You have the right to:</p>
              <ul>
                <li>be informed of what we do with your personal information;</li>
                <li>request a copy of personal information we hold about you; </li>
                <li>require us to correct any inaccuracy or error in any personal information we hold about you;</li>
                <li>request erasure of your personal information (note, however, that we may not always be able to comply with your request of erasure for record keeping purposes, to complete transactions, or to comply with our legal obligations);</li>
                <li>object to or restrict the processing by us of your personal information (including for marketing purposes);</li>
                <li>request to receive some of your personal information in a structured, commonly used, and machine readable format, and request that we transfer such information to another party; and</li>
                <li>withdraw your consent at any time where we are relying on consent to process your personal information (although this will not affect the lawfulness of any processing carried out before you withdraw your consent).  </li>
              </ul>
            </li>
            <li>Our Platform enables you to update certain information about yourself, for example you may change your business or personal information by updating your user profile or changing your user settings.</li>
            <li>You may opt out of receiving marketing materials from us by contacting us. Please note, however, that even if you opt out from receiving marketing materials from us, you will continue to receive notifications or information from us that are necessary for the use of our products or services.</li>
            <li>As a security measure, we may need specific information from you to help us confirm your identity when processing your privacy requests or when you exercise your rights. </li>
            <li>Any request under paragraph 5.1 will normally be addressed free of charge. However, we may charge a reasonable administration fee if your request is clearly unfounded, repetitive, or excessive.</li>
            <li>We will respond to all legitimate requests within one (1) month.  Occasionally, it may take us longer than a month if your request is particularly complex or if you have made a number of requests.</li>
          </ol>
        </li>
        <li>
          <p><b>Changes to this policy</b></p>
          <ol>
            <li>We may amend this policy from time to time by posting the updated policy on our Platform. By continuing to use our Platform after the changes come into effect, you agree to be bound by the revised policy</li>
          </ol>
        </li>
        <li>
          <p><b>Policy towards children</b></p>
          <ol>
            <li>Our products and services are not directed to individuals under 18. We do not knowingly collect personal information from individuals under 18. If we become aware that an individual under 18 has provided us with personal information, we will take steps to delete such information. Contact us if you believe that we have mistakenly or unintentionally collected information from an individual under 18.</li>
          </ol>
        </li>
        <li>
          <p><b>Contact us</b></p>
          <ol>
            <li>
              Please contact us at enquiry@uprisecredit.com or submit any written request to: <br />
              Uprise Credit Limited<br />
              Unit 1816 Bonham Circus, 40 Bonham Strand, Sheung Wan, Hong Kong<br />
              Attn: Data Protection Officer<br />
            </li>
            <li>Please contact us in the first instance if you have any questions or concerns. If you have unresolved concerns, you have the right to file a complaint with a data protection authority in the country where you live or work or where you feel your rights have been infringed.</li>
          </ol>
        </li>
      </ol>
      <p>Last updated: 8 March 2019</p>
      <h3>COOKIES POLICY</h3>
      <p>Cookies are small text files that are placed on your device by a web server when you access our Platform. We use cookies to identify your access and monitor usage and web traffic on our Platform to customise and improve our products and services.</p>
      <p>We use both persistent cookies and session cookies. A persistent cookie stays in your browser and will be read by us when you return to our Site or a partner site that uses our services. Session cookies only last for as long as the session lasts (usually the current visit to a website or a browser session).</p>
      <p>We use the following types of cookies: </p>
      <ol>
        <li>Strictly necessary cookies – these are cookies that are required for the operation of our site. They include, for example, cookies that enable you to log into secure areas of our website.</li>
        <li>Analytical/performance cookies – these allow us to recognise and count the number of visitors and to see how visitors move around our Site when they are using it. This helps us to improve the way our site works, for example, by ensuring that users are easily finding what they are looking for.</li>
        <li>Functionality cookies – these are used to recognise you when you return to our site.</li>
        <li>Targeting cookies – these cookies record your visit to our site, the pages you have visited, and the links you have followed.</li>
      </ol>
      <p>You can block cookies by activating the setting on your browser that allows you to refuse the use of all or some cookies. However, if you do so, you may not be able to access all or parts of our site.</p>
      <h3>LEGAL BASES TABLE</h3>
      <table className="privacy-table">
        <thead>
          <tr>
            <td>Processing purpose</td>
            <td>Type of data processed</td>
            <td>Legal basis</td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>To register you as a user on our Platform</td>
            <td>Account Data</td>
            <td>To perform our contract with you</td>
          </tr>
          <tr>
            <td>To enable you to use our products and services</td>
            <td>Account Data, Transaction Data, Support Data, Technical Data [and User Content]</td>
            <td>To perform our contract with you</td>
          </tr>
          <tr>
            <td>To process your payments </td>
            <td>Account Data, Transaction Data, Financial Data</td>
            <td>To perform our contract with you</td>
          </tr>
          <tr>
            <td>To collect overdue amounts</td>
            <td>Account Data, Transaction Data, Financial Data</td>
            <td>Legitimate interest to recover debts due to us</td>
          </tr>
          <tr>
            <td>To notify you about changes to our products, services, or terms</td>
            <td>Account Data</td>
            <td>To perform our contract with you</td>
          </tr>
          <tr>
            <td>To administer and maintain safety and security of our Platform</td>
            <td>Technical Data</td>
            <td>To perform our contract with you</td>
          </tr>
          <tr>
            <td>To study usage of our products or services</td>
            <td>Transaction Data, Support Data, Technical Data, Usage Data</td>
            <td>Legitimate interest to improve our Platform, products, and services</td>
          </tr>
          <tr>
            <td>To gather feedback on our products, services, or features</td>
            <td>Account Data</td>
            <td>Legitimate interest to improve our Platform, products, and services</td>
          </tr>
          <tr>
            <td>To provide information on products or services that may be of interest to you</td>
            <td>Account Data, Preference Data</td>
            <td>Consent, which you may withdraw any time</td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}

export default PrivacyDetails;