import React from 'react';
import { DetailsCSS } from './styles';

const Details = (props: any) => {
  const { className } = props;
  return (
    <DetailsCSS className={`drop-shadow ${className}`}>
      <p className="detail">
        <a href="tel:85265890150">+852 6589 0150</a>
      </p>
      <p className="detail">
        enquiry@uprisecredit.com
      </p>
      <p className="detail">
        Unit 1816
        <br />
        Bonham Circus
        <br />
        40 Bonham Strand
        <br />
        Sheung Wan
        <br />
        Hong Kong
      </p>
    </DetailsCSS>
  )
}

export default Details;