import styled from 'styled-components';

export const CreditLimitCSS = styled.div`
  width: 100%;
  height: 100px;
  background: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  color: white;
  padding-bottom: 7rem !important;

  h2 {
    font-weight: 300;
    margin-bottom: 0;
  }
`