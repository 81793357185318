import React, { useState } from 'react';
import View from './components/View';
import Edit from './components/Edit';

const User = (props: any) => {
  const [isEditing, toggleEdit] = useState(false);
  return (
    !isEditing
      ? (
        <View
          toggleEdit={toggleEdit}
        />
      )
      : (
        <Edit
          toggleEdit={toggleEdit}
          {...props}
        />
      )
  )
}

export default User;