import React from 'react';
import { ItemCSS } from './styles';

const LeftBorderItem = (props: any) => {
  const { children, className } = props;
  return (
    <ItemCSS className={className}>
      {children}
    </ItemCSS>
  )
}

export default LeftBorderItem;