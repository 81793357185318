import styled from 'styled-components';
import { animated } from 'react-spring';

export const NavItemsContainerCSS = styled.div`
  display: flex;
  flex-direction: column;

  ul {
    flex: 1;
    list-style: none;
    padding-bottom: 1rem;
  }

  li {
    color: white;
    padding: 1em 1em 1em 1.5em;
    font-weight: 400;
    line-height: 1.5;
    font-size: 1.2rem;

    &.active {
      background: var(--primaryDark);
    }
  }

  .terms-container {
    color: var(--gray300);
    width: 100%;
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;

    &:hover {
      color: white;
    }

    & > * {
      margin: 0.2rem 0;
    }
  }

  &.nav-items-container {
    position: fixed;
    background: var(--primary);
    overflow-y: auto;
    overflow-x: hidden;
    width: 250px;
    left: -250px;
    top: 50px;
    -webkit-transition: transform 350ms; /* For Safari 3.1 to 6.0 */
    transition: transform 350ms;
    transition-timing-function: ease-in;
    height: calc(100vh - 50px);
    z-index: 6;
    overflow-y: auto;

    &.active {
      transform: translate(250px);
      transition-timing-function: ease-in;
      -webkit-transition: transform 350ms; /* For Safari 3.1 to 6.0 */
      transition: transform 350ms;
    }
  }
`;

export const NestedCSS = styled(animated.div)`
  overflow: hidden;

  li.nested {
    background: var(--primaryDark);
  }
`;
