
import React from 'react';
import * as R from 'ramda';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { menuItems } from '../../../../config';
import { NavItemsCSS } from './styles';

const NavItems = (props: any) => {
  const { t } = useTranslation('nav');

  const renderNavItems = (item: any) => {
    const active = item.url === R.pathOr(false, ['location', 'pathname'], props) ? 'active' : '';

    return (
      <Link key={item.displayKey} to={item.url}>
        <li
          className={active}
        >
          <Icon
            name={item.icon}
          />
          {t(item.displayKey)}
        </li>
      </Link>
    )
  }


  return (
    <NavItemsCSS>
      <ul>
        {menuItems.map((x: any) => renderNavItems(x))}
      </ul>
    </NavItemsCSS>
  )
}

export default withRouter(NavItems);