import styled from 'styled-components';

export const MobileOngoingAndCompletedInvoiceCSS = styled.div`
  width: 100%;

  .ui.button {
    font-weight: 300;
    font-size: 1.3rem;
    padding: 0.5rem 1rem;
  }

  .title-and-button-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0 0 0;

    p {
      font-weight: 400;
    }
  }

  p {
    color: var(--primaryDark);
    font-weight: 300;
    font-size: 1.5rem;
    margin-bottom: 0;
  }
`;
