import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Input } from 'semantic-ui-react'
import { NewMerchantDetailCSS } from './styles';

export const NewMerchantDetail = (props: any) => {
  const { t } = useTranslation('requestForm');
  const {
    handleChange,
    className,
    merchantName,
    merchantEmail,
    merchantPhone
  } = props;
  return (
    <NewMerchantDetailCSS className={className}>
      <Form.Field
        required={className.length > 0}
        control={Input}
        name='merchantName'
        label={t('companyName')}
        placeholder={t('companyName')}
        onChange={handleChange}
        value={merchantName}
      />
      <Form.Field
        required={className.length > 0}
        control={Input}
        name='merchantEmail'
        label={t('email')}
        placeholder={t('email')}
        onChange={handleChange}
        value={merchantEmail}
      />
      <Form.Field
        control={Input}
        name='merchantPhone'
        label={t('phone')}
        placeholder={t('phone')}
        onChange={handleChange}
        value={merchantPhone}
      />
    </NewMerchantDetailCSS>
  )
}

export default NewMerchantDetail;