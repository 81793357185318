import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Media from 'react-media';
import { desktopWidth } from 'src/config';
import Details from './components/Details';
import Form from './components/Form';
import ContactSuccess from './components/ContactSuccess';
import { ContactCSS } from './styles';

const Contact = () => {
  const { t } = useTranslation('contact');
  const [showSuccess, setShowSuccess] = useState(false);

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <ContactCSS className="wrapper-padding page-padding-bottom gray-background">
        <Media
          query={`(min-width: ${desktopWidth}px)`}
          render={() => (
            <h1>{t('contact')}</h1>
          )}
        />
        <div className="contact-container">
          <Details className="details" />
          {
            showSuccess
            ? <ContactSuccess />
            : <Form className="form" onComplete={() => setShowSuccess(true)} />
          }
        </div>
      </ContactCSS>
    </>
  )
}

export default Contact;
