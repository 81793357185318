import styled from 'styled-components';

export const DesktopUpcomingCSS = styled.div`
  padding: 2.3rem 1.3rem;
  background: white;

  h3 {
    font-weight: 400;
    color: var(--gray200);
    margin-bottom: 1.4rem;
  }
`;