import styled from 'styled-components';

export const FpsCSS = styled.div`
  .ui.form {
    margin-top: 1.5rem;
  }

  .ui.button {
    width: 100%;
  }
`;