import axios from 'axios';

export async function fetchConnectTokenState(merchantId: string): Promise<string> {
  const response = await axios.post(`https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/api/connect-tokens`, {
    merchantId,
  });

  const { state } = response.data;

  if (!state) {
    throw new Error('Fetch connect token state error');
  }

  return state;
};

export function createXeroLink(state: string): string {
  const data: any = {
    state,
    response_type: 'code',
    client_id: process.env.REACT_APP_XERO_CLIENT_ID,
    scope: 'openid profile email offline_access accounting.transactions',
    redirect_uri: process.env.REACT_APP_XERO_CALLBACK_URI,
  };
  const searchParams = new URLSearchParams(data);
  const link = `https://login.xero.com/identity/connect/authorize?${searchParams.toString()}`;

  return link;
};

export function createQuickBooksLink(state: string): string {
  const data: any = {
    state,
    response_type: 'code',
    client_id: process.env.REACT_APP_QUICKBOOKS_CLIENT_ID,
    scope: 'com.intuit.quickbooks.accounting openid profile email phone address',
    redirect_uri: process.env.REACT_APP_QUICKBOOKS_CALLBACK_URI,
  };
  const searchParams = new URLSearchParams(data);
  const link = `https://appcenter.intuit.com/connect/oauth2?${searchParams.toString()}`;
  return link;
};

export const connects: { [id: string]: Function } = {
  xero: createXeroLink,
  quickbooks: createQuickBooksLink,
};
