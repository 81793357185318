import React from 'react';
import Media from 'react-media';
import { tabletWidth } from 'src/config';
import { withUser } from 'src/components/Firebase';
import DesktopUpcoming from './components/DesktopUpcoming';
import MobileUpcoming from './components/MobileUpcoming';

const Upcoming = (props: any) => {
  return (
    <Media query={{ minWidth: tabletWidth }}>
      {matches =>
        matches ? (
          <DesktopUpcoming {...props} />
        ) : (
          <MobileUpcoming {...props} />
        )
      }
    </Media>
  );
};

export default (withUser)(Upcoming);
