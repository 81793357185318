import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapDocumentTypes } from 'src/screens/Settings/config';
import SingleDocument from '../../../../../../components/SingleDocument';
import ListDocuments from '../ListDocuments';
import { filterRepeat } from '../../../../../../config';

const RequiredDocuments = (props:any) => {
  const { t } = useTranslation(['settings', 'general']);
  const { toggleScreen, documents } = props;

  const requiredDocuments = filterRepeat(documents);

  return (
    <ListDocuments 
      toggleScreen={toggleScreen}
      title={t('documentsRequired')}
      returnTo={t('submittedDocuments')}
    >
      {requiredDocuments.map((document:any)=>{
        return (
          <SingleDocument key={document.id}>
            <h4>{mapDocumentTypes(document.type.toUpperCase())}</h4>
          </SingleDocument>
        )
      })}
    </ListDocuments>
  )
}


export default RequiredDocuments;
