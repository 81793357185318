import { useEffect, useState } from 'react';
import axios from 'axios';

export const useConnectHook = (type: string) => {
  const urlParams = new URLSearchParams(window.location.search);
  const code = urlParams.get('code');
  const state = urlParams.get('state');
  const realmId = urlParams.get('realmId');

  const [connectState, setConnectState] = useState(null);

  const checkType: any = {
    xero: 'xero-connect',
    quickbooks: 'quickbooks-connect',
  };

  useEffect(() => {
    const fetchConnect = async () => {
      try {
        const data = {
          state,
          code,
          realmId,
        };

        const { status } = await axios.post(
          `${process.env.REACT_APP_API}/${checkType[type]}`,
          data
        );

        if (status !== 200) {
          throw Error('failed');
        }

        setConnectState(true);
      } catch (error) {
        setConnectState(false);
      }
    };

    fetchConnect();
  }, []);

  return {
    connectState,
  };
};
