import React from 'react';
import { Icon, Button } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SuccessCSS } from './styles';

const Success = (props: any) => {
  const { toggleShowModal, setSuccess } = props;
  const { t } = useTranslation('payment');
  const handleClose = () => {
    setSuccess(false);
    toggleShowModal(false);
  }
  return (
    <SuccessCSS>
      <h1>{t('success')}</h1>
      <Icon
        name='smile outline'
        size='big'
      />
      <Button
        className="secondary"
        onClick={() => handleClose()}
        type="button"
      >
        {t('close')}
      </Button>
    </SuccessCSS>
  )
}

export default Success;