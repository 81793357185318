import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { SingleInvoiceContext, SingleInvoiceOfferContext } from 'src/context';
import { withUser } from 'src/components/Firebase';
import { DetailsCSS } from './styles';
import Item from './components/Item';

const Details = (props: any) => {
  const { user } = props;
  const { t } = useTranslation('invoice');
  return (
    <SingleInvoiceContext.Consumer>
      {(invoice: any) => {
        const { requestTo, initiatedBy } = invoice;
        const company =
          user.merchantId === initiatedBy.merchantId
            ? requestTo.companyName
            : initiatedBy.companyName;
        const didUserInitiateThisInvoice = user.merchantId === initiatedBy.merchantId;
        const email = didUserInitiateThisInvoice ? requestTo.email : initiatedBy.email;
        const phone = didUserInitiateThisInvoice ? requestTo.phone : initiatedBy.phone;
        const isBuyer = invoice.buyerId === user.merchantId;
        const isSeller = invoice.sellerId === user.merchantId;

        return (
          <SingleInvoiceOfferContext.Consumer>
            {(offer: any) => {
              return (
                <DetailsCSS>
                  <h2>{company}</h2>
                  <div id="invoice-item-container">
                    <Item detail={invoice.referenceNumber} label="referenceNumber" />
                    <Item detail={invoice.requestedAmount} label="requestedAmount" />
                    {isSeller && didUserInitiateThisInvoice && (
                      <Item detail={offer.offeredAmount} label="offeredAmount" />
                    )}
                    {isSeller && !didUserInitiateThisInvoice && (
                      <Item detail={offer.requestedAmount} label="offeredAmount" />
                    )}
                    {!!isBuyer && <Item detail={invoice.remainingAmount} label="remainingAmount" />}
                    <Item detail={email} label="email" />
                    <Item detail={phone} label="phone" />
                    <Item detail={t(invoice.status)} label="status" />
                    <Item className="full-width" detail={invoice.purpose} label="purpose" />
                  </div>
                </DetailsCSS>
              );
            }}
          </SingleInvoiceOfferContext.Consumer>
        );
      }}
    </SingleInvoiceContext.Consumer>
  );
};

export default R.compose<any, any, any>(withUser, withRouter)(Details)
