export default {
  title: '設定',
  resetPasswordTitle: '重設密碼',
  email: '電郵地墟',
  phone: '電話號碼',
  website: '網址',
  name: '名稱',
  bankName: '銀行名稱',
  bankNumber: '銀行戶口',
  save: '儲存',
  cancel: '取消',
  resetPassword: '重設密碼',
  newPassword: '新密碼',
  newPasswordConfirm: '確認密碼',
  reset: '重設',
  passwordCheckError: '確認密碼錯誤',
  passwordLengthError: '密碼長度最小 6 位',
  passwordUpdated: '重設密碼成功',
  documentsRequired: '證明文件',
  pleaseAllow24Hours: '請選擇文件',
  submittedDocuments: '已提交文件',
  requiredDocuments: '證明文件',
  upload: '上傳',
  somethingWentWrong: '發生錯誤',
  tryAgain: '請重試',
  fileAlreadyExist: '請選擇其他檔案',
  profile: '戶口',
  documents: '文件',
  connect: '連接',
  type: '類型',
  fileName: '檔案名稱',
  connectSuccess: '連接成功!',
  connectFail: '發生錯誤，請重試',
  closeTab: '您現在可關閉視窗以繼續',
}


