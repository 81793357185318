import styled from 'styled-components';

export const ItemCSS = styled.div`
  width: 100%;
  background: white;
  margin: 1.5rem 0;
  border-left: 7px solid var(--primaryDark);
  border-radius: 6px;
  padding: 1rem;
  color: var(--primaryDark);

  .type {
    color: var(--fontColor);
  }

  &.pending {
    border-left: 7px solid var(--gray200);

    .status {
      color:var(--gray200);
    }
  }

  &.rejected {
    border-left: 7px solid var(--rejectRed);

    .status {
      color:var(--rejectRed);
    }
  }

  &.ongoing {
    border-left: 7px solid var(--primaryLight);

    .status {
      color:var(--primaryLight);
    }
  }

  &.completed {
    border-left: 7px solid var(--primaryDark);

    .status {
      color:var(--primaryDark);
    }
  }

  &.complete {
    border-left: 7px solid var(--primaryDark);
  }

  h3 {
    margin-bottom: 0.3rem;

    i.icon, i.icons {
      margin-left: 0.7rem;
      color: #ff6161;
    }
  }

  p {
    margin-bottom: 0.3rem;
    font-size: 1rem;
  }

  .label {
    color: var(--primary);
  }

  .detail { 
    margin-left: 10px;
  }

  .detail-container {
    display: flex;
    justify-content: space-between;
    align-items:center;
  }

`;