import React from 'react';
import { Switch, Route } from 'react-router-dom';
import * as ROUTES from 'src/screens/routes';
import Xero from 'src/screens/ConnectsCallback/Xero';
import Quickbooks from 'src/screens/ConnectsCallback/Quickbooks';
import TermsAndCondition from '../TermsAndCondition';
import Privacy from '../Privacy';


const Routes = (props: any) => {
  const { children } = props;
  return (
    <Switch>
      <Route path={ROUTES.TERMS_AND_CONDITIONS} component={TermsAndCondition} />
      <Route path={ROUTES.PRIVACY} component={Privacy} />
      <Route path={ROUTES.XERO_CALLBACK} component={Xero} />
      <Route path={ROUTES.QUICKBOOKS_CALLBACK} component={Quickbooks} />
      {children}
    </Switch>
  )
}


export default Routes;
