import React from 'react';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { SingleInvoice } from 'src/models/SingleInvoice';
import TableComponent from 'src/components/Table';
import SingleRow from './components/SingleRow';


const InvoicesTable = (props: any) => {
  const {
    Header,
    Row,
    HeaderCell,
    Body,
  } = Table;
  const { invoices } = props;
  const { t } = useTranslation('invoice');
  const formattedData = invoices.map((x: any) => SingleInvoice.formatData(x));

  return (
    <TableComponent>
      <Table unstackable singleLine selectable>
        <Header>
          <Row>
            <HeaderCell>{t('company')}</HeaderCell>
            <HeaderCell>{t('type')}</HeaderCell>
            <HeaderCell>{t('referenceNumber')}</HeaderCell>
            <HeaderCell>{t('date')}</HeaderCell>
            <HeaderCell>{t('requestedAmount')}</HeaderCell>
            <HeaderCell>{t('remainingAmount')}</HeaderCell>
            <HeaderCell>{t('status')}</HeaderCell>
          </Row>
        </Header>
        <Body>
          {formattedData.map((x: any) => {
            return (
              <SingleRow data={x} key={x.id} />
            )
          })}
        </Body>
      </Table>
    </TableComponent>
  )
}

export default InvoicesTable;