import React from 'react';
import InputNumber from 'rc-input-number';

const NumberInput = (props: any) => {
  return (
    <InputNumber
      formatter={(value: any) =>
        `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      }
      {...props}
    />
  )
}

export default NumberInput;