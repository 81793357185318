export const HOME = '/';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const INVOICES = '/invoices';
export const SINGLE_INVOICE = '/invoices/:id';
export const CONTACT = '/contact';
export const SETTINGS = '/settings';
export const RESET_PASSWORD = '/reset-password';
export const PAY = '/pay';
export const PAY_REQUEST = '/pay/request';
export const SINGLE_PAY = '/pay/:id';
export const GET_PAID = '/get-paid';
export const GET_PAID_REQUEST = '/get-paid/request';
export const SINGLE_GET_PAID = '/get-paid/:id';
export const LOGOUT = '/logout';
export const TERMS_AND_CONDITIONS = '/terms-and-conditions';
export const PRIVACY = '/privacy';
export const CONNECT = '/settings/connect';
export const MERCHANT_DOCUMENTS = '/settings/documents';
export const MERCHANT_DOCUMENTS_UPLOAD = '/settings/documents/upload';
export const XERO_CALLBACK = '/connect/xero/callback';
export const QUICKBOOKS_CALLBACK = '/connect/quickbooks/callback';

