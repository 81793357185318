import React, { useState } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { withMerchant, withFirebase, withUser } from 'src/components/Firebase'
import ViewDetailContainer from '../../../ViewDetailContainer';

const View = (props: any) => {
  const { t } = useTranslation('settings');
  const { toggleEdit, merchant, firebase, user, bank } = props;
  const [bankPermission, updateBankPermission] = useState(false);

  firebase.merchantTeamDoc(merchant.id, user.id).get().then((data: any) => {
    updateBankPermission(data.exists);
  });

  return (
    <ViewDetailContainer>
      <div className="header">
        <h3>{merchant.companyName}</h3>
        <Icon name='edit' onClick={() => toggleEdit(true)} />
      </div>
      <div className="all-details-container">
        <div className="detail-container">
          <p className="label">{t('email')}:</p>
          <p className="detail">{merchant.email}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('phone')}:</p>
          <p className="detail">{merchant.phone}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('website')}:</p>
          <p className="detail">{merchant.website}</p>
        </div>
        {!!bankPermission && (
          <>
            <hr />
            <div id="bank-detail-container">
              <p id="bank-label">{t('bankName')}</p>
              <p id="bank-detail">{bank.bankName}</p>
            </div>
            <div id="bank-detail-container">
              <p id="bank-label">{t('bankNumber')}</p>
              <p id="bank-detail">{bank.bankNumber}</p>
            </div>
          </>
        )}
      </div>
    </ViewDetailContainer>
  )
}

export default R.compose<any, any, any, any>(
  withUser,
  withMerchant,
  withFirebase,
)(View);