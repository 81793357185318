import React, { useCallback } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useDropzone } from 'react-dropzone';
import { withFirebase, withMerchant } from 'src/components/Firebase';


interface IProps {
  children: any;
  accept: string;
  maxSize: any;
  callback?: Function;
  filesCallback:Function;
  name?: string;
}


const Upload = (props: IProps) => {
  const { t } = useTranslation('requestForm');
  const {
    children,
    accept = 'image/*, .pdf, .doc, .docx',
    maxSize = 10485760,
    filesCallback,
    name
  } = props;

  const sizeCheck = (files: any) => {
    return files.filter((x: any) => {
      if (x.size > maxSize) {
        const sizeError = `${x.name} ${t('sizeLimitError')}`;
        toast.error(sizeError, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
        });
      };
      return x.size <= maxSize;
    })
  }

  const onDrop = useCallback(acceptedFiles => {
    try {
      if (!acceptedFiles.length) {
        throw new Error(t('noFilesFound'));
      }

      const filteredFiles = sizeCheck(acceptedFiles);

      if (!filteredFiles.length) {
        return;
      }
      if(name){
        filesCallback(name, filteredFiles);
      }
      else {
        filesCallback(filteredFiles);
      }
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }, [])


  // eslint-disable-next-line
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {children}
    </div>
  )
}

export default R.compose<any, any, any>(
  withFirebase,
  withMerchant
)(Upload);
