import React, { useState, useEffect } from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import Language from 'src/components/Language';
import { MobileNavCSS } from './styles';
import NavItemsContainer from './components/NavItemsContainer';
import { getDisplay } from '../../config';

const MobileNav = (props: any) => {
  const { t } = useTranslation('nav');
  const [isShowing, setShow] = useState(false);
  const currentLocation = getDisplay(R.pathOr('', ['location', 'pathname'], props));
  const [locationDisplay, setLocation] = useState(currentLocation);

  useEffect(() => {
    setLocation(currentLocation)
  });

  return (
    <MobileNavCSS>
      <div className='nav-placeholder' />
      <Language id="closed-routes" />
      <div className="nav">
        <div
          onClick={() => setShow(!isShowing)}
          role="button"
          className={classNames('nav-icon', { active: isShowing })}
        >
          <div />
        </div>
        <p>{t(locationDisplay)}</p>
      </div>
      <NavItemsContainer
        setShow={setShow}
        className={classNames('nav-items-container', { active: isShowing })}
      />
      <div
        role='presentation'
        onClick={() => setShow(false)}
        className={classNames('nav-items-container-overlay', { active: isShowing })}
      />
    </MobileNavCSS>
  )
}

export default withRouter(MobileNav);
