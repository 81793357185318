import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import Loading from 'src/components/Loading';
import User from './components/User';
import Company from './components/Company';
import { ProfileCSS } from './styles';

const Profile = () => {
  const { t } = useTranslation('settings');
  const [isLoading, toggleLoading] = useState(false);
  return (
    <>
      <Loading active={isLoading} />
      <ProfileCSS className="page-padding-bottom gray-background">
        <h3 className="title">{t('profile')}</h3>
        <User toggleLoading={toggleLoading} />
        <Company toggleLoading={toggleLoading} />
      </ProfileCSS>
    </>
  )
}

export default Profile;
