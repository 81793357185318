export default {
  title: '註冊',
  register: '註冊',
  businessDetails: '業務資料',
  companyName: '公司名稱',
  establish: '成立日期',
  annualTurnover: '年度營業額',
  personalDetails: '個人資料',
  name: '名稱',
  email: '電郵地址',
  phone: '電話號碼',
  password: '密碼',
  retypePassword: '確認密碼',
  passwordMismatch: '確認密碼不正確',
  passwordLength: '密碼長度最小 6 位',
  returnToLogin: '已有賬戶? 登入',
  invalidEmail: '無效的電郵地址',
  termsAndConditionError: '請同意條款及細則',
  agreeToTermsAndConditions: '我同意',
  accountCouldNotBeCreated: '註冊失敗，請重試',
}

