import React, { useState } from 'react';
import * as R from 'ramda';
import { Bank } from 'src/models/Bank';
import { withFirebase, withUser } from 'src/components/Firebase';
import { useDocumentHook } from 'src/components/Firebase/utils'
import View from './components/View';
import Edit from './components/Edit'

const Company = (props: any) => {
  const [isEditing, toggleEdit] = useState(false);
  const { firebase, user } = props;
  const bankRef = firebase.merchantBankDoc(user.merchantId);
  const bank = useDocumentHook(bankRef);
  const formatData = Bank.formatData(bank.data);

  return (
    !isEditing
      ? (
        <View
          bank={formatData}
          toggleEdit={toggleEdit}
        />
      )
      : (
        <Edit
          bank={formatData}
          toggleEdit={toggleEdit}
          {...props}
        />
      )
  )
}

export default R.compose<any, any, any>(
  withFirebase,
  withUser
)(Company);