import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { NoDataCSS } from './styles';


const NoData = (props: any) => {
  const { className = '' } = props;
  const { t } = useTranslation('general');

  return (
    <NoDataCSS className={className}>
      <Icon
        name='frown'
        size='massive'
      />
      <p>{t('noData')}</p>
    </NoDataCSS>
  )
}
export default NoData;