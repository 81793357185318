import styled from 'styled-components';

export const NewMerchantDetailCSS = styled.div`
  max-height: 0;
  overflow: hidden;
  height: auto;
  transition: max-height 550ms ease-out;

  &.active {
    max-height: 600px;
    transition: max-height 550ms ease-in;
  }

  .field:last-child {
    margin-bottom: 3em !important;
  }
`;