import styled from 'styled-components';
import { tabletWidth, desktopWidth } from 'src/config';


export const DetailsCSS = styled.div`
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 6px;

  h3 {
    text-align: center;
    color: var(--primaryDark);
    margin-bottom: 0.8rem;
  }

  h4 {
    color: var(--gray300);
  }

  .detail-container {
    display: flex;
    justify-content: space-between;
    align-items:center;
  }

  .label {
    color: var(--gray200);
    margin-right: 0.5rem;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0.3rem;
  }

  .ui.list .list>.item>i.icon, .ui.list>.item>i.icon {
    color: var(--gray200);
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    max-width: 500px;
    margin: auto;
    min-height: 300px;

    .detail-container {
      margin: 5px 0;
    }
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    margin-top: 2rem;
  }
`;