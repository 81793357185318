import React from 'react';
import { Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { withMerchant } from 'src/components/Firebase';
import { DesktopCreditLimitCSS } from './styles';
import { hasCredit } from '../../utils';

const DesktopCreditLimit = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { merchant, usedCredit } = props;
  const { className = '' } = props;

  return (
    <DesktopCreditLimitCSS className={`drop-shadow ${className}`}>
      <div id="credit-limit-container">
        <h2>{hasCredit(merchant.creditLimit) ? merchant.creditLimit : usedCredit}</h2>
        <p>{t(hasCredit(merchant.creditLimit) ? 'creditLimit' : 'usedCreditLimit')}</p>
      </div>
      <div id="dollar-icon-container">
        <Icon name="dollar sign" size="big" />
      </div>
    </DesktopCreditLimitCSS>
  );
};

export default withMerchant(DesktopCreditLimit);
