import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Success from 'src/components/Success';

const SuccessConfirmation = () => {
  const { t } = useTranslation('success');
  return (
    <Success>
      <h1>{t('confirmed')}</h1>
      <Link to='/'>
        <p>{t('backToDashboard')}</p>
      </Link>
      <Link to='/invoices'>
        <p>{t('viewInvoices')}</p>
      </Link>
    </Success>
  )
}

export default SuccessConfirmation;