
import React from 'react';
import { useTranslation } from 'react-i18next';
import NoData from 'src/components/NoData';
import Table from './components/Table';
import { DesktopUpcomingCSS } from './styles';

const DesktopUpcoming = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { className, data } = props;
  return (
    <DesktopUpcomingCSS className={`${className} drop-shadow`}>
      <h3>{t("upcomingDue")}</h3>
      <Table {...props} />
      {!data.length && <NoData />}
    </DesktopUpcomingCSS>
  )
}

export default DesktopUpcoming;
