import React from 'react';
import { SingleInvoiceContext } from 'src/context';
import { withUser } from 'src/components/Firebase';
import Details from './components/Details';
import Transactions from './components/Transactions';
import { DesktopOngoingAndCompletedInvoiceCSS } from './styles';

const DesktopOngoingAndCompletedInvoice = (props: any) => {
  const { user } = props;
  return (
    <SingleInvoiceContext.Consumer>
      {(invoice: any) => {
        const { requestTo, initiatedBy } = invoice;
        const company = user.merchantId === initiatedBy.merchantId ? requestTo.companyName : initiatedBy.companyName;
        return (
          <DesktopOngoingAndCompletedInvoiceCSS>
            <h1>{company}</h1>
            <div className="invoice-grid-container">
              <Details invoice={invoice} className="merchant-details-container" />
              <Transactions invoice={invoice} className="transactions-table-container" />
            </div>
          </DesktopOngoingAndCompletedInvoiceCSS>
        )
      }}
    </SingleInvoiceContext.Consumer>
  )
}

export default withUser(DesktopOngoingAndCompletedInvoice);