
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import Loading from 'src/components/Loading';
import LoginForm from './components/LoginForm';
import { LoginCSS } from './styles';

const Login = () => {
  const [isLoading, toggleLoading] = useState(false);
  const { t } = useTranslation('login');
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <LoginCSS className="wrapper-padding page-padding-bottom">
        <Loading active={isLoading} />
        <Icon
          name='coffee'
          size='big'
        />
        <h3>{t('welcomeBack')}</h3>
        <LoginForm toggleLoading={toggleLoading} />
        <div id="links-container">
          <Link to="/register">
            <p className="text-hover text-hover-white">{t('getStarted')}</p>
          </Link>
        </div>
      </LoginCSS>
    </>
  )
}

export default Login;
