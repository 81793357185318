import styled from 'styled-components';

export const IsAdminCSS = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .is-admin-container {
    background: white;
    padding: 2rem;
    border-radius: 4px;
    text-align: center;
  }

  .link {
    margin: 5px 0;
  }
`;