export const sortInvoicesbyStatus = (invoice: any[]) => {
  const pending: any = [];
  const ongoing: any = [];
  const rejected: any = [];
  const others: any = [];


  for (let i = 0; i < invoice.length; i += 1) {
    if (invoice[i].status === 'ongoing') {
      ongoing.push(invoice[i]);
    } else if (invoice[i].status === 'pending') {
      pending.push(invoice[i]);
    } else if (invoice[i].status === 'rejected') {
      pending.push(invoice[i]);
    } else {
      others.push(invoice[i]);
    }
  }

  return [
    ...pending,
    ...ongoing,
    ...rejected,
    ...others,
  ];
}
