import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Loading from 'src/components/Loading';
import RegisterForm from './components/RegisterForm'
import { RegisterCSS } from './styles';

const Register = () => {
  const [isLoading, toggleLoading] = useState(false);
  const { t } = useTranslation('register');
  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <RegisterCSS className="wrapper-padding page-padding-bottom">
        <Loading active={isLoading} />
        <Icon
          name='user'
          size='big'
        />
        <h3 id="title">{t('register')}</h3>
        <RegisterForm toggleLoading={toggleLoading} />
        <Link to="/login">
          <p className="text-hover text-hover-white link">{t('returnToLogin')}</p>
        </Link>
      </RegisterCSS>
    </>
  )
}

export default Register;