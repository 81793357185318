import styled from 'styled-components';

export const MobileUpcomingCSS = styled.div`
  width: 100%;
  
  .text-container {
    padding: 1.5rem;
    background: var(--secondary);
    border-radius: 4px;
    color: white;

    hr {
      width: 65%;
      border-top: 1px solid white;
    }
  }

  h3 {
     font-weight: 200;
     margin-bottom: 0.7rem;
  }

  .jKEcXU {
    min-height: 0;
    margin: 2rem 0;
    
    i.icon {
      color: #ecf6aac7;
    }

    p {
      color: #ecf6aac7;
    }
  }
`;
