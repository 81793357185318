import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { withMerchant } from 'src/components/Firebase';
import { CreditLimitCSS } from './styles';
import { hasCredit } from '../../utils';

const CreditLimit = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { merchant, usedCredit } = props;

  return (
    <CreditLimitCSS className="wrapper-padding">
      <h2>{hasCredit(merchant.creditLimit) ? merchant.creditLimit : usedCredit}</h2>
      <p>{t(hasCredit(merchant.creditLimit) ? 'creditLimit' : 'usedCreditLimit')}</p>
    </CreditLimitCSS>
  );
};

export default R.compose<any, any>(withMerchant)(CreditLimit);
