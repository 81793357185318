import React from 'react';
import { useTranslation } from 'react-i18next';
import { Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { withUser } from 'src/components/Firebase';
import ViewDetailContainer from '../../../ViewDetailContainer';

const View = (props: any) => {
  const { toggleEdit, user } = props;
  const { t } = useTranslation('settings');
  return (
    <ViewDetailContainer>
      <div className="header">
        <h3>{user.name}</h3>
        <Icon name='edit' onClick={() => toggleEdit(true)} />
      </div>
      <div className="all-details-container">
        <div className="detail-container">
          <p className="label">{t('email')}:</p>
          <p className="detail">{user.email}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('phone')}:</p>
          <p className="detail">{user.phone}</p>
        </div>
        <hr />
        <Link to="/reset-password">
          <div id="reset-password">
            <p>{t('resetPassword')}</p>
          </div>
        </Link>
      </div>
    </ViewDetailContainer>
  )
}

export default withUser(View);