import styled from 'styled-components';
import { largeDesktopWidth } from 'src/config';

export const TransactionsCSS = styled.div`
  width: 100%;
  background: white;
  padding: 2.3rem 1.3rem;
  min-height: 400px;

  @media only screen and (min-width: ${largeDesktopWidth}px) {
    min-height: 600px;
  }
`;