import styled from 'styled-components';

export const ConnectCSS = styled.div`
  background: white;
  border-radius: 4px;
  padding: 2rem;

  h3 {
    color: var(--gray300);
  }
  
  .container {
    display: grid;
    grid-template-columns: repeat(3, minmax(100px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
  }

  .single-connect {
    border-radius: 5px;
    border: 2px dashed var(--gray200);
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;

    &:hover {
      cursor: pointer;
    }
  }

  #xero-image {
    width: 80%;
  }
`;
