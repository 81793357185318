
import * as R from 'ramda';
import { convertToMoney } from 'src/utils/convertToMoney';

export class Merchant {
  public companyName: string;
  public email: string;
  public phone: string;
  public website: string | null;
  public annualTurnover: number | string;
  public creditLimit: number | string;
  public establish: number | string;
  public id: string;
  // public owner: string;
  public createdAt: any | null;
  public updatedAt: any | null;

  constructor(payload: any) {
    this.companyName = payload.companyName;
    this.email = payload.email;
    this.phone = payload.phone;
    this.website = payload.website;
    this.annualTurnover = payload.annualTurnover;
    this.creditLimit = payload.creditLimit;
    this.establish = payload.establish;
    this.id = payload.id;
    this.createdAt = payload.createdAt;
    this.updatedAt = payload.updatedAt;
  }

  static formatData(data: any) {
    return new Merchant({
      companyName: R.pathOr('', ['companyName'], data),
      email: data.email,
      phone: data.phone,
      website: R.propOr('', 'website')(data),
      annualTurnover: data.annualTurnover,
      creditLimit: convertToMoney(R.pathOr(0, ['creditLimit'], data)),
      establish: data.establish,
      id: data.id,
      createdAt: data.createdAt ? data.createdAt.toDate() : 'N/A',
      updatedAt: data.updatedAt ? data.updatedAt.toDate() : 'N/A'
    });
  }
}