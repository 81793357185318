import styled from 'styled-components';
import { desktopWidth } from 'src/config';

export const ProfileCSS = styled.div`
  padding: 1.5rem 1.3rem 1rem 1.3rem;

  .title {
    text-align: center;
    color: var(--gray300);
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    display: flex;
    flex-direction: column;
    padding: 1.5rem 0 4rem 0;

    .title {
      display: none;
    }
  }
`;
