/* eslint-disable */
import React from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { withFirebase, withUser } from 'src/components/Firebase';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Form, Button } from 'semantic-ui-react';
import NumberInput from 'src/components/NumberInput';
import Upload from 'src/components/Upload';
import { FpsCSS } from './styles';

class Fps extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = {
      amount: null,
      uploadIsLoading: false,
      submitIsLoading: false,
      uploadedFiles: [],
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleFiles = this.handleFiles.bind(this);
  }

  componentDidMount() {
    const { remainingAmount } = this.props;
    this.setState({
      amount: remainingAmount,
    });
  }

  handleChange(e: null, { name, value }: any, merge = false, nested = false) {
    if (merge) {
      this.setState((prevState: any) => ({
        [name]: R.concat(prevState[name], value),
      }));
    }
    else if (nested) {
      const { obj, key } = name;
      this.setState((prevState: any) => ({
        [obj]: R.set(R.lensProp(key), value, prevState[obj]),
      }));
    } else {
      this.setState({ [name]: value });
    }
  }

  async handleSubmit() {
    try {
      this.setState({
        submitIsLoading: true,
      });
      const { firebase, match, setSuccess, user } = this.props;
      const { amount, uploadedFiles } = this.state;
      const invoiceId = R.pathOr('', ['params', 'id'], match);

      const uploading = uploadedFiles.map((file:any) => {
        return firebase.uploadInvoice(user.merchantId, invoiceId, file.name).put(file);
      })

      const reflect = (promise: any) => promise.then(
        async (data: any) => {

          const fileName = R.pathOr('', ['metadata', 'name'], data);
          const url = await firebase.uploadInvoice(user.merchantId, invoiceId,fileName).getDownloadURL()
          
          const formattedData = {
            fileName,
            url
          }

          return (
            { data, 
              status: "fulfilled", 
              formattedData
            }
          )
        },
        (data: any) => {
          const { t } = this.props;
          const fileName = R.pathOr('', ['data', 'metadata', 'name'], data);
          const message = `${fileName} ${t('requestForm:uploadFileError')}`;

          toast.error(message, {
            position: toast.POSITION.TOP_CENTER,
            autoClose: false,
            hideProgressBar: true,
            closeOnClick: true,
            draggable: false,
          });

          return (
            { data, status: "rejected" }
          )
        }
      )

      const results = await Promise.all(uploading.map(reflect));
      const files:any = [];
      
      results.filter((x:any) => x.status !== 'rejected').map((x:any) => {
        files.push(x.formattedData);
      })

      const transactionRef = firebase.invoiceTransactionsCollection(invoiceId);

      const data = {
        paymentType: 'fps',
        amount,
        files
      };

      await transactionRef.add(data);
      setSuccess(true);
    } catch (error) {
      this.setState({
        submitIsLoading: false,
      });
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  handleFiles(files:any[]){
    this.handleChange(null, { name: 'uploadedFiles', value: files }, true)
  }

  render() {
    const { t, match, handleClose } = this.props;
    const { amount, uploadedFiles, uploadIsLoading, submitIsLoading } = this.state;
    const invoiceId = R.pathOr('', ['params', 'id'], match);
    return (
      <FpsCSS>
        <div>
          <p>Bank of China (HK)</p>
          <p>{t('bankAccountName')}: Uprise Credit Limited</p>
          <p>{t('bankAccountNumber')}: 012-721-0-009017-8</p>
          <p>{t('fpsId')}: 944261</p>
        </div>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field required>
            <label htmlFor="upload">{t('uploadInvoiceProof')}</label>
            {!!uploadedFiles.length && (
              <p>{uploadedFiles.map((x: any) => x.name).join(', ')}</p>
            )}
            <Upload
              invoiceId={invoiceId}
              toggleLoading={this.handleChange}
              filesCallback={this.handleFiles}
              loadingLabel="uploadIsLoading"
            >
              <Button
                loading={uploadIsLoading}
                className="invert-primary-light-button"
                type="button"
              >
                {t('upload')}
              </Button>
            </Upload>
          </Form.Field>
          <Form.Field>
            <label htmlFor="amount">{t('amount')}</label>
            <NumberInput
              name="amount"
              value={amount}
              disabled
            />
          </Form.Field>
          <Form.Field>
            <div className="buttons">
              <Button type="submit" className="primary-dark" loading={submitIsLoading}>
                {t('submit')}
              </Button>
              <Button onClick={handleClose} type="button" className="invert-primary-dark-button">
                {t('cancel')}
              </Button>
            </div>
          </Form.Field>
        </Form>
      </FpsCSS>
    );
  }
}

export default R.compose<any, any, any, any, any>(
  withTranslation(['payment', 'requestForm']),
  withRouter,
  withFirebase,
  withUser
)(Fps);
