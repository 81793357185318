import React, {useState} from 'react';
import * as R from 'ramda';
import classnames from 'classnames';
import { useSpring } from 'react-spring';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import TermsAndConditions from 'src/components/TermsAndConditionsModal';
import Privacy from 'src/components/PrivacyModal';
import { menuItems, generalMenuItems } from '../../../../config';
import { NavItemsContainerCSS, NestedCSS } from './styles';

interface IProps {
  className: string;
  setShow: Function;
}

const NavItemsContainer = (props: IProps & any) => {
  const { className, setShow } = props;
  const { t } = useTranslation('nav');
  const [showSettings, setShowSettings] = useState(false);

  const handleOnClick = () => {
    setShow(false);
    setShowSettings(false);
  }
  
  const {maxHeight} = useSpring({
     maxHeight: showSettings ? 200 : 0,
  });

  const renderNavItems = (item:any, config='') => {
    const active = item.url === R.pathOr(false, ['location', 'pathname'], props) ? 'active' : '';

    if(item.children){
      return (
        <React.Fragment key={item.displayKey}>
          <li
            onClick={()=> setShowSettings(!showSettings)}
          >
            {t(item.displayKey)}
          </li>
          <NestedCSS style={{maxHeight}}>
            {item.children.map((child:any) => renderNavItems(child, 'nested'))}
          </NestedCSS>
        </React.Fragment>
      )
    }

    return (
      <Link key={item.displayKey} to={item.url}>
        <li
          className={classnames(active, config)}
          onClick={handleOnClick}
        >
          {t(item.displayKey)}
        </li>
      </Link>
    )
  }

  return (
    <NavItemsContainerCSS className={className}>
      <ul>
        {menuItems.map((x: any) => renderNavItems(x))}
        {generalMenuItems.map((x: any) => renderNavItems(x))}
      </ul>
      <div className="terms-container">
        <TermsAndConditions />
        <Privacy />
      </div>
    </NavItemsContainerCSS>
  )
}

export default NavItemsContainer;
