import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button } from 'semantic-ui-react';
import { ListActiveAndCompletedInvoiceContext } from 'src/context';
import DesktopListInvoices from 'src/components/DesktopListInvoices';
import NoData from 'src/components/NoData';
import { GET_PAID_REQUEST } from 'src/screens/routes';
import GetPaidTable from './components/GetPaidTable';

const DesktopGetPaid = () => {
  const { t } = useTranslation('invoice');
  return (
    <DesktopListInvoices>
      <div className="title-container">
        <h1>{t('getPaid')}</h1>
        <Link to={GET_PAID_REQUEST}>
          <Button className="invert-primary-dark-button">
            {t('newInvoice')}
          </Button>
        </Link>
      </div>
      <div className="invoice-table-container drop-shadow">
        <ListActiveAndCompletedInvoiceContext.Consumer>
          {(invoices: any) => {
            return (
              <>
                <GetPaidTable invoices={invoices} />
                {!invoices.length && <NoData />}
              </>
            )
          }}
        </ListActiveAndCompletedInvoiceContext.Consumer>
      </div>
    </DesktopListInvoices>
  )
}

export default DesktopGetPaid;