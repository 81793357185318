export default {
  title: 'Request',
  merchantDetails: 'Merchant Details',
  merchant: 'Merchant',
  selectMerchant: 'Select Merchant...',
  companyName: 'Company Name',
  addNew: 'Add New',
  email: 'Email',
  phone: 'Phone',
  requestDetails: 'Request Details',
  amount: 'Amount',
  purpose: 'Purpose',
  note: 'Note',
  deliveryTime: 'Delivery time (days)',
  submit: 'Submit',
  selectMerchantError: 'Please select or create a merchant',
  amountError: 'Amount must be an integer',
  deliveryTimeError: 'Delivery time must be an integer',
  purposeError: 'Please input a purpose',
  newMerchantEmailError: 'Please enter email',
  newMerchantNameError: 'Please enter company name',
  company: 'Company',
  requestedAmount: 'Requested Amount',
  confirm: 'Confirm',
  goBack: 'Go Back',
  type: 'Type',
  pay: 'Pay',
  "get-paid": 'Get Paid',
  uploadInvoiceProof: 'Upload Invoice',
  upload: 'Upload',
  uploadSuccess: 'File(s) Uploaded',
  uploadFileError: 'Please try again.',
  invoiceUploadError: 'No invoice uploaded',
  amountValueError: 'Requested amount must be greater than 0',
  deliveryTimeValueError: 'Requested amount must be greater than 0',
  noFilesFound: 'No files were found.',
  sizeLimitError: 'is greater than the size limit (10mb).',
  uploadAdditionalProof: 'Additional Information (optional)',
  payForBuyer: 'Repay by seller on term complete',
  additionalPermissions: 'Other',
  select: 'Select...',
  photo: 'Photo',
  xero: 'Xero',
  quickbooks: 'Quickbooks',
  invoice: 'Invoice',
  invoiceNumber: 'Invoice Number',
  invoiceUrl: 'Invoice Url',
  or: 'or',
  invoiceError: 'Please provide a invoice',
  connectInvoiceError: 'We were unable to fetch your invoice. Please try another method.',
  invalidUrl: 'Invoice URL is invalid.',
}
