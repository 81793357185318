export default {
  nextPaymentDate: '下次付款日期',
  amount: '金額',
  status: '狀態',
  company: '公司名稱',
  remainingAmount: '金額',
  approved: '已確認',
  pending: '處理中',
  rejected: '已拒絕',
  completed: '完成',
  ongoing: '進行中',
}
