import styled from 'styled-components';

export const ListDocumentsCSS = styled.div`
  h3 {
    text-align: center;
    color: var(--gray300);
  }

  .ui.button {
    width: 100%;

    &.primary-light {
      margin: 0 0 1rem;  
    }

    &.primary-dark {
      margin: 1rem 0 0;
      padding: 1.5rem 0;
    }

  }

  .small-font {
    text-align: center;
    color: var(--gray300);
  }
`;
