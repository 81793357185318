import React from 'react';
import Media from 'react-media';
import { tabletWidth } from 'src/config';
import MobileOngoingAndCompletedInvoice from './screens/MobileOngoingAndCompletedInvoice';
import DesktopOngoingAndCompletedInvoice from './screens/DesktopOngoingAndCompletedInvoice';

const OngoingInvoice = () => {
  return (
    <Media query={{ minWidth: tabletWidth }}>
      {matches =>
        matches ? (
          <DesktopOngoingAndCompletedInvoice />
        )
          : (
            <MobileOngoingAndCompletedInvoice />
          )}
    </Media>
  )
}

export default OngoingInvoice;