import styled from 'styled-components';

export const MobileNavCSS = styled.div`
  p {
    color: white;
    font-size: 1rem;
    font-weight: 600;
  }
  
  .nav {
    width: 100%;
    background: var(--primary);
    padding: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 50px;
    top: 0;
    z-index: 50;
  }

  .nav-placeholder {
    position: relative;
    width:100%;
    height: 50px;
  }

  .nav-icon {
    width: 30px;
    position: absolute;
    left: 1em;

    &:after,
    &:before,
    > div {
      background-color: var(--white);
      border-radius: 2px;
      content: '';
      display: block;
      height: 3px;
      margin: 4px 0;
      transition: all .4s ease-in-out;
    }

    &.active {
      &:before {
        transform: translateY(7px) rotate(137deg);
      }

      &:after {
        transform: translateY(-7px) rotate(-137deg);
      }

      > div { 
        transform: scale(0);
      }
    }
  }

  .nav-items-container-overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    background: rgba(0,51,51,0.7);
    transition: opacity 300ms;
    transition-timing-function: ease-in;
    opacity: 0;
    display: none;

    &.active {
      opacity: 0.7;
      transition: opacity 300ms;
      transition-timing-function: ease-out;
      display: block;
      z-index: 5;
    }
  }
`;