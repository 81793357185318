import styled from 'styled-components';

export const UploadCSS = styled.div`
  h3 {
    text-align: center;
  }
  
  .ui.button {
    width: 100%;
    margin: 1rem;
  }

  i.icon {
    color: var(--gray200);
  }

  .upload-button {
    width: 100%;
    margin: 1rem 0;
    background: #e6e6e6;
    padding: 4rem 0;
    border-radius: 5px;
    border: 2px dashed var(--gray200);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .small-font {
    text-align: center;
    color: var(--gray300);
  }
`;
