import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { Button } from 'semantic-ui-react';
import { withRouter } from 'react-router-dom';
import { SingleInvoiceOfferContext } from 'src/context'
import { withFirebase } from 'src/components/Firebase';
import Loading from 'src/components/Loading';
import SuccessConfirmation from '../../components/SuccessConfirmation';
import Detail from './component/Detail';
import { OfferCSS } from './styles';


const Offer = (props: any) => {
  const { t } = useTranslation('invoice');
  const [showConfirm, toggleConfirm] = useState(false);
  const [answer, updateAnswer] = useState(null);
  const [showLoading, toggleLoading] = useState(false);
  const [showConfirmationSuccess, toggleConfirmationSuccess] = useState(false);
  const { firebase } = props;
  const singleInvoiceId = R.pathOr(null, ['match', 'params', 'id'], props);

  const handleClick = async (submitAnswer: any) => {
    updateAnswer(submitAnswer);
    toggleConfirm(true);
  }

  const handleConfirm = async (formattedData: any) => {
    try {
      toggleLoading(true);
      const { id } = formattedData;
      const offerDocRef = firebase.invoiceOfferDoc(singleInvoiceId, id);
      await offerDocRef.set(
        {
          accepted: answer,
        }, {
        merge: true
      }
      )
      toggleLoading(false);
      toggleConfirmationSuccess(true);
    } catch (error) {
      toggleLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  if (showConfirmationSuccess) {
    return <SuccessConfirmation />
  }

  const showAnswer = answer ? t('accept') : t('decline');

  return (
    <SingleInvoiceOfferContext.Consumer>
      {(formattedData: any) => {
        return (
          <>
            <Loading active={showLoading} />
            <OfferCSS>
              <h3>{t('congratulations')}</h3>
              <p>{t('review')}</p>
              <div>
                <Detail label='type' detail={t(formattedData.type)} />
                <Detail label='requestedAmount' detail={formattedData.requestedAmount} />
                <Detail label='offeredAmount' detail={formattedData.offeredAmount} />
                {!!showConfirm && <Detail label='confirm' detail={showAnswer} />}
              </div>
              <div className="button-container">
                {showConfirm ? (
                  <>
                    <Button
                      className="primary-light"
                      onClick={() => handleConfirm(formattedData)}
                    >
                      {t('confirm')}
                    </Button>
                    <Button
                      className="invert-primary-light-button"
                      onClick={() => toggleConfirm(false)}
                    >
                      {t('goBack')}
                    </Button>
                  </>
                )
                  : (
                    <>
                      <Button
                        className="primary-light"
                        onClick={() => handleClick(true)}
                      >
                        {t('accept')}
                      </Button>
                      <Button
                        onClick={() => handleClick(false)}
                      >
                        {t('decline')}
                      </Button>
                    </>
                  )}
              </div>
            </OfferCSS>
          </>
        )
      }}
    </SingleInvoiceOfferContext.Consumer>
  )
}

export default R.compose<any, any, any>(
  withFirebase,
  withRouter
)(Offer);