import styled from 'styled-components';

export const DetailsCSS = styled.div`
  h2 {
    width: 100%;
    text-align: center;
    color: var(--primaryDark);
    padding: 1rem;
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    font-weight: 400;
    margin-bottom: 0;
  }

  #invoice-item-container {
    width: 100%;
    padding: 1.5rem 1rem;
    background: var(--secondary);
    margin: 0.5rem 0;
    border-radius: 6px;
  }
`;