import React from 'react';
import { useTranslation } from 'react-i18next';
import DesktopListInvoices from 'src/components/DesktopListInvoices';
import { ListInvoiceContext } from 'src/context';
import NoData from 'src/components/NoData';
import InvoicesTable from './components/InvoicesTable';

const DesktopInvoices = () => {
  const { t } = useTranslation('invoice');
  return (
    <DesktopListInvoices>
      <div className="title-container">
        <h1>{t('invoices')}</h1>
      </div>
      <div className="invoice-table-container drop-shadow">
        <ListInvoiceContext.Consumer>
          {(invoices: any) => {
            return (
              <>
                <InvoicesTable invoices={invoices} />
                {!invoices.length && <NoData />}
              </>
            )
          }}
        </ListInvoiceContext.Consumer>
      </div>
    </DesktopListInvoices>
  )
}

export default DesktopInvoices;