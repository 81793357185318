import React from 'react';
import Media from 'react-media';
import { tabletWidth } from 'src/config';
import DesktopCreditLimit from './screens/DesktopCreditLimit';
import MobileCreditLimit from './screens/MobileCreditLimit';

const CreditLimit = (props: any) => {
  return (
    <Media query={{ minWidth: tabletWidth }}>
      {matches =>
        matches ? (
          <DesktopCreditLimit {...props} />
        )
          : (
            <MobileCreditLimit {...props} />
          )}
    </Media>
  )
}

export default CreditLimit;