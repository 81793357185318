import React from 'react';
import { DetailCSS } from './styles';

const Detail = (props: any) => {
  const { label, detail } = props;
  return (
    <DetailCSS>
      <p className="label">{label}: </p>
      <p className="detail">{detail}</p>
    </DetailCSS>
  )
}

export default Detail;