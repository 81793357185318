
import React, { useState } from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { withFirebase, withUser } from 'src/components/Firebase';
import { useCollectionHook } from 'src/components/Firebase/utils';
import Loading from 'src/components/Loading';
import Form from './components/Form'
import SuccessRequest from './components/SuccessRequest';

const RequestForm = (props: any) => {
  const { t } = useTranslation('requestForm');
  const [showSuccess, toggleShowSuccess] = useState(false);
  const [formLoading, toggleFormLoading] = useState(false);
  const { user, firebase } = props;
  const { isLoading, data } = useCollectionHook(firebase.merchantExistingMerchantsCollection(user.merchantId));

  if (isLoading) {
    return <Loading active={isLoading} />
  }

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      <div className="gray-background wrapper-padding page-padding-bottom">
        <Loading active={formLoading} />
        {showSuccess
          ? <SuccessRequest {...props} toggleShowSuccess={toggleShowSuccess} />
          : (
            <Form
              {...props}
              toggleFormLoading={toggleFormLoading}
              toggleShowSuccess={toggleShowSuccess}
              pastMerchants={data}
            />
          )
        }
      </div>
    </>
  )
}

export default R.compose<any, any, any>(
  withUser,
  withFirebase
)(RequestForm);