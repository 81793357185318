
import * as R from 'ramda';
import * as ROUTES from 'src/screens/routes';

export const generalMenuItems = [
  {
    displayKey: 'settings',
    children: [
      {
        displayKey: 'profile',
        url: ROUTES.SETTINGS,
      },
      {
        displayKey: 'documents',
        url: ROUTES.MERCHANT_DOCUMENTS
      },
      {
        displayKey: 'connect',
        url: ROUTES.CONNECT
      }
    ]
  },
  {
    displayKey: 'logout',
    url: ROUTES.LOGOUT,
  },
]

export const menuItems = [
  {
    displayKey: 'dashboard',
    url: ROUTES.HOME,
    icon: 'home'
  },
  {
    displayKey: 'pay',
    url: ROUTES.PAY,
    icon: 'money bill alternate outline'
  },
  {
    displayKey: 'getPaid',
    url: ROUTES.GET_PAID,
    icon: 'handshake outline'
  },
  {
    displayKey: 'invoices',
    url: ROUTES.INVOICES,
    icon: 'folder outline'
  },
  {
    displayKey: 'contact',
    url: ROUTES.CONTACT,
    icon: 'talk'
  },
]

export const getDisplay = (path: string) => {
  return R.compose(
    R.pathOr('', ['displayKey']),
    R.find(R.propEq('url', path))
  )(menuItems);
}

