import styled from 'styled-components';
import { desktopWidth } from 'src/config';

export const FormCSS = styled.div`
  .ui.button {
    width: 100%;
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    .ui.form textarea {
      min-height: 200px;
    }
  }
`;