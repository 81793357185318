import React from 'react';
import { ViewDetailContainerCSS } from './styles';

const ViewDetailContainer = (props: any) => {
  const { children } = props;

  return (
    <ViewDetailContainerCSS className="drop-shadow">
      {children}
    </ViewDetailContainerCSS>
  )
}

export default ViewDetailContainer;