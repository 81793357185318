export default {
  title: '賬單',
  payTitle: '付款',
  getPaidTitle: '收款',
  referenceNumber: '參考編號',
  date: '日期',
  requestedAmount: '發票金額',
  remainingAmount: '餘下金額',
  status: '狀態',
  role: 'Role',
  terms: '我們同意條款及細則',
  errorMessage: '請剔選同意條款及細則',
  confirm: '確認',
  transactions: '交易',
  pay: '付款',
  approved: '已確認',
  pending: '處理中',
  rejected: '已拒絕',
  nextPayment: '下次付款日期',
  getPaid: '收款',
  createdAt: '建立日期',
  invoices: '賬單',
  email: '電郵地址',
  phone: '電話號碼',
  amount: '金額',
  type: '類別',
  company: '公司名稱',
  newInvoice: '新賬單',
  agreeToTermsAndConditions: '我同意',
  congratulations: '審批申請成功',
  review: '審批已完成，詳細的批核內容如下',
  offeredAmount: '批核金額',
  accept: '接受',
  decline: '拒絕',
  goBack: '返回',
  completed: '完成',
  ongoing: '進行中',
  payForBuyer: '賣方到期支付',
  additionalPermissions: '其他',
  dueDate: '到期日',
  purpose: '賬項內容',
};


