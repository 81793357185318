import styled from 'styled-components';

export const DetailCSS = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.3rem 0;

  .label {
    margin: 0;
  }
`;
