import styled from 'styled-components';

export const PaymentFormcSS = styled.div`
  display: flex;
  flex-direction: column;

  label {
    display: block;
    margin: 0 0 .28571429rem 0;
    margin-bottom: 0.5rem;
    color: var(--gray200);
    font-size: .92857143em;
    font-weight: 700;
    text-transform: none;
  }

  .payment-form-container {
    padding-top: 1.5rem;
    max-height: 0;
    height: auto;
    overflow: hidden;
    transition: all 550ms ease-in-out;

    &.stripe, &.fps {
      max-height: 800px;
      height: auto;
      transition: max-height 1700ms ease-in-out;
    }
  }

  .dropdown {
    .option-item {
      display: flex;
      justify-content: space-between;

      .amount {
        display: none;
        color: #AAA;
        font-size: 90%;
      }
    }

    .menu .amount {
      display: block;
    }
  }
`;
