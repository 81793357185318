import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { withUser } from 'src/components/Firebase';
import { Icon } from 'semantic-ui-react';
import LeftBorderItem from 'src/components/LeftBorderItem';
import { SingleInvoice } from 'src/models/SingleInvoice';

const Item = (props: any) => {
  const { t } = useTranslation('invoice');
  const { data, user } = props;
  const { status = '' } = data;
  const formattedData: any = SingleInvoice.formatData(data);
  const { requestTo, initiatedBy } = formattedData;

  const company =
    user.merchantId === initiatedBy.merchantId ? requestTo.companyName : initiatedBy.companyName;
  const type = user.merchantId === formattedData.sellerId ? t('getPaid') : t('pay');
  const buyerRoleAndConfirmCheck =
    formattedData.buyerId === user.merchantId && !formattedData.buyerConfirmed;
  const sellerRoleAndConfirmCheck =
    formattedData.sellerId === user.merchantId && !formattedData.sellerConfirmed;
  const actionRequired =
    buyerRoleAndConfirmCheck ||
    sellerRoleAndConfirmCheck ||
    (formattedData.offerCreated && formattedData.status === 'pending');

  return (
    <Link to={`/invoices/${formattedData.id}`}>
      <LeftBorderItem className={`${status.toLowerCase()} drop-shadow`}>
        <h3>
          {company}
          {actionRequired && <Icon name="exclamation circle" />}
        </h3>
        <div className="detail-container">
          <p className="label">{t('referenceNumber')}:</p>
          <p className="detail">{formattedData.referenceNumber}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('date')}:</p>
          <p className="detail">{formattedData.createdAt}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('requestedAmount')}:</p>
          <p className="detail">{formattedData.requestedAmount}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('remainingAmount')}:</p>
          <p className="detail">{formattedData.remainingAmount}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('type')}:</p>
          <p className="detail type">{type}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('status')}:</p>
          <p className="detail status">{t(formattedData.status)}</p>
        </div>
      </LeftBorderItem>
    </Link>
  );
};

export default withUser(Item);
