import styled from 'styled-components';
import { largeDesktopWidth } from 'src/config';

export const DesktopDashboardCSS = styled.div`
  h1 {
    margin-bottom: 1rem;
    color: var(--gray200);
  }

  .dashboard-container{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    grid-gap: 30px;
    align-items: center;
  }

  .table-container {
    grid-column: 1 / 2;
    display: grid;
    grid-template-columns: 100%;
    grid-gap: 45px;
    min-height: 300px;
  }

  .credit-limit-and-do-container {
    grid-column: 1 / 2;
    grid-row: 1;
    display: grid;
    grid-template-columns: repeat(2, minmax(100px, 1fr));
    grid-template-rows: auto;
    grid-gap: 20px;
  }


  @media only screen and (min-width: ${largeDesktopWidth}px) {
    .dashboard-container {
      grid-template-columns: repeat(2, minmax(100px, auto)) minmax(100px, 400px);
      grid-template-rows: repeat(2, minmax(100px, auto));
      grid-gap: 30px;
    }

    .table-container {
      grid-column: 1/ span 2;
      grid-row: 1/ span 2;
      min-height: 450px;
      align-self: flex-start;
    }

    .credit-limit-and-do-container {
      grid-column: 3/ span 1;
      grid-template-columns: 100%;
      grid-gap: 35px;
    }

  }
`;