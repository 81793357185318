import React from 'react';
import * as R from 'ramda';
import axios from 'axios';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { Form as SemanticForm, Select, Input, Button, Checkbox } from 'semantic-ui-react';
import { withFirebase, withUser, withMerchant } from 'src/components/Firebase';
import { isURL } from 'src/utils/isURL';
import NumberInput from 'src/components/NumberInput';
import { 
  formatExtraPermissions,
} from 'src/config';
import Upload from 'src/components/Upload';
import { 
  fetchOptions, 
  initialState, 
  formatSubmission,
  invoiceOptions, 
  formatConnects, 
} from './config';
import NewMerchantDetail from './components/NewMerchantDetail';
import Invoice from './components/InvoiceProof';
import ConfirmForm from './components/ConfirmForm';
import { FormCSS } from './styles';

class Form extends React.Component<any, any> {
  constructor(props: any) {
    super(props);

    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
    this.toggleConfirmation = this.toggleConfirmation.bind(this);
    this.filesCallback = this.filesCallback.bind(this);
    this.uploadToStorage = this.uploadToStorage.bind(this);
  }

  async componentDidMount() {
    const { firebase, path, user } = this.props;
    const invoiceId = firebase.invoiceCollection().doc().id;
    // eslint-disable-next-line
    let extraPermissionsRef;
    if (path === 'get-paid') {
      extraPermissionsRef = firebase.merchantGetPaidPermissionsDoc(user.merchantId);
    } else {
      extraPermissionsRef = firebase.merchantPayPermissionsDoc(user.merchantId);
    }

    const connectsRef = firebase.merchantTokensCollection(user.merchantId);

    let extraPermissionsdata = {};
    await extraPermissionsRef.get().then((doc:any)=>{
      if(doc.exists){
        extraPermissionsdata = doc.data();
      }
    })
    const listPermissions = formatExtraPermissions(extraPermissionsdata);

    let connectsData:any = [];
    await connectsRef.get().then((snapshot:any)=>{
      snapshot.forEach((x:any)=> {
        connectsData.push(x.data());
      });
    });

    connectsData = formatConnects(connectsData);
    

    this.setState({
      invoiceId,
      listPermissions,
      extraPermissions: extraPermissionsdata,
      connects: connectsData,
    });
  }

  handleChange(e: null, { name, value }: any, merge = false, nested = false) {
    if (merge) {
      this.setState((prevState: any) => ({
        [name]: R.concat(prevState[name], value),
      }));
    } else if (nested) {
      const { obj, key } = name;
      this.setState((prevState: any) => ({
        [obj]: R.set(R.lensProp(key), value, prevState[obj]),
      }));
    } else {
      this.setState({ [name]: value });
    }
  }

  toggleConfirmation(value: boolean) {
    this.setState({
      showConfirmation: value,
    });
  }

  filesCallback(name:string, files:any[]) {
    this.handleChange(null, { name, value: files }, true)
  }

  async uploadToStorage(files:any=[]){
    const {
      merchant,
      firebase,
      t,
    } = this.props;
    const {
      invoiceId
    } = this.state;

    if(!files.length) {
      return [];
    } 

    const uploadFiles = files.map((x: any) => firebase.uploadInvoice(merchant.id, invoiceId, x.name).put(x));

    const reflect = (promise: any) => promise.then(
      (data: any) => ({ data, status: "fulfilled" }),
      (data: any) => ({ data, status: "rejected" })
    )
    const results = await Promise.all(uploadFiles.map(reflect));

    const formatUrls: object[] = [];

    const getUrlPromises = results.filter((x: any) => {
      if (x.status === 'fulfilled') {
        return true;
      }
      const fileName = R.pathOr('', ['data', 'metadata', 'name'], x);
      const message = `${fileName} ${t('uploadFileError')}`;
      toast.error(message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
      return false
    }).map((y: any) => {
      const fileName = R.pathOr('', ['data', 'metadata', 'name'], y);
      formatUrls.push({
        fileName
      })
      return firebase.uploadInvoice(merchant.id, invoiceId, fileName).getDownloadURL()
    })

    const urls = await Promise.all(getUrlPromises.map(reflect));

    formatUrls.forEach((x: any, i: any) => {
      // eslint-disable-next-line
      x.url = urls[i].data
    })

    return formatUrls;
  }

  async validateForm() {
    const { t, user } = this.props;
    const {
      merchantIndex,
      amount,
      deliveryTime,
      purpose,
      merchantName,
      merchantEmail,
      requiredFiles,
      connectInvoiceNumber,
      connectInvoiceUrl,
      invoiceOption
    } = this.state;

    try {
      if (merchantIndex === 'new' && !merchantName.length) {
        throw new Error(t('newMerchantNameError'));
      }

      if (merchantIndex === 'new' && !merchantEmail.length) {
        throw new Error(t('newMerchantEmailError'));
      }

      if (merchantIndex === null) {
        throw new Error(t('selectMerchantError'));
      }

      if (typeof amount !== 'number' && !connectInvoiceNumber.length) {
        throw new Error(t('amountError'));
      }

      if (amount <= 0 && !connectInvoiceNumber.length) {
        throw new Error(t('amountValueError'));
      }

      if (!purpose.length) {
        throw new Error(t('purposeError'));
      }

      if (typeof deliveryTime !== 'number') {
        throw new Error(t('deliveryTimeError'));
      }

      if (deliveryTime <= 0) {
        throw new Error(t('deliveryTimeValueError'));
      }

      if (!requiredFiles.length && !connectInvoiceNumber.length && !connectInvoiceUrl.length) {
        throw new Error(t('invoiceError'));
      }

      if(connectInvoiceUrl.length && !isURL(connectInvoiceUrl)) {
        throw new Error(t('invalidUrl'));
      }

      if(connectInvoiceNumber.length) {
        const payload = {
          merchantId: user.merchantId,
          connectInvoiceNumber,
          type: invoiceOption,
        }

        const {data, status} = await axios.post(`${process.env.REACT_APP_API}/invoice-check`, payload).catch(() => {
          throw new Error(t('connectInvoiceError'));
        });

        if(status !== 200){
          throw new Error(t('connectInvoiceError'));
        }

        if(data.amount <= 0) {
          throw new Error(t('amountValueError'));
        }

        this.setState({
          amount: data.amount
        })
      }


      this.toggleConfirmation(true);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
    return true;
  }

  async handleSubmit() {
    const {
      toggleShowSuccess,
      toggleFormLoading,
      path,
      user,
      merchant,
      pastMerchants,
      firebase,
    } = this.props;

    toggleFormLoading(true);

    const { 
      merchantIndex, 
      invoiceId, 
      requiredFiles,
      additionalFiles 
    } = this.state;

    try {
      const data = formatSubmission(this.state, pastMerchants, user, merchant, path);
      const allPromises = [];
      if (merchantIndex === 'new') {
        allPromises.push(
          firebase.merchantExistingMerchantsCollection(user.merchantId).add(data.requestTo)
        );
      }

      const [requiredFilesUrl, additionalFilesUrl] = await Promise.all([
        this.uploadToStorage(requiredFiles),
        this.uploadToStorage(additionalFiles)
      ])

      requiredFilesUrl.forEach((file: any) => {
        allPromises.push(
          firebase.invoiceDocumentsRequiredDoc(invoiceId).add({
            type: 'invoices',
            ...file,
          }),
        );
      })


      if (additionalFilesUrl.length) {
        additionalFilesUrl.forEach((file: any) => {
          allPromises.push(
            firebase.invoiceDocumentsAdditionalDoc(invoiceId).add({
              type: 'additional',
              ...file,
            }),
          );
        })
      }

      allPromises.push(firebase.invoiceDoc(invoiceId).set(data));

      await Promise.all(allPromises);

      toast.dismiss();
      toggleShowSuccess(true);
      toggleFormLoading(false);
    } catch (error) {
      toggleFormLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  render() {
    const { t, pastMerchants } = this.props;
    const {
      amount,
      merchantIndex,
      showConfirmation,
      purpose,
      deliveryTime,
      merchantName,
      merchantEmail,
      merchantPhone,
      requiredFiles,
      additionalFiles,
      extraPermissions,
      listPermissions,
      invoiceOption,
      connectInvoiceNumber,
      connectInvoiceUrl,
      connects
    } = this.state;

    const merchantOptions = fetchOptions(pastMerchants, t);

    const invoiceProofOptions = invoiceOptions(t);

    if (showConfirmation) {
      return (
        <ConfirmForm
          toggleConfirmation={this.toggleConfirmation}
          handleSubmit={this.handleSubmit}
          state={this.state}
          {...this.props}
        />
      );
    }

    return (
      <FormCSS>
        <SemanticForm onSubmit={this.validateForm}>
          <h3>{t('merchantDetails')}</h3>
          <SemanticForm.Field
            required
            value={merchantIndex}
            name="merchantIndex"
            control={Select}
            label={t('merchant')}
            options={merchantOptions}
            placeholder={t('selectMerchant')}
            onChange={this.handleChange}
          />
          <NewMerchantDetail
            className={classNames({ active: merchantIndex === 'new' })}
            handleChange={this.handleChange}
            merchantName={merchantName}
            merchantEmail={merchantEmail}
            merchantPhone={merchantPhone}
          />
          <h3>{t('requestDetails')}</h3>
          <SemanticForm.Field
            required
            value={invoiceOption}
            name="invoiceOption"
            control={Select}
            label={t('invoice')}
            options={invoiceProofOptions}
            placeholder={t('select')}
            onChange={this.handleChange}
          />
          <Invoice 
            invoiceOption={invoiceOption}
            requiredFiles={requiredFiles}
            filesCallback={this.filesCallback}
            connectInvoiceNumber={connectInvoiceNumber}
            connectInvoiceUrl={connectInvoiceUrl}
            handleChange={this.handleChange}
            connects={connects}
          />
          {
            !connectInvoiceNumber && (
              <SemanticForm.Field required>
                <label htmlFor="amount">{t('amount')}</label>
                <NumberInput
                  name="amount"
                  onChange={(value: any) => this.handleChange(null, { name: 'amount', value })}
                  value={amount}
                />
              </SemanticForm.Field>
            )
          }
          <SemanticForm.Field
            required
            value={purpose}
            control={Input}
            name="purpose"
            label={t('purpose')}
            onChange={this.handleChange}
          />
          <SemanticForm.Field required>
            <label htmlFor="deliveryTime">{t('deliveryTime')}</label>
            <NumberInput
              name="deliveryTime"
              onChange={(value: any) => this.handleChange(null, { name: 'deliveryTime', value })}
              value={deliveryTime}
            />
          </SemanticForm.Field>
          <SemanticForm.Field>
            <label htmlFor="uploadAdditional">{t('uploadAdditionalProof')}</label>
            {!!additionalFiles.length && (
              <p>{additionalFiles.map((x: any) => x.name).join(', ')}</p>
            )}
            <Upload
              filesCallback={this.filesCallback}
              name='additionalFiles'
            >
              <Button
                className="invert-primary-dark-button"
                type="button"
              >
                {t('upload')}
              </Button>
            </Upload>
          </SemanticForm.Field>
          <SemanticForm.Field>
            {!!listPermissions.length && <label>{t('additionalPermissions')}</label>}
            {listPermissions.map((x: any) => {
              return (
                <Checkbox
                  key={x.permission}
                  label={t(x.permission)}
                  name={x.permission}
                  onChange={(e: any, { name, checked }: any) =>
                    this.handleChange(
                      null,
                      {
                        name: {
                          obj: 'extraPermissions',
                          key: name,
                        },
                        value: checked,
                      },
                      false,
                      true
                    )
                  }
                  checked={extraPermissions[x.permission]}
                />
              );
            })}
          </SemanticForm.Field>
          <SemanticForm.Field>
            <Button type="submit" className="primary-dark">
              {t('submit')}
            </Button>
          </SemanticForm.Field>
        </SemanticForm>
      </FormCSS>
    );
  }
}


export default R.compose<any, any, any, any, any>(
  withTranslation('requestForm'),
  withUser,
  withMerchant,
  withFirebase
)(Form);
