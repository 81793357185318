
import React from 'react';
import { SingleInvoiceTransactionsContext } from 'src/context';
import NoData from 'src/components/NoData';
import Item from './components/Item';

const ListTransactions = () => {
  return (
    <SingleInvoiceTransactionsContext.Consumer>
      {transactions => {
        if (!transactions.length) {
          return (
            <NoData />
          )
        }

        return (
          <div className='top-bottom-padding'>
            {transactions.map((x: any) => (
              <Item key={x.id} data={x} />
            ))}
          </div>
        )
      }}
    </SingleInvoiceTransactionsContext.Consumer>
  )
}

export default ListTransactions