import moment from 'moment';
import * as R from 'ramda';
import { convertToMoney } from 'src/utils/convertToMoney';

class SingleInvoiceInitiatedBy {
  public companyName: string;
  public merchantId: string | null;
  public phone: string | null;
  public email: string | null;
  public permissions: object | null;

  constructor(payload: any) {
    this.companyName = payload.companyName;
    this.merchantId = payload.merchantId;
    this.email = payload.email;
    this.phone = payload.email;
    this.permissions = payload.permissions;
  }

  static formatData(data: any) {
    return new SingleInvoiceInitiatedBy({
      companyName: R.pathOr('', ['companyName'], data),
      merchantId: R.pathOr(null, ['merchantId'], data),
      email: R.pathOr(null, ['email'], data),
      phone: R.pathOr(null, ['phone'], data),
      permissions: R.pathOr({}, ['permissions'], data)
    });
  }
}



class SingleInvoiceRequestTo {
  public companyName: string;
  public email: string;
  public phone: string;

  constructor(payload: any) {
    this.companyName = payload.companyName;
    this.email = payload.email;
    this.phone = payload.phone;
  }

  static formatData(data: any) {
    return new SingleInvoiceRequestTo({
      companyName: data.companyName,
      email: data.email,
      phone: data.phone,
    });
  }
}

export class SingleInvoice {
  public buyerConfirmed: boolean;
  public buyerId: string | null;
  public id: string;
  public requestTo: object;
  public initiatedBy: object;
  public note: string;
  public purpose: string;
  public nextDueDate: string | null;
  public referenceNumber: string;
  public remainingAmount: number;
  public requestedAmount: number | null;
  public remainingAmountNoFormat: number;
  public sellerConfirmed: boolean;
  public sellerId: string | null;
  public status: "pending" | "ongoing" | "completed" | "rejected";
  public createdAt: any | null;
  public updatedAt: any | null;
  public offerCreated: boolean;

  constructor(payload: any) {
    this.buyerConfirmed = payload.buyerConfirmed;
    this.buyerId = payload.buyerId;
    this.id = payload.id;
    this.requestTo = payload.requestTo;
    this.initiatedBy = payload.initiatedBy;
    this.note = payload.note;
    this.purpose = payload.purpose;
    this.referenceNumber = payload.referenceNumber;
    this.remainingAmount = payload.remainingAmount;
    this.requestedAmount = payload.requestedAmount;
    this.sellerConfirmed = payload.sellerConfirmed;
    this.remainingAmountNoFormat = payload.remainingAmountNoFormat;
    this.sellerId = payload.sellerId;
    this.status = payload.status;
    this.createdAt = payload.createdAt;
    this.updatedAt = payload.updatedAt;
    this.nextDueDate = payload.nextDueDate;
    this.offerCreated = payload.offerCreated;
  }


  static formatData(data: any) {
    return new SingleInvoice({
      buyerConfirmed: data.buyerConfirmed,
      buyerId: data.buyerId,
      id: data.id,
      requestTo: SingleInvoiceRequestTo.formatData(R.pathOr({}, ['requestTo'], data)),
      initiatedBy: SingleInvoiceInitiatedBy.formatData(R.pathOr({}, ['initiatedBy'], data)),
      note: data.note,
      purpose: data.purpose,
      referenceNumber: data.referenceNumber,
      remainingAmount: data.remainingAmount >= 0 ? convertToMoney(data.remainingAmount) : null,
      requestedAmount: convertToMoney(data.requestedAmount),
      remainingAmountNoFormat: data.remainingAmount,
      sellerConfirmed: data.sellerConfirmed,
      sellerId: data.sellerId,
      status: data.status,
      createdAt: data.createdAt ? moment(data.createdAt.toDate()).format('YYYY-MM-DD') : 'N/A',
      updatedAt: data.updatedAt ? moment(data.updatedAt.toDate()).format('YYYY-MM-DD') : 'N/A',
      nextDueDate: data.nextDueDate ? moment(data.nextDueDate.toDate()).format('YYYY-MM-DD') : 'N/A',
      offerCreated: data.offerCreated,
    });
  }
}

export class SingleInvoiceTransactions {
  public amount: number;
  public status: 'pending' | 'approved' | 'rejected';
  public date: string;
  public id: string;

  constructor(payload: any) {
    this.id = payload.id;
    this.amount = payload.amount;
    this.status = payload.status;
    this.date = payload.date;
  }

  static formatData(data: any) {

    const amount = convertToMoney(R.pathOr(0, ['amount'], data));
    const status = R.pathOr('pending', ['status'], data);


    return new SingleInvoiceTransactions({
      id: data.id,
      amount,
      status,
      date: data.createdAt ? moment(data.createdAt.toDate()).format('YYYY-MM-DD') : 'N/A'
    });
  }
}

export class SingleInvoiceOffer {
  public accepted: boolean;
  public createdAt: string;
  public updatedAt: string;
  public offeredAmount: number;
  public requestedAmount: number;
  public type: string;
  public id: string;

  constructor(payload: any) {
    this.id = payload.id;
    this.type = payload.type;
    this.requestedAmount = payload.requestedAmount;
    this.offeredAmount = payload.offeredAmount;
    this.createdAt = payload.createdAt;
    this.accepted = payload.accepted;
    this.updatedAt = payload.updatedAt;
  }

  static formatData(data: any) {
    return new SingleInvoiceOffer({
      id: data.id,
      type: data.type,
      requestedAmount: convertToMoney(data.requestedAmount),
      offeredAmount: convertToMoney(data.offeredAmount),
      accepted: data.accepted,
      createdAt: data.createdAt ? moment(data.createdAt.toDate()).format('YYYY-MM-DD') : 'N/A',
      updatedAt: data.updatedAt ? moment(data.updatedAt.toDate()).format('YYYY-MM-DD') : 'N/A',
    });
  }
}