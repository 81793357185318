import React from 'react';
import Media from 'react-media';
import { desktopWidth } from 'src/config';
import MobileNav from './components/MobileNav';
import DesktopNav from './components/DesktopNav';


const Nav = () => {
  return (
    <Media query={{ minWidth: desktopWidth }}>
      {
        matches =>
          matches ? (
            <DesktopNav />
          )
            : (
              <MobileNav />
            )
      }
    </Media>
  )
}

export default Nav;