
import React, { useState } from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { Modal, Button, Icon } from 'semantic-ui-react'
import { SETTINGS } from 'src/screens/routes';
import { useTranslation } from 'react-i18next';
import { withUser, withFirebase } from 'src/components/Firebase';

const GetStarted = (props: any) => {
  const { user } = props;
  const [isOpen, setOpen] = useState(true);
  const { t } = useTranslation('dashboard');

  const handleClose = () => {
    try {
      const { firebase } = props;

      firebase.userDoc(user.id).set({
        getStartedDisplay: false
      }, { merge: true });

      setOpen(false);
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  return (
    <Modal
      dimmer='blurring'
      open={isOpen}
      onClose={handleClose}
    >
      <Modal.Header>{t('welcome')} {user.name}!</Modal.Header>
      <Modal.Description>
        <p className="test">
          {t('continueOne')}
          <Link to={SETTINGS}>
            <span role="button" onClick={handleClose}>&nbsp;{t('settings')}&nbsp;</span>
          </Link>
          {t('continueTwo')}
        </p>
      </Modal.Description>
      <Modal.Actions>
        <Button className="primary-dark" onClick={handleClose}>
          <Icon name='checkmark' />
          {t('gotIt')}
        </Button>
      </Modal.Actions>
    </Modal>
  )
}

export default R.compose<any, any, any>(
  withUser,
  withFirebase
)(GetStarted);