import styled from 'styled-components';
import { desktopWidth } from 'src/config';

export const ResetPasswordFormCSS = styled.div`
  background: white;
  width: 100%;
  padding: 1rem;
  max-width: 350px;
  margin: auto;

  h2 {
    text-align: center;
  }

  .ui.button {
    width: 100%;
  }

  .ui.form .field .ui.input input, .ui.form .fields .field .ui.input input, .ui.form input:not([type]) {
    border: none;
    border-bottom: 1px solid var(--gray200);
    border-radius: 0;
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    max-width: none;
    padding: 2rem;
  }

`;
