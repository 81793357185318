export const desktopWidth = 1024;
export const largeDesktopWidth = 1224;
export const tabletWidth = 650;

export const formatExtraPermissions = (data: any) => {
  const result = [];
  // eslint-disable-next-line
  for (const key in data) {
    if (key !== 'updatedAt' && key !== ' createdAt') {
      result.push({
        permission: key,
        value: data[key]
      })
    }
  }
  return result;
}
