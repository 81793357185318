import React from 'react';
import * as R from 'ramda';
import { Helmet } from 'react-helmet';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { withFirebase } from 'src/components/Firebase';
import { Form, Input, Button } from 'semantic-ui-react';
import Loading from 'src/components/Loading';
import { ResetPasswordFormCSS } from './styles';

class ResetPassword extends React.Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      newPassword: '',
      newPasswordConfirm: '',
      isLoading: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: null, { name, value }: any) {
    this.setState({ [name]: value });
  }

  async handleSubmit() {
    const { firebase, t } = this.props;
    const { newPassword, newPasswordConfirm } = this.state;
    try {
      this.setState({ isLoading: true });
      if (newPassword !== newPasswordConfirm) {
        throw new Error(t('passwordCheckError'));
      }
      if (newPassword.length < 6) {
        throw new Error(t('passwordLengthError'));
      }
      await firebase.resetPassword(newPassword);
      this.setState({
        isLoading: false,
        newPassword: '',
        newPasswordConfirm: '',
      });
      toast.success(t('passwordUpdated'), {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });

    } catch (error) {
      this.setState({ isLoading: false })
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }

  }

  render() {
    const { t } = this.props;
    const { newPassword, newPasswordConfirm, isLoading } = this.state;
    return (
      <>
        <Helmet>
          <title>{t('resetPasswordTitle')}</title>
        </Helmet>
        <Loading active={isLoading} />
        <div className="wrapper-padding page-padding-bottom gray-background">
          <ResetPasswordFormCSS className="drop-shadow">
            <h2>{t('resetPasswordTitle')}</h2>
            <Form onSubmit={this.handleSubmit}>
              <Form.Field>
                <Input
                  onChange={(e: any, { name, value }: any) => this.handleChange(null, { name, value })}
                  value={newPassword}
                  name='newPassword'
                  type="password"
                  placeholder={t('newPassword')}
                />
              </Form.Field>
              <Form.Field>
                <Input
                  onChange={(e: any, { name, value }: any) => this.handleChange(null, { name, value })}
                  value={newPasswordConfirm}
                  name='newPasswordConfirm'
                  type="password"
                  placeholder={t('newPasswordConfirm')}
                />
              </Form.Field>
              <Form.Field>
                <Button type="submit" className="primary-light">{t('reset')}</Button>
              </Form.Field>
            </Form>
          </ResetPasswordFormCSS>
        </div>
      </>
    )
  }
}

export default R.compose<any, any, any>(
  withTranslation('settings'),
  withFirebase,
)(ResetPassword);