import React from 'react';
import { useTranslation } from 'react-i18next';
import { withUser } from 'src/components/Firebase';
import { useConnectHook } from 'src/screens/Settings/hooks';
import Loading from 'src/components/Loading';
import { SinglePaneCSS } from '../../styles';
import { connects } from '../../../../config';
import { ConnectCSS } from './styles';

const Connect = (props:any) => {
  const { t } = useTranslation('settings');
  const { user } = props;
  const {
    handleConnect,
    isLoading
  } = useConnectHook(user.merchantId)
  
  return (
    <>
      <Loading active={isLoading} />
      <SinglePaneCSS>
        <ConnectCSS className="drop-shadow">
          <h3>{t('connect')}</h3>
          <div className="container">
            {
              connects.map((x:any) => {
                const {
                  connect,
                  src,
                  alt
                } = x;
                return (
                  <div key={connect} className="single-connect">
                    <img 
                      id={`${connect}-image`} 
                      src={src} 
                      alt={alt} 
                      onClick={() => handleConnect(connect)}
                    />
                  </div>
                );
              })
            }
          </div>
        </ConnectCSS>
      </SinglePaneCSS>
    </>
  )
}

export default withUser(Connect);
