
import login from './login';
import register from './register';
import nav from './nav';
import dashboard from './dashboard';
import requestForm from './requestForm';
import mobileItems from './mobileItems';
import invoice from './invoice';
import settings from './settings';
import success from './success';
import general from './general';
import contact from './contact';
import payment from './payment';

export default {
  login,
  register,
  nav,
  dashboard,
  requestForm,
  mobileItems,
  invoice,
  settings,
  success,
  general,
  contact,
  payment
}