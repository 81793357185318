import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table, Icon } from 'semantic-ui-react';
import { withUser } from 'src/components/Firebase';


const SingleRow = (props: any) => {
  const { Row, Cell } = Table;
  const { t } = useTranslation('invoice');
  const { data, user } = props;
  const { requestTo, initiatedBy } = data;
  const cellPath = [['referenceNumber'], ['createdAt'], ['requestedAmount'], ['remainingAmount']];
  const company =
    user.merchantId === initiatedBy.merchantId ? requestTo.companyName : initiatedBy.companyName;
  const buyerRoleAndConfirmCheck = data.buyerId === user.merchantId && !data.buyerConfirmed;
  const sellerRoleAndConfirmCheck = data.sellerId === user.merchantId && !data.sellerConfirmed;
  const type = user.merchantId === data.sellerId ? t('getPaid') : t('pay');
  const actionRequired =
    buyerRoleAndConfirmCheck ||
    sellerRoleAndConfirmCheck ||
    (data.offerCreated && data.status === 'pending');

  return (
    <Row>
      <Cell>
        <Link to={`invoices/${data.id}`}>
          <div className="cell-companyName cell">
            {company}
            {actionRequired && <Icon name="exclamation circle" />}
          </div>
        </Link>
      </Cell>
      <Cell>
        <Link to={`invoices/${data.id}`}>
          <div className="cell-companyName cell">{type}</div>
        </Link>
      </Cell>
      {cellPath.map((cell: any) => {
        return (
          <Cell key={cell}>
            <Link to={`invoices/${data.id}`}>
              <div className="cell">{R.pathOr(null, [...cell], data)}</div>
            </Link>
          </Cell>
        );
      })}
      <Cell>
        <Link to={`invoices/${data.id}`}>
          <div className="cell-companyName cell">{t(R.pathOr(null, ['status'], data))}</div>
        </Link>
      </Cell>
    </Row>
  );
};

export default withUser(SingleRow);
