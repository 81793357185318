export default {
  nextPaymentDate: 'Next Payment Due',
  amount: 'Amount',
  status: 'Status',
  company: 'Company',
  remainingAmount: 'Remaining Amount',
  approved: 'Approved',
  pending: 'Pending',
  rejected: 'Rejected',
  completed: 'Completed',
  ongoing: 'On-going',
};
