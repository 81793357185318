import React from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import { List } from 'semantic-ui-react';
import { withUser } from 'src/components/Firebase';
import { SingleInvoiceContext } from 'src/context';
import { formatExtraPermissions } from 'src/config';
import { DetailsCSS } from './styles';
import ConfirmationButton from './components/ConfirmationButton';

const Details = (props: any) => {
  const { t } = useTranslation('invoice');
  const { user } = props;

  return (
    <SingleInvoiceContext.Consumer>
      {(invoice: any) => {
        const needConfirmation =
          (invoice.buyerId === user.merchantId && !invoice.buyerConfirmed) ||
          (invoice.sellerId === user.merchantId && !invoice.sellerConfirmed);

        const { requestTo, initiatedBy } = invoice;
        const type = invoice.buyerId === user.merchantId ? t('pay') : t('getPaid');
        const company =
          user.merchantId === initiatedBy.merchantId
            ? requestTo.companyName
            : initiatedBy.companyName;
        const permissions = formatExtraPermissions(initiatedBy.permissions);

        return (
          <DetailsCSS className="drop-shadow">
            <h3>{company}</h3>
            <div className="detail-container">
              <p className="label">{t('referenceNumber')}:</p>
              <p className="detail">{invoice.referenceNumber}</p>
            </div>
            <div className="detail-container">
              <p className="label">{t('date')}:</p>
              <p className="detail">{invoice.createdAt}</p>
            </div>
            <div className="detail-container">
              <p className="label">{t('requestedAmount')}:</p>
              <p className="detail">{invoice.requestedAmount}</p>
            </div>
            <div className="detail-container">
              <p className="label">{t('type')}:</p>
              <p className="detail type">{type}</p>
            </div>
            <div className="detail-container">
              <p className="label">{t('status')}:</p>
              <p className="detail status">{t(invoice.status)}</p>
            </div>
            {!!permissions.length && (
              <>
                <hr className="hr-gray" />
                <h4>{t('additionalPermission')}</h4>
                <List>
                  {permissions.map((x: any) => {
                    return <List.Item icon="bell" content={t(x.permission)} />;
                  })}
                </List>
              </>
            )}
            {!!needConfirmation && (
              <ConfirmationButton
                isBuyer={invoice.buyerId === user.merchantId}
                invoice={invoice}
                {...props}
              />
            )}
          </DetailsCSS>
        );
      }}
    </SingleInvoiceContext.Consumer>
  );
};

export default R.compose<any, any, any>(withUser, withRouter)(Details);
