import React from 'react';
import TermsAndConditionsDetails from 'src/components/TermsAndConditionsDetails';
import { TermAndConditionsCSS } from './styles';


const TermsAndConditions = () => {
  return (
    <TermAndConditionsCSS className="wrapper-padding page-padding-bottom">
      <div className="container drop-shadow">
        <TermsAndConditionsDetails />
      </div>
    </TermAndConditionsCSS>
  )
}

export default TermsAndConditions;