
export default {
  dashboard: '主頁',
  pay: '付款',
  getPaid: '收款',
  invoices: '賬單',
  contact: '聯絡我們',
  settings: '設定',
  logout: '登出',
  documents: '文件',
  connect: '連接',
  profile: '戶口',
}

