import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const ViewDetailContainerCSS = styled.div`
  width: 100%;
  background: white;
  border-radius: 4px;
  max-width: 350px;
  margin: auto;
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }

  p {
    font-size: 1rem;
    margin-bottom: 0;
  }

  hr {
    margin: 1rem -1rem 0 -1rem;
    border-top: 1px solid #f1f1f1;
  }

  .header {

    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4px 4px 0 0;
    padding: 1rem 1rem 0.5rem;

    h3 {
      margin-bottom: 0;
      color: var(--gray300);
      text-align: center;
    }

    i.icon {
      margin: 0;
      color: var(--gray300);
      font-size: 1.2rem;
      margin-right: -5px;
      cursor: pointer;
    }
  }

  .detail-container {
     display: flex;
     justify-content: space-between;
     align-items: center;
     margin-bottom: 0.5rem;
     align-items:center;
  }

  .all-details-container {
    padding: 1rem;
  }

  .label {
    color: var(--gray200);
  }

  #reset-password {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 0.5rem 0 0.5rem;
    color: var(--gray200);
  }

  #bank-detail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0 0;

    #bank-label {
      color: var(--gray200);
    }
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    max-width: none;
    padding: 1rem 1rem 0;

    #bank-detail-container{
      flex-direction: column;
      align-items: flex-start;

      #bank-detail {
        font-size: 1.2rem;
      }
    }

    .detail-container {
      flex-direction: column;
      align-items: flex-start;

      .detail {
        font-size: 1.2rem;
      }
    }

    hr {
      margin: 1rem -2rem 0 -2rem;
    }

    .header {
      background: white;

      h3 {
        color: var(--gray300);
      }

      i.icon {
        color: var(--gray300);
      }
    }
  }
`;
