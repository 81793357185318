import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleInvoiceOfferContext } from 'src/context';
import { withUser } from 'src/components/Firebase';
import { DetailsCSS } from './styles';
import Item from './components/Item';

const Details = (props: any) => {
  const { invoice, className, user } = props;
  const { t } = useTranslation('invoice');
  const { requestTo, initiatedBy } = invoice;
  const { permissions = {} } = initiatedBy;
  const didUserInitiateThisInvoice = user.merchantId === initiatedBy.merchantId;
  const email = didUserInitiateThisInvoice ? requestTo.email : initiatedBy.email;
  const phone = didUserInitiateThisInvoice ? requestTo.phone : initiatedBy.phone;
  const isBuyer = invoice.buyerId === user.merchantId;
  const isSeller = invoice.sellerId === user.merchantId;

  const displayOfferAmountIfSellerIsInitiator = isSeller && didUserInitiateThisInvoice;
  const displayOfferAmountIfSellerIsNotInitiator = isSeller && !didUserInitiateThisInvoice;
  const displayRemainingAmount = permissions.payForBuyer || isBuyer;

  return (
    <SingleInvoiceOfferContext.Consumer>
      {(offer: any) => {
        return (
          <>
            <DetailsCSS className={`${className} drop-shadow`}>
              <Item detail={invoice.referenceNumber} label="referenceNumber" />
              <Item detail={invoice.nextDueDate} label="dueDate" />
              <Item detail={invoice.requestedAmount} label="requestedAmount" />
              {displayOfferAmountIfSellerIsInitiator && (
                <Item detail={offer.offeredAmount} label="offeredAmount" />
              )}
              {displayOfferAmountIfSellerIsNotInitiator && (
                <Item detail={offer.requestedAmount} label="offeredAmount" />
              )}
              {displayRemainingAmount && (
                <Item detail={invoice.remainingAmount} label="remainingAmount" />
              )}
              <Item detail={email} label="email" />
              <Item detail={phone} label="phone" />
              <Item detail={t(invoice.status)} label="status" />
              <Item detail={invoice.purpose} label="purpose" />
            </DetailsCSS>
          </>
        );
      }}
    </SingleInvoiceOfferContext.Consumer>
  );
};

export default withUser(Details)

