import React, { useEffect } from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'src/components/Firebase';
import Loading from 'src/components/Loading'

const Logout = (props: any) => {
  useEffect(() => {
    const logout = async () => {
      try {
        const { history, firebase } = props;
        await firebase.logout();
        history.push('/');
        return;
      } catch (error) {
        toast.error(error.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
        });
      }
    }
    logout();
  }, []);

  return (
    <Loading />
  )
}

export default R.compose<any, any, any>(
  withRouter,
  withFirebase
)(Logout);