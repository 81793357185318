import React from 'react';
import { SingleInvoiceTransactionsContext } from 'src/context';
import NoData from 'src/components/NoData';
import TransactionTable from './components/TransactionTable';
import { TransactionsCSS } from './styles';

const Transactions = (props: any) => {
  const { className } = props;
  return (
    <TransactionsCSS className={`${className} drop-shadow`}>
      <SingleInvoiceTransactionsContext.Consumer>
        {transactions => {
          return (
            <>
              <TransactionTable
                {...props}
                transactions={transactions}
              />
              {!transactions.length && <NoData />}
            </>
          )
        }}
      </SingleInvoiceTransactionsContext.Consumer>
    </TransactionsCSS>
  )
}

export default Transactions;