import React from 'react';
import TermsAndConditions from 'src/components/TermsAndConditionsModal';
import Privacy from 'src/components/PrivacyModal';
import { DesktopNavCSS, PlaceholderCSS } from './styles';
import UserInfo from './components/UserInfo'
import NavItems from './components/NavItems';

const DesktopNav = () => {
  return (
    <>
      <PlaceholderCSS>
        <></>
      </PlaceholderCSS>
      <DesktopNavCSS>
        <UserInfo />
        <div className="nav-flex">
          <NavItems />
          <div className="terms-container">
            <TermsAndConditions />
            <Privacy />
          </div>
        </div>
      </DesktopNavCSS>
    </>
  )
}


export default DesktopNav;
