import styled from 'styled-components';

export const UserInfoCSS = styled.div`
  padding: 1.3rem 1rem;
  background: var(--primary);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-bottom: solid 1px #7389ba;

  h3 {
    margin: 0.2rem 0;
    color: white;
    font-weight: 400;
  }

  p {
    color: white;
    margin-bottom: 0.3rem;
    font-size: 1rem;

    &.credit-limit {
      font-weight: 600;
    }

    &.company-name {
      font-size: 1.2rem;
      font-weight: 300;
    }
  }


  i.large.icon, i.large.icons {
    color: white;
  }
`;