import React from 'react';
import { useTranslation } from 'react-i18next';
import { withUser } from 'src/components/Firebase';
import { useConnectHook } from 'src/screens/Settings/hooks';
import Loading from 'src/components/Loading';
import { ConnectCSS } from './styles';
import { connects } from '../../../../config';

const Connect = (props:any) => {
  const { t } = useTranslation('settings');
  const { user } = props;
  const {
    handleConnect,
    isLoading,
  } = useConnectHook(user.merchantId);
  
  return (
    <>
      <Loading active={isLoading} />
      <ConnectCSS className="wrapper-padding gray-background page-padding-bottom">
        <h3>{t('connect')}</h3>
        {
          connects.map((x:any) => {
            const {
              connect,
              src,
              alt
            } = x;
            return (
              <div key={connect} className="connects-container drop-shadow">
                <img 
                  id={`${connect}-image`} 
                  src={src} 
                  alt={alt} 
                  onClick={() => handleConnect(connect)}
                />
              </div>
            );
          })
        }
      </ConnectCSS>
    </>
  )
}

export default withUser(Connect);

