import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const CallbackCSS = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 2rem;
  
  .container {
    background: var(--gray100);
    padding: 2rem;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    text-align: center;
  }

  i.icon {
    margin-bottom: 1rem;
    color: var(--gray300);
  }

  p {
    font-size: 1.2rem;
  }

  h3 {
    margin-top: 0;
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    .container {
      width: auto;
      min-height: 150px;
      min-width: 300px;
    }
  }
`;
