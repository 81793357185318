import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslation from './en';
import cnTranslation from './cn';

i18n
  .use(initReactI18next)
  .init({
    ns: Object.keys(cnTranslation),
    resources: {
      en: enTranslation,
      cn: cnTranslation
    },
    lng: 'cn',
    fallbackLng: 'cn',
    debug: false,
    returnEmptyString: true,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitWhitelist: true,
  });

export default i18n;