import React from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Button} from 'semantic-ui-react';
import Upload from 'src/components/Upload';

const Photo = (props:any) => {
  const { t } = useTranslation('requestForm');
  const {
    filesCallback,
    requiredFiles
  } = props;
  return (
    <Form.Field required>
      <label htmlFor="upload">{t('uploadInvoiceProof')}</label>
      {!!requiredFiles.length && (
        <p>{requiredFiles.map((x: any) => x.name).join(', ')}</p>
      )}
      <Upload
        filesCallback={filesCallback}
        name="requiredFiles"
      >
        <Button
          className="invert-primary-light-button"
          type="button"
        >
          {t('upload')}
        </Button>
      </Upload>
    </Form.Field>
  )
}

export default Photo;
