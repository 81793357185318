
import React from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { Button, Input, Form } from 'semantic-ui-react'
import { withFirebase, withMerchant, withUser } from 'src/components/Firebase';
import EditDetailContainer from '../../../EditDetailContainer';
import { initialState } from './config';

class Edit extends React.Component<any, any>{
  constructor(props: any) {
    super(props);

    this.state = initialState;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async componentDidMount() {
    try {
      const { merchant, firebase, user, bank } = this.props;
      const { bankName, bankNumber } = bank;
      const { companyName, phone, email, website } = merchant;
      let bankPermission = await firebase.merchantTeamDoc(merchant.id, user.id).get();
      bankPermission = bankPermission.exists;
      this.setState({
        bankPermission,
        companyName,
        phone,
        email,
        website,
        bankName,
        bankNumber
      })
    } catch (error) {
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: 6000,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  handleChange = (e: null, { name, value }: any) => {
    this.setState({ [name]: value });
  }

  handleSubmit = async () => {
    const { toggleEdit, toggleLoading, firebase, user } = this.props;
    const { companyName, phone, email, website, bankPermission, bankName, bankNumber } = this.state;
    try {
      toggleLoading(true);
      const updateMerchant = firebase
        .merchantDoc(user.merchantId)
        .set({
          companyName,
          phone,
          email,
          website
        }, { merge: true });

      const updates: any[] = [updateMerchant];
      if (bankPermission) {
        const updateBank = firebase
          .merchantBankDoc(user.merchantId)
          .set({
            bankName,
            bankNumber
          }, { merge: true });
        updates.push(updateBank);
      }

      Promise
        .all(updates)
        .catch((err: any) => {
          throw new Error(err.message)
        })

      toast.dismiss();
      toggleEdit(false);
      toggleLoading(false);
    } catch (error) {
      toggleLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const { toggleEdit } = this.props;
    const { companyName, email, phone, website, bankName, bankNumber, bankPermission } = this.state;
    return (
      <EditDetailContainer>
        <Form onSubmit={this.handleSubmit}>
          <Form.Field
            required
            control={Input}
            value={companyName}
            name='companyName'
            label={t('name')}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            control={Input}
            value={email}
            name='email'
            label={t('email')}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            control={Input}
            value={phone}
            name='phone'
            label={t('phone')}
            onChange={this.handleChange}
          />
          <Form.Field
            control={Input}
            value={website}
            name='website'
            label={t('website')}
            onChange={this.handleChange}
          />
          {!!bankPermission && (
            <>
              <Form.Field
                required
                control={Input}
                value={bankName}
                name='bankName'
                label={t('bankName')}
                onChange={this.handleChange}
              />
              <Form.Field
                required
                control={Input}
                value={bankNumber}
                name='bankNumber'
                label={t('bankNumber')}
                onChange={this.handleChange}
              />
            </>
          )}
          <Button
            type='submit'
          >
            {t('save')}
          </Button>
          <Button
            type='button'
            className='invert-primary-light-button'
            onClick={() => toggleEdit(false)}
          >
            {t('cancel')}
          </Button>
        </Form>
      </EditDetailContainer>
    )
  }
}

export default R.compose<any, any, any, any, any>(
  withTranslation('settings'),
  withFirebase,
  withMerchant,
  withUser
)(Edit);