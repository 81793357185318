import React from 'react';
import Item from './components/Item'
import { MobileItemsCSS } from './styles';

const MobileListInvoices = (props: any) => {
  const { invoices } = props;
  return (
    <MobileItemsCSS className="wrapper-padding page-padding-bottom">
      <div className='items-container'>
        {invoices.map((data: any) => {
          return (
            <Item data={data} key={data.id} />
          )
        })}
      </div>
    </MobileItemsCSS>
  )
}

export default MobileListInvoices;