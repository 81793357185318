export const convertToMoney = (num = 0, decimal = 2, showCurrency = true) => {
  if (!num) {
    // eslint-disable-next-line
    num = 0;
  }
  if (typeof num === 'string') {
    // eslint-disable-next-line
    num = parseInt(num);
  }

  // eslint-disable-next-line
  let result = num.toFixed(decimal).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,');

  if (showCurrency) {
    result = `HKD$${result}`;
  }
  return result;
};
