import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  ContactSuccessWrapper,
} from './styles';

function ContactSuccess() {
  const { t } = useTranslation('contact');
  return (
    <ContactSuccessWrapper>
      <h3>{t('contactSuccessTitle')}</h3>
      <p>{t('contactSuccessMessage')}</p>
    </ContactSuccessWrapper>
  )
}

export default ContactSuccess;
