import React from 'react';
import { Helmet } from 'react-helmet';
import * as R from 'ramda';
import { withRouter } from 'react-router-dom';
import {
  SingleInvoiceContext,
  SingleInvoiceTransactionsContext,
  SingleInvoiceOfferContext
} from 'src/context';
import { SingleInvoice as SingleInvoiceModel, SingleInvoiceOffer } from 'src/models/SingleInvoice';
import { withFirebase, withUser } from 'src/components/Firebase';
import { useDocumentHook, useCollectionHook } from 'src/components/Firebase/utils'
import Loading from 'src/components/Loading';
import Invoice from './screens/Invoice';
import Offer from './screens/Offer';
import OngoingAndCompletedInvoice from './screens/OngoingAndCompletedInvoice'


const SingleInvoice = (props: any) => {
  const { firebase, user } = props;
  const singleInvoiceId = R.pathOr(null, ['match', 'params', 'id'], props);
  const invoiceRef = firebase.invoiceDoc(singleInvoiceId);
  const invoice = useDocumentHook(invoiceRef);

  const transactionsRef = firebase.invoiceTransactionsCollection(singleInvoiceId);
  const transactions = useCollectionHook(transactionsRef);

  const offerRef = firebase.invoiceOfferCollection(singleInvoiceId);
  const offer = useCollectionHook(offerRef);

  if (invoice.isLoading || transactions.isLoading || offer.isLoading) {
    return (
      <Loading />
    )
  }
  let offerData = {};
  if (offer.data.length) {
    offerData = SingleInvoiceOffer.formatData(offer.data[0]);
  }

  const invoiceData: any = SingleInvoiceModel.formatData(invoice.data);
  const { status, initiatedBy, requestTo, offerCreated } = invoiceData;
  const check = status.toLowerCase() === 'ongoing' || status.toLowerCase() === 'complete' || status.toLowerCase() === 'completed';
  const company = user.merchantId === invoiceData.sellerId ? initiatedBy.companyName : requestTo.companyName;
  const didMerchantInitiateInvoice = initiatedBy.merchantId === user.merchantId;

  const renderScreen = () => {
    if (check) {
      return <OngoingAndCompletedInvoice />
    }

    if (didMerchantInitiateInvoice && offerCreated && status.toLowerCase() === 'pending') {
      return <Offer />
    }
    return <Invoice />
  }

  return (
    <>
      <Helmet>
        <title>{company}</title>
      </Helmet>
      <div className="gray-background page-padding-bottom wrapper-padding">
        <SingleInvoiceContext.Provider value={invoiceData}>
          <SingleInvoiceTransactionsContext.Provider value={transactions.data}>
            <SingleInvoiceOfferContext.Provider value={offerData}>
              {renderScreen()}
            </SingleInvoiceOfferContext.Provider>
          </SingleInvoiceTransactionsContext.Provider>
        </SingleInvoiceContext.Provider>
      </div>
    </>
  )
}

export default R.compose<any, any, any, any>(
  withFirebase,
  withUser,
  withRouter
)(SingleInvoice);