import styled from 'styled-components';
import { desktopWidth } from 'src/config';

export const ContactCSS = styled.div`
  .form, .details {
    width: 100%;
    max-width: 350px;
    margin: auto;
  }

  .form {
    padding: 1.5rem 0 0 0;
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    .contact-container {
      display: flex;
      flex-direction: row-reverse;
      align-items: flex-start;
      justify-content: center;
    }

    h1 {
      color: var(--gray200);
      margin: 1rem 0;
    }

    .form, .details {
      margin: 0;
    }

    .details {
      max-width: none;
      width: 35%;
    }


    .form {
      padding: 0;
      max-width: none;
      flex: 1;
      margin-right: 3rem;
    }

  }
`;