import React from 'react';
import { TableCSS } from './styles';

const Table = (props: any) => {
  const { children } = props;
  return (
    <TableCSS>
      {children}
    </TableCSS>
  )
}

export default Table;