import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'semantic-ui-react';
import PrivacyDetails from '../PrivacyDetails';
import { TriggerCSS, ContentCSS } from './styles';


const PrivacyModal = () => {
  const { t } = useTranslation('general');
  return (
    <Modal trigger={
      (
        <TriggerCSS>
          {t('privacy')}
        </TriggerCSS>
      )
    }
    >
      <Modal.Header>
        {t('privacy')}
      </Modal.Header>
      <Modal.Content>
        <ContentCSS>
          <PrivacyDetails />
        </ContentCSS>
      </Modal.Content>
    </Modal>
  )
}

export default PrivacyModal;