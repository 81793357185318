
import styled from 'styled-components';
import { tabletWidth, largeDesktopWidth } from 'src/config';

export const DoTodayCSS = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
  height: 185px;

  .text-container {
    padding: 1.5rem;
    position: absolute;
    background: white;
    top: -38px;
    z-index: 3;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 85%;
  }

  .ui.button {
    width: 100%;
    background: var(--primary) none;
    margin: 0.5em 0;
    color: white;
    font-weight: 400;
    white-space: nowrap;
  }

  p {
    color: var(--fontColor);
    text-align: center;

  }

  a {
    width: 100%;
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    padding: 0 !important;
    justify-content: center;
    height: 120px;

    .text-container {
      position: static;
      top: auto;
      height: 120px;
      width: 100%;

      #do-today-link-container {
        display: flex;
        width: 100%;
        justify-content: space-between;

        a {
          width: 50%;
          padding: 0 0.5rem;
        }
      }

    }
  }

  @media only screen and (min-width: ${largeDesktopWidth}px) {
    .text-container {
      height: 200px;
    }
  }
`;