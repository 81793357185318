import styled from 'styled-components';

export const SettingsCSS = styled.div`
  h1 {
    color: var(--gray200);
    margin: 1rem 0 1.5rem;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;


export const SinglePaneCSS = styled.div `
  padding: 1.5rem 0 4rem 0;
`;
