import styled from 'styled-components';
import { tabletWidth, desktopWidth } from 'src/config';

export const OfferCSS = styled.div`
  width: 100%;
  background: white;
  padding: 1rem;
  border-radius: 6px;

  h3 {
    text-align: center;
  }

  .button-container {
    display: flex;
    flex-direction: column;

    .ui.button {
      margin: 5px 0;
    }
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    max-width: 500px;
    margin: auto;
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    margin-top: 2rem;
  }
`;