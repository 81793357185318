export default {
  title: '新賬單',
  merchantDetails: '商戶資料',
  merchant: '商戶',
  selectMerchant: '請選擇...',
  addNew: '新增',
  companyName: '公司名稱',
  email: '電郵地址',
  phone: '電話號碼',
  requestDetails: '賬單明細',
  amount: '金額',
  purpose: '賬項內容',
  note: '備註',
  deliveryTime: '貨期(日數)',
  submit: '提交',
  selectMerchantError: '請選擇商戶',
  amountError: '金額須為整數',
  deliveryTimeError: '貨期須為整數',
  purposeError: '請輸入賬項內容',
  newMerchantEmailError: '請輸入電郵地址',
  newMerchantNameError: '請輸入公司名稱',
  company: '公司名稱',
  requestedAmount: '賬單金額',
  confirm: '確認',
  goBack: '返回',
  type: '類別',
  pay: '付款',
  "get-paid": '收款',
  uploadInvoiceProof: '票據文件',
  upload: '上傳',
  uploadSuccess: '上傳成功',
  uploadFileError: '上傳失敗，請重試',
  invoiceUploadError: '上傳失敗，請重試',
  amountValueError: '金額須大於 0',
  deliveryTimeValueError: '貨期須大於 0',
  noFilesFound: '請選擇上傳檔案',
  sizeLimitError: '超過上傳大小上限 (10mb).',
  uploadAdditionalProof: '補充資料 (選填)',
  payForBuyer: '賣方到期支付',
  additionalPermissions: '其他',
  select: '請選擇...',
  photo: '圖片',
  xero: 'Xero',
  quickbooks: 'Quickbooks',
  invoice: '票據',
  invoiceNumber: '票據號碼',
  invoiceUrl: '票據網絡連結',
  or: '或',
  invoiceError: '請提供票據',
  connectInvoiceError: '連接票據記錄失敗，請重試。',
  invalidUrl: 'Invoice URL is invalid.'
}

