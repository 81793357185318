
import React from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { withFirebase } from 'src/components/Firebase';
import { withTranslation } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { Form, Input, Icon } from 'semantic-ui-react'
import { LoginFormCSS } from './styles';

class LoginForm extends React.Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      email: '',
      password: '',
      passwordHide: true,
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e: null, { name, value }: any) {
    this.setState({ [name]: value });
  }

  async handleSubmit(e: any) {
    e.preventDefault();
    const { firebase, toggleLoading } = this.props;
    const { email, password } = this.state;
    try {
      toggleLoading(true);
      await firebase.login(email, password);
      toast.dismiss();
    } catch (error) {
      toggleLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  render() {
    const { password, passwordHide } = this.state;
    const { t } = this.props;
    return (
      <LoginFormCSS className="drop-shadow">
        <Form onSubmit={this.handleSubmit}>
          <Form.Field
            required
            control={Input}
            name='email'
            placeholder={t('email')}
            onChange={this.handleChange}
          />
          <Form.Field>
            <Input
              onChange={(e: any, { name, value }: any) => this.handleChange(null, { name, value })}
              value={password}
              name='password'
              type={passwordHide ? "password" : ''}
              icon={
                (
                  <Icon
                    link
                    onClick={() => this.handleChange(null, { name: 'passwordHide', value: !passwordHide })}
                    name={passwordHide ? 'eye slash' : 'eye'}
                  />
                )
              }
              placeholder={t('password')}
            />
          </Form.Field>
          <Form.Button>{t('login')}</Form.Button>
        </Form>
      </LoginFormCSS>
    )
  }
}

export default R.compose<any, any, any, any>(
  withTranslation('login'),
  withRouter,
  withFirebase
)(LoginForm);