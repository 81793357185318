import React from 'react';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import { Icon } from 'semantic-ui-react';
import { SingleInvoiceContext } from 'src/context';
import { withUser } from 'src/components/Firebase'
import { MobileOngoingAndCompletedInvoiceCSS } from './styles';
import ListTransactions from './components/ListTransactions';
import Details from './components/Details';
import PayModal from '../../components/PayModal';

const MobileOngoingAndCompletedInvoice = (props: any) => {
  const { user } = props;
  const { t } = useTranslation('invoice');

  return (
    <MobileOngoingAndCompletedInvoiceCSS>
      <Details />
      <div className="title-and-button-container">
        <p>{t('transactions')} <Icon name='triangle down' /></p>
        <SingleInvoiceContext.Consumer>
          {
            (invoice: any) => {
              const { initiatedBy } = invoice;
              const { permissions = {} } = initiatedBy;
              const didUserInitiateThisInvoice = user.merchantId === initiatedBy.merchantId;
              const displayPay = (invoice.status === 'ongoing' && invoice.buyerId === user.merchantId) || (didUserInitiateThisInvoice && permissions.payForBuyer && invoice.status === 'ongoing');

              return (
                !!displayPay && (
                  <PayModal />
                )
              )
            }
          }
        </SingleInvoiceContext.Consumer>
      </div>
      <ListTransactions />
    </MobileOngoingAndCompletedInvoiceCSS>
  )
}

export default R.compose<any, any>(
  withUser,
)(MobileOngoingAndCompletedInvoice);