import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import { withFirebase, withUser, withMerchant } from './utils';
import { config } from './config';

class Firebase {
  auth: any;
  db: any;
  storage: any;
  constructor() {
    app.initializeApp(config);
    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  
  getFieldValue() {
    return app.firestore.FieldValue;
  }

  resetPassword(password: string) {
    return this.auth.currentUser.updatePassword(password);
  }

  uploadInvoice(merchantId: any, invoiceId: any, fileName: any) {
    return this.storage.ref().child(`merchants/${merchantId}/invoices/${invoiceId}/images/${fileName}`);
  }

  uploadMerchantFiles(merchantId: any, fileName:any) {
    return this.storage.ref().child(`merchants/${merchantId}/files/${fileName}`);
  }

  login(email: string, password: string) {
    return this.auth.signInWithEmailAndPassword(email, password);
  }

  register(email: string, password: string) {
    return this.auth.createUser({
      email,
      password
    });

  }

  logout() {
    return this.auth.signOut();
  }

  roleDoc(uid: string) {
    return this.db.collection('roles').doc(uid);
  }

  userDoc(uid: string) {
    return this.db.collection('users').doc(uid);
  }

  merchantCollection() {
    return this.db.collection('merchants');
  }

  merchantDoc(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId);
  }

  merchantTokensCollection(merchantId:string) {
    return this.db.collection('merchants').doc(merchantId).collection('tokens');
  }

  merchantDocumentsCollection(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId).collection('documents');
  }

  merchantFilesDoc(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId).collection('files');
  }

  merchantExistingMerchantsCollection(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId).collection('existingMerchants');
  }

  merchantTeamDoc(merchantId: string, uid: string) {
    return this.db.collection('merchants').doc(merchantId).collection('team').doc(uid);
  }

  merchantBankDoc(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId).collection('permissions').doc('bank');
  }

  merchantGetPaidPermissionsDoc(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId).collection('permissions').doc('getPaid');
  }

  merchantPayPermissionsDoc(merchantId: string) {
    return this.db.collection('merchants').doc(merchantId).collection('permissions').doc('pay');
  }

  invoiceCollection() {
    return this.db.collection('invoices');
  }

  invoiceDoc(docId: any) {
    return this.db.collection('invoices').doc(docId);
  }

  invoiceTransactionsCollection(docId: any) {
    return this.db.collection('invoices').doc(docId).collection('transactions');
  }

  invoiceDocumentsRequiredDoc(docId: any) {
    return this.db.collection('invoices').doc(docId).collection('documents');
  }

  invoiceDocumentsAdditionalDoc(docId: any) {
    return this.db.collection('invoices').doc(docId).collection('documents');
  }

  invoiceOfferCollection(docId: any) {
    return this.db.collection('invoices').doc(docId).collection('offer');
  }

  invoiceOfferDoc(docId: any, offerId: any) {
    return this.db.collection('invoices').doc(docId).collection('offer').doc(offerId);
  }

}

export default Firebase;

export {
  withFirebase,
  withUser,
  withMerchant
};

