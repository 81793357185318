import React from 'react';
import { useTranslation } from 'react-i18next';
import { mapDocumentTypes } from 'src/screens/Settings/config';
import ListDocuments from '../ListDocuments';
import SingleDocument from '../../../../../../components/SingleDocument';

const SubmittedDocuments = (props:any) => {
  const { t } = useTranslation('settings');
  const { toggleScreen, documents } = props;
  return (
    <ListDocuments 
      toggleScreen={toggleScreen}
      title={t('submittedDocuments')}
      returnTo={t('requiredDocuments')}
    >
      {documents.map((document:any)=>{
        return (
          <SingleDocument key={document.id}>
            <h4>{mapDocumentTypes(document.type)}</h4>
            <a href={document.url} target="_blank" rel="noopener noreferrer">
              <p>{document.fileName}</p>
            </a>
          </SingleDocument>
        )
      })}
    </ListDocuments>
    )
}


export default SubmittedDocuments;
