import React from 'react';
import { Button, List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { formatExtraPermissions } from 'src/config';
import { convertToMoney } from 'src/utils/convertToMoney';
import { formatSubmission } from '../../config';
import Detail from './components/Detail';
import { ConfirmFormCSS } from './styles';

const ConfirmForm = (props: any) => {
  const { toggleConfirmation, handleSubmit, state, user, path, pastMerchants, merchant } = props;
  const { t } = useTranslation('requestForm');
  const data = formatSubmission(state, pastMerchants, user, merchant, path);
  const { requestTo, initiatedBy } = data;
  const listPermissions = formatExtraPermissions(initiatedBy.permissions);
  

  return (
    <ConfirmFormCSS>
      <Detail label={t('type')} detail={t(path)} />
      <Detail label={t('company')} detail={requestTo.companyName} />
      <Detail label={t('email')} detail={requestTo.email} />
      <Detail label={t('phone')} detail={requestTo.phone} />
      <hr />
      <Detail label={t('requestedAmount')} detail={convertToMoney(data.requestedAmount)} />
      <Detail label={t('purpose')} detail={data.purpose} />
      <Detail label={t('deliveryTime')} detail={data.deliveryTime} />
      {!!listPermissions.length && (
        <>
          <hr />
          <Detail label={t('additionalPermission')} detail="" />
          <List>
            {listPermissions.map((x: any) => {
              return <List.Item key={x.permission} icon="bell" content={t(x.permission)} />;
            })}
          </List>
        </>
      )}
      <Button type="button" onClick={handleSubmit}>
        {t('confirm')}
      </Button>
      <Button
        type="button"
        className="invert-primary-light-button"
        onClick={() => toggleConfirmation(false)}
      >
        {t('goBack')}
      </Button>
    </ConfirmFormCSS>
  );
};

export default ConfirmForm;
