import React from 'react';
import { ListInvoiceContext } from 'src/context';
import { ListInvoicesCSS } from './styles';
import Item from './components/Item';

const ListInvoices = () => {

  return (
    <ListInvoicesCSS className='wrapper-padding'>
      <div className='items-container'>
        <ListInvoiceContext.Consumer>
          {(invoices: any) => {
            return (
              invoices.map((x: any) => (
                <Item key={x.id} data={x} />
              ))
            )
          }}
        </ListInvoiceContext.Consumer>
      </div>
    </ListInvoicesCSS>
  )
}

export default ListInvoices;