import React from 'react';
import axios from 'axios';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { withRouter } from 'react-router-dom';
import { withFirebase } from 'src/components/Firebase';
import { withTranslation } from 'react-i18next';
import { Form, Input, Icon, Checkbox } from 'semantic-ui-react';
import NumberInput from 'src/components/NumberInput';
import TermsAndConditions from 'src/components/TermsAndConditionsModal';
import { RegisterFormCSS } from './styles';

// eslint-disable-next-linc
class RegisterForm extends React.Component<any, any>{
  constructor(props: any) {
    super(props);
    this.state = {
      companyName: '',
      establish: '',
      annualTurnover: '',
      name: '',
      email: '',
      phone: '',
      password: '',
      retypePassword: '',
      passwordHide: true,
      retypePasswordHide: true,
      termsAndConditionChecked: false
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  handleChange(e: null, { name, value }: any) {
    this.setState({ [name]: value });
  }

  validateForm() {
    const { t } = this.props;
    const { password, retypePassword, email, termsAndConditionChecked } = this.state;
    // eslint-disable-next-line
    const emailRegex = RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

    if (!emailRegex.test(email)) {
      throw Error(t('invalidEmail'));
    }

    if (password.length < 6) {
      throw Error(t('passwordLength'));
    }

    if (password !== retypePassword) {
      throw Error(t('passwordMismatch'));
    }

    if (!termsAndConditionChecked) {
      throw Error(t('termsAndConditionError'))
    }

    return true;
  }

  async handleSubmit(e: any) {
    e.preventDefault();
    
    const { firebase, toggleLoading, t, i18n } = this.props;
    const {
      email,
      password,
      name,
      phone,
      companyName,
      establish,
      annualTurnover
    } = this.state;

    try {
      toggleLoading(true);
      this.validateForm();

      const { status, data: authUser }: any = await axios.post(
        `https://us-central1-${process.env.REACT_APP_PROJECT_ID}.cloudfunctions.net/api/register`,
        {
          email,
          password
        }
      ).catch((error: any) => {
        const message = R.pathOr(t('accountCouldNotBeCreated'), ['response', 'data', 'message'], error);
        throw Error(message);
      })

      if (status !== 200 && status !== 201) {
        throw Error(t('accountCouldNotBeCreated'));
      }

      const { uid } = authUser;

      const merchant = await firebase.merchantCollection().add({
        companyName,
        email,
        phone,
        establish,
        annualTurnover,
        owner: uid,
        getStartedDisplay: true,
      })

      const newUser = firebase
        .userDoc(uid)
        .set({
          merchantId: merchant.id,
          name,
          email,
          phone,
          language: i18n.language
        })

      const newMerchantTeam = firebase.merchantTeamDoc(merchant.id, uid).set({})

      await Promise
        .all([newUser, newMerchantTeam])
        .catch((err: any) => {
          throw new Error(err.message)
        })
      toast.dismiss();
      await firebase.login(email, password);
    } catch (error) {
      toggleLoading(false);
      toast.error(error.message, {
        position: toast.POSITION.TOP_CENTER,
        autoClose: false,
        hideProgressBar: true,
        closeOnClick: true,
        draggable: false,
      });
    }
  }

  render() {
    const { t } = this.props;
    const {
      passwordHide,
      password,
      retypePasswordHide,
      retypePassword,
      annualTurnover,
      termsAndConditionChecked
    } = this.state;


    return (
      <RegisterFormCSS className="drop-shadow">
        <Form onSubmit={this.handleSubmit}>
          <h3>{t('businessDetails')}</h3>
          <Form.Field
            required
            control={Input}
            name='companyName'
            placeholder={t('companyName')}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            control={Input}
            name='establish'
            placeholder={t('establish')}
            onChange={this.handleChange}
          />

          <Form.Field required>
            <NumberInput
              name='annualTurnover'
              placeholder={t('annualTurnover')}
              onChange={(value: any) => this.handleChange(null, { name: 'annualTurnover', value })}
              value={annualTurnover}
            />
          </Form.Field>
          <h3>{t('personalDetails')}</h3>
          <Form.Field
            required
            control={Input}
            name='name'
            placeholder={t('name')}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            control={Input}
            name='email'
            placeholder={t('email')}
            onChange={this.handleChange}
          />
          <Form.Field
            required
            control={Input}
            name='phone'
            placeholder={t('phone')}
            onChange={this.handleChange}
          />
          <Form.Field>
            <Input
              onChange={(e: any, { name, value }: any) => this.handleChange(null, { name, value })}
              value={password}
              name='password'
              type={passwordHide ? "password" : ''}
              icon={
                (
                  <Icon
                    link
                    onClick={() => this.handleChange(null, { name: 'passwordHide', value: !passwordHide })}
                    name={passwordHide ? 'eye slash' : 'eye'}
                  />
                )
              }
              placeholder={t('password')}
            />
          </Form.Field>
          <Form.Field>
            <Input
              onChange={(e: any, { name, value }: any) => this.handleChange(null, { name, value })}
              value={retypePassword}
              name='retypePassword'
              type={retypePasswordHide ? "password" : ''}
              icon={
                (
                  <Icon
                    link
                    onClick={() => this.handleChange(null, { name: 'retypePasswordHide', value: !retypePasswordHide })}
                    name={retypePasswordHide ? 'eye slash' : 'eye'}
                  />
                )
              }
              placeholder={t('retypePassword')}
            />
          </Form.Field>
          <Form.Checkbox
            className="checkbox"
            name='termsAndConditionChecked'
            onChange={(e: any, { name, checked }: any) => this.handleChange(null, { name, value: checked })}
            control={Checkbox}
            label={(
              <label>
                {t('agreeToTermsAndConditions')}&nbsp;
                <TermsAndConditions />
              </label>
            )}
            checked={termsAndConditionChecked}
          />
          <Form.Button>{t('register')}</Form.Button>
        </Form>
      </RegisterFormCSS>
    )
  }
}

export default R.compose<any, any, any, any>(
  withTranslation('register'),
  withRouter,
  withFirebase
)(RegisterForm);
