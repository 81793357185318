import React from 'react';
import { useSpring } from 'react-spring';
import Photo from './components/Photo';
import Connect from './components/Connect';
import {InvoiceProofCSS} from './styles';

const InvoiceProof = (props:any) => {
  const { invoiceOption } = props;

  const {maxHeight} = useSpring({
    maxHeight: invoiceOption ? 300 : 0,
 });
  
  const renderOptions = () => {
    switch(invoiceOption) {      
      case 'xero':
      case 'quickbooks':
        return (
          <Connect {...props} type={invoiceOption} />
        )
      case 'photo':
        return (
          <Photo {...props} />
        )
      default: 
        return null;
    }
  }

  return (
    <InvoiceProofCSS style={{maxHeight}}>
      {renderOptions()}
    </InvoiceProofCSS>
  )
}

export default InvoiceProof;
