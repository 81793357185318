import styled from 'styled-components';

export const ConfirmationButtonCSS = styled.div`
  width: 100%;

  .ui.checkbox input.hidden+label {
    font-size: 0.8rem;
  }

  .ui.form .field {
    display: flex;
    justify-content: center;
    flex-direction: column;
    margin: 1rem 0;
    color: var(--fontColor);
    font-weight: 300;
  }

  .ui.button {
    width: 100%;
    background: var(--primaryLight);
    color: white;
    margin-top: 0.5rem;
  }
`;