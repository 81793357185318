import React from 'react';
import * as R from 'ramda';
import { withUser } from 'src/components/Firebase'
import { SingleInvoiceTransactions } from 'src/models/SingleInvoice';
import { Table } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TableComponent from 'src/components/Table';
import PayModal from '../../../../../../components/PayModal';

const TransactionTable = (props: any) => {
  const { t } = useTranslation('invoice');
  const { transactions, invoice, user } = props;
  const { initiatedBy } = invoice;
  const { permissions = {} } = initiatedBy;
  const formattedData = transactions.map((x: any) => SingleInvoiceTransactions.formatData(x));
  const {
    Header,
    Row,
    HeaderCell,
    Body,
    Cell
  } = Table;
  const cellPath = [['date'], ['amount'], ['status']];
  const didUserInitiateThisInvoice = user.merchantId === initiatedBy.merchantId;
  const displayPay = (invoice.status === 'ongoing' && invoice.buyerId === user.merchantId) || (didUserInitiateThisInvoice && permissions.payForBuyer && invoice.status === 'ongoing');

  return (
    <TableComponent>
      <div className="table-details-container">
        <h3>{t('transactions')}</h3>
        {!!displayPay && (
          <PayModal />
        )}
      </div>
      <Table
        unstackable
        selectable={false}
      >
        <Header>
          <Row>
            <HeaderCell>{t('date')}</HeaderCell>
            <HeaderCell>{t('amount')}</HeaderCell>
            <HeaderCell>{t('status')}</HeaderCell>
          </Row>
        </Header>
        <Body>
          {
            formattedData.map((x: any) => {
              return (
                <Row key={x.id}>
                  {cellPath.map((cell: any) => {
                    return (
                      <Cell key={cell}>
                        <div className="cell">
                          {R.pathOr(null, [...cell], x)}
                        </div>
                      </Cell>
                    )
                  })}
                </Row>
              )
            })
          }
        </Body>
      </Table>
    </TableComponent>
  )
}

export default withUser(TransactionTable);