import React from 'react';
import { useTranslation } from 'react-i18next';
import { ItemCSS } from './styles';

const Item = (props: any) => {
  const { t } = useTranslation('invoice');
  const { className, label, detail } = props;
  return (
    <ItemCSS className={className}>
      <p className="label">{t(label)} :</p>
      <p className="detail">{detail}</p>
    </ItemCSS>
  );
};

export default Item;
