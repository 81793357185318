import React from 'react';
import { DesktopListInvoicesCSS } from './styles';

const DesktopPayAndGetPaid = (props: any) => {
  const { children } = props;
  return (
    <DesktopListInvoicesCSS className="page-padding-bottom wrapper-padding">
      {children}
    </DesktopListInvoicesCSS>
  )
}

export default DesktopPayAndGetPaid;