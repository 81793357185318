import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import * as R from 'ramda';
import Media from 'react-media';
import { ListActiveAndCompletedInvoiceContext } from 'src/context';
import { withFirebase, withUser } from 'src/components/Firebase';
import { useCollectionHook } from 'src/components/Firebase/utils';
import { tabletWidth } from 'src/config';
import sortByCreatedAt from 'src/utils/sortByCreatedAt';
import MobilePay from './screens/MobilePay';
import DesktopPay from './screens/DesktopPay';

const Pay = (props: any) => {
  const { firebase, user } = props;
  const ongoingRef = firebase
    .invoiceCollection()
    .where('buyerId', '==', user.merchantId)
    .where('status', '==', 'ongoing');

  const completedRef = firebase
    .invoiceCollection()
    .where('buyerId', '==', user.merchantId)
    .where('status', '==', 'complete');

  const ongoingData = useCollectionHook(ongoingRef);
  const completedData = useCollectionHook(completedRef);

  const invoices = sortByCreatedAt([...ongoingData.data, ...completedData.data]);

  const { t } = useTranslation('invoice');

  return (
    <>
      <Helmet>
        <title>{t('payTitle')}</title>
      </Helmet>
      <ListActiveAndCompletedInvoiceContext.Provider value={invoices}>
        <Media query={{ minWidth: tabletWidth }}>
          {matches => (matches ? <DesktopPay /> : <MobilePay />)}
        </Media>
      </ListActiveAndCompletedInvoiceContext.Provider>
    </>
  );
};

export default R.compose<any, any, any>(withUser, withFirebase)(Pay);
