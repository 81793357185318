
import React from 'react';
import { useTranslation } from 'react-i18next';
import { DetailCSS } from './styles';

const Detail = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { label, detail } = props;

  return (
    <DetailCSS>
      <p className='label'>{t(label)}:</p>
      <p className='detail'>{detail}</p>
    </DetailCSS>
  )
}

export default Detail;
