import styled from 'styled-components';

export const SingleDocumentCSS = styled.div`
  padding: 2rem 1rem;
  background: white;
  margin: 1rem 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;


  h4 {
    text-align: center;
    color: var(--gray300);
    margin-bottom: 0;
  }

  p {
    line-height: 2;
    font-size: 1rem;
  }

  a {
    text-decoration: underline;
  }
`;
