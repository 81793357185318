import React, { useState } from 'react';
import { SingleInvoiceContext } from 'src/context';
import { Button, Modal, Icon } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { PayModalHeaderCSS, PayModalBodyCSS } from './styles';
import Success from './components/Success';
import PaymentForm from './components/PaymentForm';

const PayModal = () => {
  const [showSuccess, setSuccess] = useState(false);
  const [showModal, toggleShowModal] = useState(false);
  const { t } = useTranslation('invoice');

  const handleClose = () => {
    toggleShowModal(false);
    setSuccess(false);
  };

  return (
    <SingleInvoiceContext.Consumer>
      {invoice => {
        const { remainingAmount, remainingAmountNoFormat } = invoice;
        return (
          <Modal
            trigger={(
              <Button
                onClick={() => toggleShowModal(true)}
                className="primary-dark"
              >
                <Icon name="world" />
                {t('pay')}
              </Button>
            )}
            open={showModal}
            onClose={handleClose}
          >
            <Modal.Header>
              <PayModalHeaderCSS>
                {t('remainingAmount')}: {remainingAmount}
              </PayModalHeaderCSS>
            </Modal.Header>
            <Modal.Content>
              <PayModalBodyCSS>
                {showSuccess ? (
                  <Success toggleShowModal={toggleShowModal} setSuccess={setSuccess} />
                ) : (
                  <PaymentForm
                    remainingAmount={remainingAmountNoFormat}
                    setSuccess={setSuccess}
                    handleClose={handleClose}
                  />
                )}
              </PayModalBodyCSS>
            </Modal.Content>
          </Modal>
        );
      }}
    </SingleInvoiceContext.Consumer>
  );
};

export default PayModal;
