import React from 'react';
import { EditDetailContainerCSS } from './styles';

const EditDetailContainer = (props: any) => {
  const { children } = props;

  return (
    <EditDetailContainerCSS className="drop-shadow">
      {children}
    </EditDetailContainerCSS>
  )
}

export default EditDetailContainer;