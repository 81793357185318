import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const RegisterFormCSS = styled.div`
  width: 100%;
  background: white;
  border-radius: 6px;
  padding: 2.5rem 1.5rem;

  .ui.form .field .ui.input input, .ui.form .fields .field .ui.input input, .ui.form input:not([type]) {
    border: none;
    border-bottom: 1px solid var(--gray200);
    border-radius: 0;
  }

  .checkbox {
    margin: 0.5rem 0;
  }

  .ui.button {
    width: 100%;
    background: var(--primaryLight);
    color: white;
 }

 h3 {
   color: var(--gray200);
   text-align: center;
   font-size: 1.2rem;
 }

 .ui.icon.input>i.link.icon {
   color: var(--gray200);
 }

 @media only screen and (min-width: ${tabletWidth}px) {
    width: 40%;
    max-width:500px;
 }
`;