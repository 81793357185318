import React from 'react';
import { useTranslation } from 'react-i18next';
import { SingleInvoiceTransactions } from 'src/models/SingleInvoice';
import { ItemCSS } from './styles';

const Item = (props: any) => {
  const { data: rawData } = props;
  const data: any = SingleInvoiceTransactions.formatData(rawData);
  const { t } = useTranslation('merchant');
  const { status } = data;

  return (
    <ItemCSS className={`${status} drop-shadow`}>
      <h3>{data.amount}</h3>
      <hr />
      <p>{data.date}</p>
      <p className='status'>{t(status)}</p>
    </ItemCSS>
  )
}

export default Item;