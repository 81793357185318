import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Link, withRouter } from 'react-router-dom';
import { withUser } from 'src/components/Firebase';
import { SingleInvoice } from 'src/models/SingleInvoice';
import LeftBorderItem from '../../../LeftBorderItem';

const Item = (props: any) => {
  const { data, user } = props;
  const { t } = useTranslation('mobileItems');
  const path = R.pathOr('/', ['location', 'pathname'], props);
  const formattedData: any = SingleInvoice.formatData(data);
  const { requestTo, initiatedBy } = formattedData;
  const company =
    user.merchantId === initiatedBy.merchantId ? requestTo.companyName : initiatedBy.companyName;

  return (
    <LeftBorderItem className={`${formattedData.status} drop-shadow`} key={formattedData.id}>
      <Link to={`${path}/${formattedData.id}`}>
        <h3>{company}</h3>
        <div className="detail-container">
          <p className="label">{t('nextPaymentDate')}:</p>
          <p className="detail">{formattedData.nextDueDate}</p>
        </div>
        <div className="detail-container">
          <p className="label">{t('amount')}:</p>
          <p className="detail">{formattedData.remainingAmount}</p>
        </div>
        {!!formattedData.status.length && (
          <div className="detail-container">
            <p className="label">{t('status')}:</p>
            <p className="detail">{t(formattedData.status)}</p>
          </div>
        )}
      </Link>
    </LeftBorderItem>
  );
};

export default R.compose<any, any, any>(withUser, withRouter)(Item);
