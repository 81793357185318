import styled from 'styled-components';

export const DetailCSS = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  alignt-items: center;

  p {
    font-size: 1rem;
    margin-bottom: 0.8rem;
  }

  .label {
    font-weight: 300;
  }

  .detail {
    
  }
`;
