import React from 'react';
import CreditLimit from '../../components/CreditLimit';
import DoToday from '../../components/DoToday';
import Upcoming from '../../components/Upcoming';

const MobileDashboard = ({ upcomingDueRecords, usedCredit }: any) => {
  return (
    <div className="gray-background page-padding-bottom">
      <CreditLimit usedCredit={usedCredit} />
      <DoToday />
      <Upcoming data={upcomingDueRecords} />
    </div>
  );
};

export default MobileDashboard;
