export default {
  title: '主頁',
  dashboard: '主頁',
  creditLimit: '信用額',
  usedCreditLimit: '已使用信用額',
  whatDoYouWantToDoToday: '請選擇服務',
  pay: '付款',
  getPaid: '收款',
  upcomingDue: '即將到期的收款',
  upcomingPay: '即將到期的付款',
  amount: '金額',
  to: '公司名稱',
  welcome: '歡迎',
  continueOne: '請前往',
  continueTwo: '登記你的銀行戶口',
  settings: '設定',
  gotIt: '明白了',
  dueDate: '到期日',
}

