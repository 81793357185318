
import ReactDOM from 'react-dom';
import React from 'react';
import 'semantic-ui-css/semantic.min.css'
import { BrowserRouter } from 'react-router-dom';
import { FirebaseContext } from 'src/context';
import Firebase from 'src/components/Firebase';
import ScrollToTop from './components/ScrollToTop';
import App from './screens/App';

import './styles/index.css';
import './utils/translate';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <BrowserRouter>
    <ScrollToTop>
      <FirebaseContext.Provider value={new Firebase() as any}>
        <App />
      </FirebaseContext.Provider>
    </ScrollToTop>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
