import styled from 'styled-components';

export const DesktopCreditLimitCSS = styled.div`
  width: 100%;
  background: white;
  display: flex;
  justify-content: flex-end;
  height: 120px;

  #dollar-icon-container {
    width: 55px;
    border-left: 1px solid var(--gray100);
    display: flex;
    align-items: center;
    justify-content: center;

    i.big.icon, i.big.icons {
      color: var(--primary);
    }
  }

  #credit-limit-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 0.6rem 1rem 0.6rem 3rem;
    justify-content: center;
    min-height: 120px;

    h2 {
      margin-bottom: 0;
      font-weight: 400;
    }

    p {
      color: var(--gray200);
    }
  }
`;