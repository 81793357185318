import styled from 'styled-components';
import { desktopWidth } from 'src/config';

export const LanguageCSS = styled.span`
  color: white;

  &:hover {
    cursor: pointer;
  }

  &#open-routes {
    position: absolute;
    right: 19px;
    top: 17px;
    font-size: 1rem;
  }

  &#closed-routes {
    position: fixed;
    right: 19px;
    top: 17px;
    font-size: 1rem;
    z-index: 51;
  }

  @media only screen and (min-width: ${desktopWidth}px) {
    &#closed-routes {
      position: static;
      color: var(--fontColor);
    }
  }
`;

