import React from 'react';
import MobileListInvoices from 'src/components/MobileListInvoices';
import { ListActiveAndCompletedInvoiceContext } from 'src/context';
import Decorator from 'src/components/Decorator';
import NoData from 'src/components/NoData';

const MobilePay = () => {
  return (
    <div className="gray-background">
      <ListActiveAndCompletedInvoiceContext.Consumer>
        {
          (invoices: any) => {
            if (!invoices.length) {
              return <NoData className="full-height" />
            }

            return (
              <>
                <Decorator />
                <MobileListInvoices invoices={invoices} />
              </>
            )
          }
        }
      </ListActiveAndCompletedInvoiceContext.Consumer>
    </div>
  )
}

export default MobilePay;