export default {
  title: 'Settings',
  resetPasswordTitle: 'Reset Password',
  email: 'Email',
  phone: 'Phone',
  website: 'Website',
  name: 'Name',
  bankName: 'Bank Name',
  bankNumber: 'Bank Number',
  save: 'Save',
  cancel: 'Cancel',
  resetPassword: 'Reset Password',
  oldPassword: 'Old Password',
  newPassword: 'New Password',
  newPasswordConfirm: 'Retype New Password',
  reset: 'Reset',
  passwordCheckError: 'Passwords do not match',
  passwordLengthError: 'Password must be have a minimum length of 6',
  passwordUpdated: 'Reset Password Success.',
  documentsRequired: 'Documents Required',
  pleaseAllow24Hours: 'Please allow 24 hours for new documents to be checked and processed.',
  submittedDocuments: 'Submitted Documents',
  requiredDocuments: 'Required Documents',
  upload: 'Upload',
  profile: 'Profile',
  documents: 'Documents',
  connect: 'Connect',
  type: 'Type',
  fileName: 'File Name',
  connectSuccess: 'Successfully Connected!',
  connectFail: 'Something went wrong. Please try again later.',
  closeTab: 'You can now close this tab.',
}
