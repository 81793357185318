import React from 'react';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';
import Media from 'react-media';
import * as R from 'ramda';
import { tabletWidth } from 'src/config';
import { withMerchant, withFirebase, withUser } from 'src/components/Firebase';
import { useCollectionHook } from 'src/components/Firebase/utils';
import { SingleInvoice } from 'src/models/SingleInvoice';
import sortByNextDueDate from 'src/utils/sortByNextDueDate';
import { convertToMoney } from 'src/utils/convertToMoney';
import MobileDashboard from './screens/MobileDashboard';
import DesktopDashboard from './screens/DesktopDashboard';
import GetStarted from './components/GetStarted';

const Dashboard = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { merchant, firebase, user } = props;
  const { getStartedDisplay = false } = merchant;
  const dueRef = firebase
    .invoiceCollection()
    .where('buyerId', '==', user.merchantId)
    .where('status', '==', 'ongoing')
    .orderBy('nextDueDate', 'asc');
  const due = useCollectionHook(dueRef);

  const sellerPermissionsRef = firebase
    .invoiceCollection()
    .where('sellerId', '==', user.merchantId)
    .where('initiatedBy.permissions.payForBuyer', '==', true)
    .orderBy('nextDueDate', 'asc');
  const sellerPermissions = useCollectionHook(sellerPermissionsRef);

  let upcomingDueRecords: any = [];

  if (!sellerPermissions.isLoading && !due.isLoading) {
    upcomingDueRecords = sortByNextDueDate(R.concat(sellerPermissions.data, due.data));
  }

  const usedCredit = convertToMoney(upcomingDueRecords.reduce(
    (acc: number, record: any) => {
      return acc + record.remainingAmount;
    },
    0,
  ));

  const mappedUpcomingDueRecords = upcomingDueRecords.map((x: any) => SingleInvoice.formatData(x));

  return (
    <>
      <Helmet>
        <title>{t('title')}</title>
      </Helmet>
      {getStartedDisplay && <GetStarted />}
      <Media query={{ minWidth: tabletWidth }}>
        {matches =>
          matches ? (
            <DesktopDashboard
              upcomingDueRecords={mappedUpcomingDueRecords}
              usedCredit={usedCredit}
            />
          ) : (
            <MobileDashboard
              upcomingDueRecords={mappedUpcomingDueRecords}
              usedCredit={usedCredit}
            />
          )
        }
      </Media>
    </>
  );
};

export default R.compose<any, any, any, any>(
  withUser,
  withFirebase,
  withMerchant,
)(Dashboard);
