import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Button, Icon } from 'semantic-ui-react';
import { PAY_REQUEST, GET_PAID_REQUEST } from 'src/screens/routes';
import { DoTodayCSS } from './styles';


const DoToday = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { className = '' } = props;
  return (
    <DoTodayCSS className={`wrapper-padding ${className}`}>
      <div className='drop-shadow text-container'>
        <p>{t('whatDoYouWantToDoToday')}</p>
        <div id="do-today-link-container">
          <Link to={PAY_REQUEST}>
            <Button>
              <Icon name='world' />
              {t('pay')}
            </Button>
          </Link>
          <Link to={GET_PAID_REQUEST}>
            <Button>
              <Icon name='world' />
              {t('getPaid')}
            </Button>
          </Link>
        </div>
      </div>
    </DoTodayCSS>
  )
}

export default DoToday;