export default {
  dashboard: 'Dashboard',
  pay: 'Pay',
  getPaid: 'Get Paid',
  invoices: 'Invoices',
  contact: 'Contact',
  settings: 'Settings',
  logout: 'Logout',
  documents: 'Documents',
  connect: 'Connect',
  profile: 'Profile'
}
