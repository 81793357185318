import React from 'react';
import * as R from 'ramda';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { PAY } from 'src/screens/routes';
import NoData from 'src/components/NoData';
import Detail from './components/Detail';
import { MobileUpcomingCSS } from './styles';

const MobileUpcoming = (props: any) => {
  const { t } = useTranslation('dashboard');
  const { data } = props;

  const invoiceId: any = R.propOr('', 'id')(data[0]);

  const generateDetail = () => {
    // eslint-disable-next-line
    if (data.length) {
      // eslint-disable-next-line
      const { nextDueDate, remainingAmount, requestTo }: any = data[0];

      return (
        <div>
          {/* eslint-disable-next-line */}
          <Detail label='dueDate' detail={nextDueDate} />
          {/* eslint-disable-next-line */}
          <Detail label='amount' detail={remainingAmount} />
          {/* eslint-disable-next-line */}
          <Detail label='to' detail={requestTo.companyName} />
        </div>
      )
    }
    return (
      <div>
        <NoData className="white" />
      </div>
    )
  }

  const path = data.length ? `${PAY}/${invoiceId}` : '';

  return (
    <MobileUpcomingCSS className="wrapper-padding">
      <Link to={path}>
        <div className="text-container">
          <h3>{t('upcomingDue')}</h3>
          <hr />
          {generateDetail()}
        </div>
      </Link>
    </MobileUpcomingCSS>
  )
}

export default MobileUpcoming;
