
import React from 'react';
import { Link } from 'react-router-dom';
import { LOGOUT } from 'src/screens/routes';
import { IsAdminCSS } from './styles';


const IsAdmin = () => {
  return (
    <IsAdminCSS>
      <div className="is-admin-container drop-shadow">
        <h1>NO ENTRY</h1>
        <p>You&apos;re currently logged in as admin</p>
        <Link to={LOGOUT}>
          <p className="link">Logout</p>
        </Link>
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={process.env.REACT_APP_ADMIN_DASHBOARD_URL}
        >
          <p className="link">Go to admin dashboard</p>
        </a>
      </div>
    </IsAdminCSS>
  )
}

export default IsAdmin;