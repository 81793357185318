import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import { mapDocumentTypes } from 'src/screens/Settings/config';
import { DocumentsContainerCSS } from '../../styles';
import { filterRepeat } from '../../../../../../config';
import { ContainerCSS } from './styles';
import Upload from '../Upload';

const RequiredDocuments = (props:any) => {
  const { t } = useTranslation(['settings', 'general']);
  const [modalOpen, setModal] = useState(false);
  const {documents} = props;
  const requiredDocuments = filterRepeat(documents);

  const openModal = () => {
    setModal(true);
  }

  return (
    <DocumentsContainerCSS className="drop-shadow">
      <div className="title-container">
        <h3>{t('documentsRequired')}</h3>
        <Upload modalOpen={modalOpen} setModal={setModal} />
      </div>
      <ContainerCSS>
        {requiredDocuments.map((document:any)=>{
          return(
            <div 
              className="single-document" 
              key={document.id}
              onClick={openModal}
              role="button"
            >
              <h4>{mapDocumentTypes(document.type).toUpperCase()}</h4>
            </div>
          )
        })}
      </ContainerCSS>
    </DocumentsContainerCSS>
  )
}

export default RequiredDocuments;

