import styled from 'styled-components';

export const DesktopListInvoicesCSS = styled.div`
  h1 {
    color: var(--gray200);
    margin-bottom: 0;
  }

  .invoice-table-container {
    background: white;
    padding: 2.3rem 1.3rem;
    min-height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
  }
`;