import React from 'react';
import { Icon } from 'semantic-ui-react';
import { SuccessCSS } from './styles';

const Success = (props: any) => {
  const { children } = props;
  return (
    <SuccessCSS>
      <Icon
        name='thumbs up outline'
        size='big'
      />
      {children}
    </SuccessCSS>
  )
}

export default Success;