import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const RegisterCSS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  i.big.icon, i.big.icons {
    color: white;
    margin-top: 2.5rem;
  }
  
  p {
    margin: 0.5rem 0;
  }

  #title {
    color: white;
    margin-top: 0.5rem;
  }

  .link {
    color: white;
    text-align: center;
    margin: 1.4rem 0;
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    justify-content: center;

    h3 {
      margin-bottom: 2rem;
    }

    i.big.icon, i.big.icons {
      margin-top: 1rem;
    }

  }

`;