
import React, { useState } from 'react';
import Details from './components/Details';
import SuccessConfirmation from '../../components/SuccessConfirmation';


const Invoice = () => {
  const [showSuccess, toggleShowSuccess] = useState(false);
  return (
    <div>
      {showSuccess
        ? <SuccessConfirmation />
        : <Details toggleShowSuccess={toggleShowSuccess} />
      }
    </div>
  )
}

export default Invoice;