export default {
  amount: 'Amount',
  email: 'Email',
  cancel: 'Cancel',
  cardNumber: 'Card Number',
  expiration: 'Expiration',
  cv: 'CV',
  invalidEmail: 'Invalid Email',
  amountEmptyError: 'Amount must be at least HKD$4.00',
  amountTooSmallError: 'Amount must be at least HKD$4.00',
  amountTooLargeError: 'Amount cannot be greater than remaining',
  remainingAmountLessThanFour: 'Remaining Amount cannot be less than HKD$4.00',
  noCentsError: 'Whole dollars only',
  paymentType: 'Payment Type',
  selectPaymentType: 'Select a payment',
  success: ' Success',
  close: 'Close',
  generalError: 'An error has occured. Please try again later',
  creditCard: 'Credit Card',
  fps: 'FPS',
  submit: 'Submit',
  bankAccountName: 'Bank Account Name',
  bankAccountNumber: 'Bank Account Number',
  fpsInstructions: 'Some instructions here for fps',
  upload: 'Upload',
  uploadInvoiceProof: 'Upload recipt',
  fpsId: 'FPS ID',
}
