import styled from 'styled-components';

export const DesktopSettingsAndLogoutCSS = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 1rem 2rem 0 2rem;

  p {

    &:first-child{
      margin-right: 20px;
      margin-bottom: 0;
    }

    &:hover {
      cursor: pointer;
    }
  }
`;