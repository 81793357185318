import React from 'react';
import * as R from 'ramda';
import { withFirebase, withUser } from 'src/components/Firebase';
import { SinglePaneCSS  } from '../../styles';
import RequiredDocuments from './components/RequiredDocuments';
import SubmittedDocuments from './components/SubmittedDocuments';
import { useDocumentsHook } from '../../../../hooks';

const Documents = (props:any) => {

  const { firebase, user } = props;

  const {
    completeDocuments,
    incompleteDocuments
  } = useDocumentsHook(firebase, user);

  return (
    <SinglePaneCSS>
      <RequiredDocuments documents={incompleteDocuments} />
      <SubmittedDocuments documents={completeDocuments} />
    </SinglePaneCSS>
  )
}

export default R.compose<any, any, any>(
  withUser,
  withFirebase,
)(Documents);
