import * as R from 'ramda';

export class Bank {
  public bankName: string;
  public bankNumber: string;

  constructor(payload: any) {
    this.bankName = payload.bankName;
    this.bankNumber = payload.bankNumber;
  }

  static formatData(data: any) {
    return new Bank({
      bankName: R.pathOr('', ['bankName'], data),
      bankNumber: R.pathOr('', ['bankNumber'], data)
    })
  }
}