export default {
  title: 'Register',
  register: 'Register',
  businessDetails: 'Business Details',
  companyName: 'Company Name',
  establish: 'Establish',
  annualTurnover: 'Annual Turnover',
  personalDetails: 'Personal Details',
  name: 'Name',
  email: 'Email',
  phone: 'Phone',
  password: 'Password',
  retypePassword: 'Retyped Password',
  passwordMismatch: 'Passwords do not match up',
  passwordLength: 'Password must be have a minimum length of 6',
  returnToLogin: 'Already have an account? Return to login',
  invalidEmail: 'Invalid Email',
  termsAndConditionError: 'You must agree to the terms and conditions',
  agreeToTermsAndConditions: 'I agree to the',
  accountCouldNotBeCreated: 'Registration failed. Please try again later.',
}
