
import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';

class ScrollToTop extends React.Component<any, {}> {
  componentDidUpdate(prevProps: any) {
    const { location } = this.props;
    if (location !== prevProps.location) {
      window.scrollTo(0, 0);
      toast.dismiss();
    }
  }

  render() {
    const { children } = this.props;
    return children;
  }
}

export default withRouter(ScrollToTop);
