import React from 'react';
import * as R from 'ramda';
import { Table } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SingleInvoice } from 'src/models/SingleInvoice';
import { withUser } from 'src/components/Firebase';
import TableComponent from 'src/components/Table';

const GetPaidTable = (props: any) => {
  const { Header, Row, HeaderCell, Body, Cell } = Table;
  const { invoices, user } = props;
  const { t } = useTranslation('mobileItems');
  const path = R.pathOr('/', ['location', 'pathname'], props);
  const cellPath = [['nextDueDate'], ['remainingAmount']];
  const formattedData = invoices.map((x: any) => SingleInvoice.formatData(x));

  return (
    <TableComponent>
      <Table unstackable selectable>
        <Header>
          <Row>
            <HeaderCell>{t('company')}</HeaderCell>
            <HeaderCell>{t('nextPaymentDate')}</HeaderCell>
            <HeaderCell>{t('amount')}</HeaderCell>
            <HeaderCell>{t('status')}</HeaderCell>
          </Row>
        </Header>
        <Body>
          {formattedData.map((x: any) => {
            const { initiatedBy, requestTo } = x;
            const company =
              user.merchantId === initiatedBy.merchantId
                ? requestTo.companyName
                : initiatedBy.companyName
            return (
              <Row key={x.id}>
                <Cell>
                  <Link to={`${path}/${x.id}`}>
                    <div className="cell">{company}</div>
                  </Link>
                </Cell>
                {cellPath.map((cell: any) => {
                  return (
                    <Cell key={cell}>
                      <Link to={`${path}/${x.id}`}>
                        <div className="cell">{R.pathOr(null, [...cell], x)}</div>
                      </Link>
                    </Cell>
                  );
                })}
                <Cell>
                  <Link to={`${path}/${x.id}`}>
                    <div className="cell">{t(R.pathOr(null, ['status'], x))}</div>
                  </Link>
                </Cell>
              </Row>
            );
          })}
        </Body>
      </Table>
    </TableComponent>
  );
};

export default R.compose<any, any, any>(withUser, withRouter)(GetPaidTable);
