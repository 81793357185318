import styled from 'styled-components';
import { tabletWidth } from 'src/config';

export const LoginCSS = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;

  h3 {
    margin-top: 0.5rem;
    color: white;
  }

  i.big.icon, i.big.icons {
    color: white;
    margin-top: 2.5rem;
  }

  #links-container {
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    p {
      color: white;
      margin: 0.5rem 0;
      text-align: center;
    }
  }

  @media only screen and (min-width: ${tabletWidth}px) {
    justify-content: center;

    h3 {
      margin-bottom: 2rem;
    }

    i.big.icon, i.big.icons {
      margin-top: 1rem;
    }

    #links-container {
      margin: 0.6rem 0;
      p {
        margin: 0.6rem 0;
      }
    }
  }
`;