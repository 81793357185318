import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Success from 'src/components/Success';

const SuccessRequest = (props: any) => {
  const { t } = useTranslation('success');
  const { path, toggleShowSuccess } = props;
  return (
    <Success>
      <h1>{t('submitted')}</h1>
      <Link to={`/${path}/request`}>
        <p onClick={() => toggleShowSuccess(false)}>
          {t('submitAnotherRequest')}
        </p>
      </Link>
      <Link to='/'>
        <p>{t('backToDashboard')}</p>
      </Link>
      <Link to='/invoices'>
        <p>{t('viewInvoices')}</p>
      </Link>
    </Success>
  )
}

export default SuccessRequest;