import React from 'react';
import { DecoratorCSS } from './styles';

const Decorator = () => {
  return (
    <DecoratorCSS>
      <></>
    </DecoratorCSS>
  )
}

export default Decorator;