import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Checkbox, Form } from 'semantic-ui-react';
import { withFirebase } from 'src/components/Firebase';
import TermsAndConditions from 'src/components/TermsAndConditionsModal';
import { ConfirmationButtonCSS } from './styles';

const ConfirmationButton = (props: any) => {
  const { t } = useTranslation('invoice');
  const [confirm, toggleConfirm] = useState(false);
  const [error, toggleError] = useState(false);
  const errorMessage = {
    content: t('errorMessage'),
    pointing: 'below',
  }

  const handleConfirm = async () => {
    if (!confirm) {
      toggleError(true);
    } else {
      try {
        toggleError(false);
        const { toggleShowSuccess, firebase, invoice, isBuyer } = props;
        const update: any = {};
        if (isBuyer) {
          update.buyerConfirmed = true
        } else {
          update.sellerConfirmed = true;
        }

        await firebase.invoiceDoc(invoice.id).set(
          update, { merge: true }
        )
        toggleShowSuccess(true);
      } catch (err) {
        toast.error(err.message, {
          position: toast.POSITION.TOP_CENTER,
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: true,
          draggable: false,
        });
      }
    }
  }

  return (
    <ConfirmationButtonCSS>
      <Form onSubmit={handleConfirm}>
        <Form.Checkbox
          name='checkbox'
          onChange={(e: any, data: any) => toggleConfirm(data.checked)}
          control={Checkbox}
          label={(
            <label>
              {t('agreeToTermsAndConditions')}&nbsp;
              <TermsAndConditions />
            </label>
          )}
          error={error && errorMessage}
        />
        <Form.Button>{t('confirm')}</Form.Button>
      </Form>
    </ConfirmationButtonCSS>
  )
}

export default withFirebase(ConfirmationButton);