import React from 'react';
import Decorator from 'src/components/Decorator';
import { ListInvoiceContext } from 'src/context';
import NoData from 'src/components/NoData';
import ListInvoices from './components/ListInvoices';


const MobileInvoices = () => {
  return (
    <div className="gray-background page-padding-bottom">
      <ListInvoiceContext.Consumer>
        {(invoices: any) => {
          if (!invoices.length) {
            return (
              <NoData className="full-height" />
            )
          }
          return (
            <>
              <Decorator />
              <ListInvoices />
            </>
          )
        }}
      </ListInvoiceContext.Consumer>
    </div>
  )
}

export default MobileInvoices;